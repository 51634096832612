import React from "react";
import Container from "react-bootstrap/Container";

import * as S from "./styles";

function ShowerOfBlessings() {
  return (
    <>
      <Container>
        <S.PageHeader>
          <span>
            &#3844;&#3845;&#3853;
            &#3853;&#3930;&#3954;&#3906;&#3851;&#3926;&#3921;&#3956;&#3923;&#3851;&#3906;&#3942;&#3964;&#3939;&#3851;&#3936;&#3921;&#3962;&#3926;&#3942;&#3851;&#3921;&#3908;&#3851;&#3936;&#3926;&#4018;&#3962;&#3939;&#3851;&#3926;&#3936;&#3954;&#3851;&#3926;&#4019;&#3851;&#3928;&#3936;&#3954;&#3851;&#3938;&#4003;&#3939;&#3851;&#3936;&#3926;&#4017;&#3964;&#3938;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3926;&#3938;&#4019;&#3926;&#3942;&#3851;&#3910;&#3938;&#3851;&#3936;&#3926;&#3962;&#3926;&#3942;&#3851;&#3926;&#3934;&#3956;&#3906;&#3942;&#3851;&#3942;&#3964;&#3853;
            &#3853;
          </span>
          <span>
            A Shower of Blessings: A Guru Yoga Practice to Accompany the
            Seven-Line Prayer
          </span>
        </S.PageHeader>
        <p>
          <span>by Mipham Rinpoche</span>
        </p>
        <p>
          <span>
            &#3944;&#3953;&#3967;
            &#3938;&#3908;&#3851;&#3939;&#3956;&#3942;&#3851;&#3920;&#3851;&#3928;&#3939;&#3851;&#3906;&#3923;&#3942;&#3851;&#3924;&#3936;&#3954;&#3851;&#3928;&#3921;&#3956;&#3923;&#3851;&#3928;&#3905;&#3936;&#3851;&#3938;&#3956;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>ah, rang l&uuml; tamal nep&eacute; d&uuml;n kha ru</span>
        </p>
        <p>
          <span>
            &#256;&#7717;! I am in my ordinary form; before me, in the sky
          </span>
        </p>
        <p>
          <span>
            &#3944;&#3964;&#3851;&#3938;&#3986;&#4017;&#3923;&#3851;&#3921;&#4018;&#3954;&#3851;&#3928;&#3962;&#3921;&#3851;&#3921;&#4023;&#3851;&#3923;&#3851;&#3904;&#3964;&#3851;&#3940;&#3936;&#3954;&#3851;&#3928;&#3930;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>orgyen drim&eacute; dhanakosh&eacute; tso</span>
        </p>
        <p>
          <span>
            Appears O&#7693;&#7693;iy&#257;na, and the immaculate Dhanako&#347;a
            lake,
          </span>
        </p>
        <p>
          <span>
            &#3906;&#3919;&#3954;&#3908;&#3851;&#3935;&#3926;&#3851;&#3937;&#3923;&#3851;&#3939;&#3906;&#3851;&#3926;&#3938;&#3986;&#4017;&#3921;&#3851;&#3939;&#4001;&#3923;&#3851;&#3910;&#3956;&#3942;&#3851;&#3906;&#3908;&#3851;&#3926;&#3936;&#3954;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>ting zap yenlak gy&eacute; den ch&uuml; gangw&eacute;</span>
        </p>
        <p>
          <span>Deep and full of water with eight kinds of purity.</span>
        </p>
        <p>
          <span>
            &#3921;&#3926;&#3956;&#3942;&#3851;&#3942;&#3956;&#3851;&#3938;&#3954;&#3923;&#3851;&#3910;&#3962;&#3923;&#3851;&#3924;&#3921;&#3851;&#3942;&#4001;&#3964;&#3908;&#3851;&#3936;&#3921;&#3926;&#3851;&#3938;&#3986;&#4017;&#3942;&#3851;&#3942;&#3999;&#3962;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>w&uuml; su rin chen p&eacute; dong dap gy&eacute; teng</span>
        </p>
        <p>
          <span>
            In its centre you dwell, on a jewelled lotus blossom, its petals
            open wide:
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3984;&#4017;&#3926;&#3942;&#3851;&#3906;&#3923;&#3942;&#3851;&#3904;&#3956;&#3923;&#3851;&#3936;&#3921;&#3956;&#3942;&#3851;&#3944;&#3964;&#3851;&#3938;&#3986;&#4017;&#3923;&#3851;&#3938;&#4001;&#3964;&#3851;&#3938;&#3991;&#3962;&#3851;&#3936;&#3910;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>kyapn&eacute; kund&uuml; orgyen dorj&eacute; chang</span>
        </p>
        <p>
          <span>All sources of refuge combined, Orgyen Dorje Chang,</span>
        </p>
        <p>
          <span>
            &#3928;&#3930;&#3923;&#3851;&#3921;&#3924;&#3962;&#3936;&#3954;&#3851;&#3921;&#3924;&#3939;&#3851;&#3936;&#3926;&#3938;&#3851;&#3928;&#3930;&#3964;&#3851;&#3938;&#3986;&#4017;&#3939;&#3851;&#3937;&#3956;&#3928;&#3851;&#3921;&#3908;&#3851;&#3936;&#3905;&#4018;&#3954;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>tsenp&eacute; palbar tsogyal yum dang tril</span>
        </p>
        <p>
          <span>
            Ablaze in splendour with the major and minor marks of perfection,
            and embracing the consort Yeshe Tsogyal.
          </span>
        </p>
        <p>
          <span>
            &#3925;&#4017;&#3906;&#3851;&#3906;&#3937;&#3942;&#3851;&#3938;&#4001;&#3964;&#3851;&#3938;&#3991;&#3962;&#3851;&#3906;&#3937;&#3964;&#3923;&#3851;&#3924;&#3942;&#3851;&#3920;&#3964;&#3921;&#3851;&#3926;&#3956;&#3928;&#3851;&#3926;&#3942;&#4003;&#3928;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>
            chak y&eacute; dorj&eacute; y&ouml;np&eacute; t&ouml; bum nam
          </span>
        </p>
        <p>
          <span>
            You hold a vajra in your right hand, a skull cup containing a vase
            in your left,
          </span>
        </p>
        <p>
          <span>
            &#3921;&#3938;&#3851;&#3921;&#3908;&#3851;&#3938;&#3954;&#3923;&#3851;&#3910;&#3962;&#3923;&#3851;&#3938;&#3956;&#3942;&#3851;&#3924;&#3936;&#3954;&#3851;&#3938;&#3986;&#4017;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3928;&#3931;&#3962;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>dar dang rinchen r&uuml;p&eacute; gyen gyi dz&eacute;</span>
        </p>
        <p>
          <span>Beautiful in all your silk, jewel and bone ornaments.</span>
        </p>
        <p>
          <span>
            &#3936;&#3964;&#3921;&#3851;&#3939;&#3988;&#3936;&#3954;&#3851;&#3904;&#4019;&#3964;&#3908;&#3851;&#3923;&#3942;&#3851;&#3926;&#3921;&#3962;&#3851;&#3910;&#3962;&#3923;&#3851;&#3906;&#3935;&#3954;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3936;&#3926;&#3938;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>&ouml;ng&eacute; longn&eacute; dechen zi jin bar</span>
        </p>
        <p>
          <span>
            In an expanse of blue, white, yellow, red and green light, you
            preside resplendent, radiating great bliss.
          </span>
        </p>
        <p>
          <span>
            &#3936;&#3905;&#3964;&#3938;&#3851;&#3921;&#3956;&#3851;&#3938;&#4009;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3938;&#3986;&#4017;&#3851;&#3928;&#3930;&#3964;&#3851;&#3942;&#4004;&#4018;&#3954;&#3923;&#3851;&#3939;&#3999;&#3938;&#3851;&#3906;&#3919;&#3954;&#3926;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>khor du tsa sum gyatso trin tar tip</span>
        </p>
        <p>
          <span>
            All around you, gurus, devas and &#7693;&#257;kin&#299;s gather like
            clouds.
          </span>
        </p>
        <p>
          <span>
            &#3926;&#4017;&#3954;&#3923;&#3851;&#3938;&#4019;&#3926;&#3942;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3938;&#3991;&#3962;&#3936;&#3954;&#3851;&#3910;&#3938;&#3851;&#3936;&#3926;&#3962;&#3926;&#3942;&#3851;&#3926;&#3921;&#3906;&#3851;&#3939;&#3851;&#3906;&#3935;&#3954;&#3906;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>jinlap tukj&eacute; char bep dak la zik</span>
        </p>
        <p>
          <span>
            Pour down your blessings in a rain of compassion: watch over and
            care for me!
          </span>
        </p>
        <p>
          <span>
            &#3938;&#3986;&#4017;&#3939;&#3851;&#3904;&#3956;&#3923;&#3851;&#3908;&#3964;&#3851;&#3926;&#3964;&#3851;&#3936;&#3910;&#3954;&#3851;&#3928;&#3962;&#3921;&#3851;&#3937;&#3962;&#3851;&#3940;&#3962;&#3942;&#3851;&#3942;&#3984;&#3956;&#3938;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>gyal k&uuml;n ngowo chim&eacute; yesh&eacute; kur</span>
        </p>
        <p>
          <span>Essence of all buddhas, in your immortal wisdom body,</span>
        </p>
        <p>
          <span>
            &#3906;&#3921;&#3956;&#3908;&#3851;&#3940;&#3956;&#3906;&#3942;&#3851;&#3921;&#4018;&#3906;&#3851;&#3924;&#3964;&#3942;&#3851;&#3921;&#3921;&#3851;&#3925;&#4017;&#3906;&#3851;&#3938;&#3999;&#3906;&#3851;&#3919;&#3956;&#3851;&#3936;&#3930;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>dung shuk drakp&ouml; d&eacute; chak tak tu tsal</span>
        </p>
        <p>
          <span>
            Now and always, I bow to you with passionate longing and devotion.
          </span>
        </p>
        <p>
          <span>
            &#3939;&#3956;&#3942;&#3851;&#3921;&#3908;&#3851;&#3939;&#3964;&#3908;&#3942;&#3851;&#3942;&#4004;&#4017;&#3964;&#3921;&#3851;&#3921;&#3956;&#3942;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3921;&#3906;&#3962;&#3851;&#3926;&#3936;&#3954;&#3851;&#3930;&#3964;&#3906;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>l&uuml; dang long ch&ouml; d&uuml; sum gew&eacute; tsok</span>
        </p>
        <p>
          <span>
            My bodies, possessions and all my merit, past, present and future
          </span>
        </p>
        <p>
          <span>
            &#3904;&#3956;&#3923;&#3851;&#3926;&#3935;&#3908;&#3851;&#3928;&#3910;&#3964;&#3921;&#3851;&#3924;&#3936;&#3954;&#3851;&#3942;&#4004;&#4018;&#3954;&#3923;&#3851;&#3921;&#3956;&#3851;&#3921;&#3928;&#3954;&#3906;&#3942;&#3851;&#3923;&#3942;&#3851;&#3936;&#3926;&#3956;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>kunzang ch&ouml;p&eacute; trin du mikn&eacute; bul</span>
        </p>
        <p>
          <span>
            I visualize as Samantabhadra&rsquo;s offering clouds, and present
            them to you.
          </span>
        </p>
        <p>
          <span>
            &#3920;&#3964;&#3906;&#3851;&#3928;&#3962;&#3921;&#3851;&#3923;&#3942;&#3851;&#3926;&#3942;&#3906;&#3942;&#3851;&#3942;&#4001;&#3954;&#3906;&#3851;&#3939;&#3999;&#3956;&#3908;&#3851;&#3928;&#3851;&#3939;&#3956;&#3942;&#3851;&#3926;&#3940;&#3906;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>tokm&eacute; n&eacute; sak dik tung ma l&uuml; shak</span>
        </p>
        <p>
          <span>
            My harmful actions and downfalls, committed through beginningless
            time, I confess without exception.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#4018;&#3942;&#3851;&#3926;&#3909;&#3942;&#3851;&#3938;&#3986;&#4017;&#3939;&#3851;&#3926;&#3851;&#3904;&#3956;&#3923;&#3851;&#3906;&#4017;&#3954;&#3851;&#3937;&#3964;&#3923;&#3851;&#3919;&#3923;&#3851;&#3906;&#4017;&#3954;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>s&eacute; ch&eacute; gyalwa k&uuml;n gyi y&ouml;nten gyi</span>
        </p>
        <p>
          <span>
            You alone have completely mastered the precious qualities of all the
            buddhas and bodhisattvas,
          </span>
        </p>
        <p>
          <span>
            &#3905;&#4017;&#3926;&#3851;&#3926;&#3921;&#3906;&#3851;&#3906;&#3909;&#3954;&#3906;&#3851;&#3924;&#3956;&#3851;&#3928;&#3906;&#3964;&#3923;&#3851;&#3924;&#3964;&#3936;&#3954;&#3851;&#3938;&#4003;&#3928;&#3851;&#3920;&#3938;&#3851;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>khyap dak chik pu g&ouml;np&ouml; nam tar la</span>
        </p>
        <p>
          <span>
            And with all my heart I rejoice at your liberating life story.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3993;&#3954;&#3908;&#3851;&#3923;&#3942;&#3851;&#3937;&#3954;&#3851;&#3938;&#3908;&#3851;&#3921;&#3921;&#3851;&#3924;&#3942;&#3851;&#3906;&#3942;&#3964;&#3939;&#3851;&#3936;&#3921;&#3962;&#3926;&#3942;&#3851;&#3940;&#3954;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>nying n&eacute; yi rang dep&eacute; sol dep shing</span>
        </p>
        <p>
          <span>With total trust I pray to you,</span>
        </p>
        <p>
          <span>
            &#3935;&#3926;&#3851;&#3938;&#3986;&#4017;&#3942;&#3851;&#3910;&#3964;&#3942;&#3851;&#3904;&#4017;&#3954;&#3851;&#3910;&#3938;&#3851;&#3910;&#3962;&#3923;&#3851;&#3936;&#3926;&#3962;&#3926;&#3942;&#3851;&#3924;&#3938;&#3851;&#3926;&#3942;&#3984;&#3956;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>zap gy&eacute; ch&ouml; kyi char chen beppar kul</span>
        </p>
        <p>
          <span>
            And implore you: shower down a great rain of Dharma, profound and
            vast.
          </span>
        </p>
        <p>
          <span>
            &#3938;&#3908;&#3851;&#3906;&#3934;&#3923;&#3851;&#3921;&#3906;&#3962;&#3851;&#3926;&#3936;&#3954;&#3851;&#3921;&#3908;&#3964;&#3942;&#3851;&#3924;&#3964;&#3851;&#3904;&#3956;&#3923;&#3851;&#3926;&#3942;&#4001;&#3964;&#3928;&#3942;&#3851;&#3923;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>rang shyen gew&eacute; ng&ouml;po kun dom n&eacute;</span>
        </p>
        <p>
          <span>Gathering all the actual merit of myself and others,</span>
        </p>
        <p>
          <span>
            &#3936;&#3906;&#4018;&#3964;&#3851;&#3905;&#3928;&#3942;&#3851;&#3938;&#3986;&#4017;&#3851;&#3928;&#3930;&#3964;&#3851;&#3911;&#3954;&#3851;&#3942;&#4018;&#3954;&#3921;&#3851;&#3906;&#3923;&#3942;&#3851;&#3904;&#4017;&#3954;&#3851;&#3926;&#3938;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>dro kham gyatso ji si n&eacute; kyi bar</span>
        </p>
        <p>
          <span>
            For as long as these infinite realms of living beings remain
          </span>
        </p>
        <p>
          <span>
            &#3928;&#3906;&#3964;&#3923;&#3851;&#3924;&#3964;&#3851;&#3905;&#4017;&#3964;&#3921;&#3851;&#3904;&#4017;&#3954;&#3851;&#3938;&#4003;&#3928;&#3851;&#3920;&#3938;&#3851;&#3938;&#3991;&#3962;&#3942;&#3851;&#3926;&#3942;&#3993;&#3962;&#3906;&#3942;&#3851;&#3919;&#3962;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>g&ouml;npo khy&ouml; kyi namtar j&eacute; nyek t&eacute;</span>
        </p>
        <p>
          <span>I shall follow your example, Lord,</span>
        </p>
        <p>
          <span>
            &#3928;&#3905;&#3936;&#3851;&#3905;&#4017;&#3926;&#3851;&#3936;&#3906;&#4018;&#3964;&#3851;&#3926;&#3851;&#3936;&#3921;&#4018;&#3962;&#3923;&#3851;&#3924;&#3936;&#3954;&#3851;&#3921;&#3964;&#3923;&#3851;&#3921;&#3956;&#3851;&#3926;&#3942;&#3988;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>kha khyap drowa drenp&eacute; d&ouml;n du ngo</span>
        </p>
        <p>
          <span>
            And dedicate it all to guiding beings as limitless as space.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3984;&#4017;&#3926;&#3942;&#3851;&#3906;&#3923;&#3942;&#3851;&#3904;&#3956;&#3923;&#3851;&#3936;&#3921;&#3956;&#3942;&#3851;&#3928;&#3905;&#4017;&#3962;&#3923;&#3851;&#3926;&#3938;&#4009;&#3962;&#3936;&#3954;&#3851;&#3906;&#3919;&#3962;&#3938;&#3851;&#3910;&#3962;&#3923;&#3851;&#3924;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>kyapn&eacute; kund&uuml; khyents&eacute; terchen po</span>
        </p>
        <p>
          <span>
            You are all my sources of refuge in one, a great treasure of wisdom
            and love,
          </span>
        </p>
        <p>
          <span>
            &#3921;&#3956;&#3942;&#3851;&#3908;&#3923;&#3851;&#3942;&#3993;&#3954;&#3906;&#3942;&#3851;&#3928;&#3936;&#3954;&#3851;&#3942;&#3984;&#4017;&#3926;&#3942;&#3851;&#3928;&#3910;&#3964;&#3906;&#3851;&#3938;&#3954;&#3923;&#3851;&#3924;&#3964;&#3851;&#3910;&#3962;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>d&uuml; ngen nyik m&eacute; kyap chok rinpoch&eacute;</span>
        </p>
        <p>
          <span>
            Our precious, our greatest, protection in this dark age, the dregs
            of time.
          </span>
        </p>
        <p>
          <span>
            &#3939;&#3988;&#3851;&#3926;&#3921;&#3964;&#3936;&#3954;&#3851;&#3938;&#3986;&#3956;&#3921;&#3851;&#3924;&#3942;&#3851;&#3928;&#3923;&#3938;&#3851;&#3940;&#3954;&#3908;&#3851;&#3906;&#3921;&#3956;&#3908;&#3942;&#3851;&#3940;&#3956;&#3906;&#3942;&#3851;&#3904;&#4017;&#3954;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>nga d&ouml; g&uuml;p&eacute; nar shing dung shuk kyi</span>
        </p>
        <p>
          <span>
            Tormented and drained by the five kinds of degeneration, when I call
            on you
          </span>
        </p>
        <p>
          <span>
            &#3906;&#3942;&#3964;&#3939;&#3851;&#3936;&#3921;&#3962;&#3926;&#3942;&#3851;&#3926;&#3956;&#3851;&#3939;&#3851;&#3926;&#3938;&#4009;&#3962;&#3851;&#3926;&#3942;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3904;&#4017;&#3954;&#3942;&#3851;&#3906;&#3935;&#3954;&#3906;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>sol dep bu la tsew&eacute; tuk kyi zik</span>
        </p>
        <p>
          <span>
            In desperate yearning, look upon your child with all the love of
            your wisdom mind.
          </span>
        </p>
        <p>
          <span>
            &#3921;&#3906;&#3964;&#3908;&#3942;&#3851;&#3924;&#3936;&#3954;&#3851;&#3904;&#4019;&#3964;&#3908;&#3851;&#3923;&#3942;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3938;&#3991;&#3962;&#3936;&#3954;&#3851;&#3938;&#4009;&#3939;&#3851;&#3925;&#4017;&#3956;&#3908;&#3942;&#3851;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>gong p&eacute; long n&eacute; tukj&eacute; tsal chung la</span>
        </p>
        <p>
          <span>
            Let the power of your compassion burst out from your vast expanse of
            realization,
          </span>
        </p>
        <p>
          <span>
            &#3928;&#3964;&#3942;&#3851;&#3939;&#4001;&#3923;&#3851;&#3926;&#3921;&#3906;&#3851;&#3906;&#3954;&#3851;&#3942;&#3993;&#3954;&#3908;&#3851;&#3939;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3938;&#4019;&#3964;&#3926;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>m&ouml; den dak gi nying la jin gyi lop</span>
        </p>
        <p>
          <span>And flood my devoted heart with your blessing.</span>
        </p>
        <p>
          <span>
            &#3938;&#3999;&#3906;&#3942;&#3851;&#3921;&#3908;&#3851;&#3928;&#3930;&#3923;&#3851;&#3928;&#3851;&#3928;&#4017;&#3956;&#3938;&#3851;&#3921;&#3956;&#3851;&#3942;&#3999;&#3964;&#3923;&#3851;&#3924;&#3851;&#3921;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>tak dang tsenma nyur du t&ouml;npa dang</span>
        </p>
        <p>
          <span>Quickly show me the signs and marks of attainment,</span>
        </p>
        <p>
          <span>
            &#3928;&#3910;&#3964;&#3906;&#3851;&#3921;&#3908;&#3851;&#3920;&#3956;&#3923;&#3851;&#3928;&#3964;&#3908;&#3851;&#3921;&#3908;&#3964;&#3942;&#3851;&#3906;&#4018;&#3956;&#3926;&#3851;&#3942;&#4009;&#3939;&#3851;&#3921;&#3956;&#3851;&#3906;&#3942;&#3964;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>chok dang t&uuml;nmong ng&ouml;drup tsal du sol</span>
        </p>
        <p>
          <span>And grant me siddhis, ordinary and supreme.</span>
        </p>
        <p>
          <span>
            &#3943;&#3953;&#3956;&#3971;&#3860;
            &#3944;&#3964;&#3851;&#3938;&#3986;&#4017;&#3923;&#3851;&#3937;&#3956;&#3939;&#3851;&#3906;&#4017;&#3954;&#3851;&#3923;&#3956;&#3926;&#3851;&#3926;&#4017;&#3908;&#3851;&#3928;&#3930;&#3928;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>hung, orgyen yul gyi nup jang tsam</span>
        </p>
        <p>
          <span>
            H&#363;&#7747;! In the north-west of the land of
            O&#7693;&#7693;iy&#257;na,
          </span>
        </p>
        <p>
          <span>
            &#3924;&#3921;&#4008;&#3851;&#3906;&#3962;&#3851;&#3942;&#3938;&#3851;&#3942;&#4001;&#3964;&#3908;&#3851;&#3924;&#3964;&#3851;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>pema g&eacute;sar dong po la</span>
        </p>
        <p>
          <span>In the heart of a lotus flower,</span>
        </p>
        <p>
          <span>
            &#3937;&#3851;&#3928;&#3930;&#3923;&#3851;&#3928;&#3910;&#3964;&#3906;&#3851;&#3906;&#3954;&#3851;&#3921;&#3908;&#3964;&#3942;&#3851;&#3906;&#4018;&#3956;&#3926;&#3851;&#3926;&#3938;&#3993;&#3962;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>yatsen chok gi ng&ouml; drup ny&eacute;</span>
        </p>
        <p>
          <span>Endowed with the most marvellous attainments,</span>
        </p>
        <p>
          <span>
            &#3924;&#3921;&#4008;&#3851;&#3936;&#3926;&#4017;&#3956;&#3908;&#3851;&#3906;&#3923;&#3942;&#3851;&#3934;&#3962;&#3942;&#3851;&#3942;&#3956;&#3851;&#3906;&#4018;&#3906;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>pema jungn&eacute; shy&eacute; su drak</span>
        </p>
        <p>
          <span>You are renowned as the Lotus-born,</span>
        </p>
        <p>
          <span>
            &#3936;&#3905;&#3964;&#3938;&#3851;&#3921;&#3956;&#3851;&#3928;&#3905;&#3936;&#3851;&#3936;&#3906;&#4018;&#3964;&#3851;&#3928;&#3908;&#3851;&#3924;&#3964;&#3942;&#3851;&#3926;&#3942;&#3984;&#3964;&#3938;&#3860;
          </span>
        </p>
        <p>
          <span>khor du khandro mangp&ouml; kor</span>
        </p>
        <p>
          <span>Surrounded by many hosts of &#7693;&#257;kin&#299;s.</span>
        </p>
        <p>
          <span>
            &#3905;&#4017;&#3962;&#3921;&#3851;&#3904;&#4017;&#3954;&#3851;&#3938;&#3991;&#3962;&#3942;&#3851;&#3942;&#3956;&#3851;&#3926;&#3921;&#3906;&#3851;&#3926;&#3942;&#3986;&#4018;&#3956;&#3926;&#3851;&#3904;&#4017;&#3954;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>khy&eacute; kyi jesu dak drup kyi</span>
        </p>
        <p>
          <span>Following in your footsteps</span>
        </p>
        <p>
          <span>
            &#3926;&#4017;&#3954;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3938;&#4019;&#3964;&#3926;&#3942;&#3851;&#3925;&#4017;&#3954;&#3938;&#3851;&#3906;&#3940;&#3962;&#3906;&#3942;&#3851;&#3942;&#3956;&#3851;&#3906;&#3942;&#3964;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>jin gyi lop chir shek su sol</span>
        </p>
        <p>
          <span>I pray to you: come and bless me with your grace!</span>
        </p>
        <p>
          <span>
            &#3906;&#3956;&#3851;&#3938;&#3956;&#3851;&#3924;&#3921;&#4008;&#3851;&#3942;&#3954;&#3921;&#4001;&#4023;&#3954;&#3851;&#3943;&#3953;&#3956;&#3971;&#3860;
          </span>
        </p>
        <p>
          <span>guru pema siddhi hung</span>
        </p>
        <p>
          <span>Guru padma siddhi h&#363;&#7747;</span>
        </p>
        <p>
          <span>
            &#3934;&#3962;&#3942;&#3851;&#3926;&#4017;&#3942;&#3851;&#3939;&#3851;&#3930;&#3954;&#3906;&#3851;&#3926;&#3921;&#3956;&#3923;&#3851;&#3906;&#3942;&#3964;&#3939;&#3851;&#3936;&#3921;&#3962;&#3926;&#3942;&#3851;&#3909;&#3954;&#3851;&#3923;&#3956;&#3942;&#3851;&#3942;&#3956;&#3851;&#3936;&#3921;&#3964;&#3923;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>Recite The Seven Line Prayer as many times as you can.</span>
        </p>
        <p>
          <span>
            &#3928;&#3964;&#3942;&#3851;&#3906;&#3956;&#3942;&#3851;&#3904;&#4017;&#3954;&#3942;&#3851;&#3906;&#3942;&#3964;&#3939;&#3851;&#3926;&#3851;&#3926;&#3919;&#3926;&#3851;&#3924;&#3942;&#3851;&#3926;&#4019;&#3851;&#3928;&#3851;&#3937;&#3926;&#3851;&#3937;&#3956;&#3928;&#3851;&#3906;&#4017;&#3954;&#3851;&#3942;&#4006;&#4017;&#3964;&#3938;&#3851;&#3928;&#3930;&#3928;&#3942;&#3851;&#3921;&#3908;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3904;&#3851;&#3923;&#3942;&#3851;&#3937;&#3962;&#3851;&#3940;&#3962;&#3942;&#3851;&#3904;&#4017;&#3954;&#3851;&#3936;&#3964;&#3921;&#3851;&#3935;&#3962;&#3938;&#3851;&#3942;&#4003;&#3851;&#3939;&#3988;&#3851;&#3926;&#3851;&#3920;&#3906;&#3851;&#3939;&#3999;&#3938;&#3851;&#3926;&#3938;&#3984;&#4017;&#3908;&#3851;&#3923;&#3942;&#3851;&#3938;&#3908;&#3851;&#3906;&#3954;&#3851;&#3942;&#3993;&#3954;&#3908;&#3851;&#3906;&#3938;&#3851;&#3920;&#3954;&#3928;&#3851;&#3924;&#3942;&#3851;&#3938;&#3986;&#4017;&#3956;&#3921;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3926;&#3938;&#4019;&#3926;&#3851;&#3924;&#3938;&#3851;&#3926;&#3942;&#3928;&#3853;
          </span>
        </p>
        <p>
          <span>
            Imagine that, because you are praying with sincere devotion, from
            the place of union and the heart of the guru and the consort fine
            shafts of wisdom light&mdash;blue, white, yellow, red and
            green&mdash;shoot out and dissolve into your heart, blessing and
            transforming your very being.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3984;&#3926;&#3942;&#3851;&#3942;&#3956;&#3851;&#3926;&#3931;&#4018;&#3851;&#3906;&#3956;&#3851;&#3938;&#3956;&#3851;&#3909;&#3954;&#3851;&#3938;&#3954;&#3906;&#3942;&#3851;&#3926;&#3935;&#4019;&#3853;
          </span>
        </p>
        <p>
          <span>Recite the Vajra Guru Mantra as much as you wish.</span>
        </p>
        <p>
          <span>
            &#3944;&#3964;&#3966;&#3851;&#3944;&#3953;&#3967;&#3943;&#3953;&#3956;&#3971;&#3851;&#3926;&#3931;&#4018;&#3851;&#3906;&#3956;&#3851;&#3938;&#3956;&#3851;&#3924;&#3921;&#4008;&#3851;&#3942;&#3954;&#3921;&#4001;&#4023;&#3954;&#3851;&#3943;&#3953;&#3956;&#3971;&#3860;
          </span>
        </p>
        <p>
          <span>om ah hung vajra guru pema siddhi hung</span>
        </p>
        <p>
          <span>
            o&#7747; &#257;&#7717; h&#363;&#7747; vajra guru padma siddhi
            h&#363;&#7747;
          </span>
        </p>
        <p>
          <span>&#3920;&#3956;&#3923;&#3851;&#3928;&#3920;&#3938;&#3853;</span>
        </p>
        <p>
          <span>At the end of the session:</span>
        </p>
        <p>
          <span>
            &#3926;&#4019;&#3851;&#3928;&#3936;&#3954;&#3851;&#3906;&#3923;&#3942;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3937;&#3954;&#3851;&#3906;&#3962;&#3851;&#3936;&#3926;&#4018;&#3956;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3939;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>lam&eacute; n&eacute; sum yi g&eacute; dru sum l&eacute;</span>
        </p>
        <p>
          <span>
            From the three syllables o&#7747; &#257;&#7717; h&#363;&#7747; at
            the three centres of the guru,
          </span>
        </p>
        <p>
          <span>
            &#3936;&#3964;&#3921;&#3851;&#3935;&#3962;&#3938;&#3851;&#3921;&#3904;&#3938;&#3851;&#3921;&#3928;&#3938;&#3851;&#3928;&#3920;&#3954;&#3908;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3926;&#4017;&#3956;&#3908;&#3851;&#3923;&#3942;&#3851;&#3942;&#3956;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>&ouml;zer kar mar ting sum jung n&eacute; su</span>
        </p>
        <p>
          <span>White, red and blue rays of light strike out</span>
        </p>
        <p>
          <span>
            &#3938;&#3908;&#3851;&#3906;&#3954;&#3851;&#3906;&#3923;&#3942;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3920;&#3954;&#3928;&#3851;&#3924;&#3942;&#3851;&#3942;&#3986;&#3964;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3906;&#4017;&#3954;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>rang gi n&eacute; sum timp&eacute; go sum gyi</span>
        </p>
        <p>
          <span>
            And dissolve into my own three centres, purifying the obscurations
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3986;&#4018;&#3954;&#3926;&#3851;&#3926;&#4017;&#3908;&#3851;&#3942;&#3984;&#3956;&#3851;&#3906;&#3942;&#3956;&#3908;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3904;&#4017;&#3954;&#3851;&#3938;&#4001;&#3964;&#3851;&#3938;&#3991;&#3962;&#3938;&#3851;&#3906;&#4017;&#3956;&#3938;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>drip jang ku sung tuk kyi dorj&eacute;r gyur</span>
        </p>
        <p>
          <span>
            Of my body, speech and mind, and transforming them into vajra body,
            speech and mind.
          </span>
        </p>
        <p>
          <span>
            &#3928;&#3920;&#3938;&#3851;&#3923;&#3954;&#3851;&#3926;&#4019;&#3851;&#3928;&#3851;&#3936;&#3905;&#3964;&#3938;&#3851;&#3926;&#3909;&#3942;&#3851;&#3936;&#3964;&#3921;&#3851;&#3921;&#3956;&#3851;&#3934;&#3956;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>tar ni lama khor ch&eacute; &ouml; du shyu</span>
        </p>
        <p>
          <span>Finally, the guru and his retinue melt into light</span>
        </p>
        <p>
          <span>
            &#3921;&#3904;&#3938;&#3851;&#3921;&#3928;&#3938;&#3851;&#3920;&#3954;&#3906;&#3851;&#3939;&#3962;&#3851;&#3943;&#3953;&#3956;&#3971;&#3851;&#3906;&#3954;&#3942;&#3851;&#3928;&#3930;&#3923;&#3851;&#3924;&#3851;&#3938;&#3956;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>kar mar tigl&eacute; hum gi tsen pa ru</span>
        </p>
        <p>
          <span>
            And into a red and white bindu marked with h&#363;&#7747;,
          </span>
        </p>
        <p>
          <span>
            &#3938;&#3908;&#3851;&#3906;&#3954;&#3851;&#3942;&#3993;&#3954;&#3908;&#3851;&#3906;&#3938;&#3851;&#3920;&#3954;&#3928;&#3851;&#3924;&#3942;&#3851;&#3926;&#4019;&#3851;&#3928;&#3936;&#3954;&#3851;&#3920;&#3956;&#3906;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>rang gi nying gar timp&eacute; lam&eacute; tuk</span>
        </p>
        <p>
          <span>
            That dissolves into my heart, through which the guru&rsquo;s wisdom
            mind
          </span>
        </p>
        <p>
          <span>
            &#3938;&#3908;&#3851;&#3942;&#3962;&#3928;&#3942;&#3851;&#3921;&#3926;&#4017;&#3962;&#3938;&#3851;&#3928;&#3962;&#3921;&#3851;&#3939;&#4023;&#3923;&#3851;&#3942;&#3984;&#4017;&#3962;&#3942;&#3851;&#3910;&#3964;&#3942;&#3851;&#3942;&#3984;&#3956;&#3938;&#3851;&#3906;&#3923;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>
            rang sem yerm&eacute; lhen ky&eacute; ch&ouml; kur n&eacute;
          </span>
        </p>
        <p>
          <span>
            And my own mind merge inseparably, and so I abide in the
            ever-present dharmak&#257;ya.
          </span>
        </p>
        <p>
          <span>&#3944;&#3851;&#3944;&#3953;&#3967;</span>
        </p>
        <p>
          <span>a a</span>
        </p>
        <p>
          <span>a &#257;&#7717;</span>
        </p>
        <p>
          <span>
            &#3934;&#3962;&#3942;&#3851;&#3926;&#3938;&#3991;&#3964;&#3921;&#3851;&#3939;&#3851;&#3937;&#3962;&#3851;&#3923;&#3942;&#3851;&#3926;&#3909;&#3964;&#3942;&#3851;&#3926;&#3942;&#3986;&#4017;&#3956;&#3938;&#3851;&#3942;&#4004;&#3908;&#3851;&#3926;&#4019;&#3908;&#3851;&#3939;&#3942;&#3851;&#3936;&#3921;&#3942;&#3851;&#3924;&#3851;&#3938;&#3908;&#3851;&#3906;&#3954;&#3851;&#3942;&#3962;&#3928;&#3942;&#3851;&#3913;&#3954;&#3921;&#3851;&#3906;&#3921;&#3964;&#3921;&#3851;&#3928;&#3936;&#3954;&#3851;&#3910;&#3964;&#3942;&#3851;&#3942;&#3984;&#3956;&#3936;&#3954;&#3851;&#3910;&#3962;&#3923;&#3851;&#3924;&#3964;&#3936;&#3954;&#3851;&#3938;&#3908;&#3851;&#3934;&#3939;&#3851;&#3926;&#3939;&#3999;&#3853;
          </span>
        </p>
        <p>
          <span>
            As you utter &ldquo;a, &#257;&#7717;!&rdquo; look into the actual
            face of the great primordial dharmak&#257;ya&mdash;the very nature
            of your own mind that from the very beginning is utterly beyond
            alteration or change, beyond rejecting or accepting anything at all.
            Once again, consider all illusion-like appearances as the nature of
            the guru. Dedicate the merit and make prayers for auspiciousness, so
            that peace and wellbeing prevail.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#4019;&#3938;&#3851;&#3937;&#3908;&#3851;&#3942;&#3986;&#4017;&#3956;&#3851;&#3928;&#3851;&#3939;&#3999;&#3851;&#3926;&#3956;&#3936;&#3954;&#3851;&#3942;&#4003;&#3908;&#3851;&#3926;&#3851;&#3920;&#3928;&#3942;&#3851;&#3909;&#3921;&#3851;&#3926;&#4019;&#3851;&#3928;&#3936;&#3954;&#3851;&#3938;&#3908;&#3851;&#3926;&#3934;&#3954;&#3923;&#3851;&#3921;&#3956;&#3851;&#3926;&#3939;&#3999;&#3942;&#3851;&#3939;&#3851;&#3921;&#3906;&#3962;&#3851;&#3926;&#3851;&#3926;&#3942;&#3988;&#3964;&#3851;&#3934;&#3954;&#3908;&#3851;&#3940;&#3954;&#3942;&#3851;&#3924;&#3851;&#3926;&#3938;&#3991;&#3964;&#3921;&#3851;&#3924;&#3942;&#3851;&#3926;&#3921;&#3962;&#3851;&#3939;&#3962;&#3906;&#3942;&#3851;&#3942;&#3956;&#3851;&#3926;&#4017;&#3936;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>
            End the practice well by recognizing all illusory appearance as the
            nature of the guru, dedicating the merit and making a prayer of
            prosperity.
          </span>
        </p>
        <p>
          <span>
            &#3934;&#3962;&#3942;&#3851;&#3924;&#3936;&#3908;&#3851;&#3920;&#3928;&#3942;&#3851;&#3909;&#3921;&#3851;&#3936;&#3921;&#3956;&#3939;&#3851;&#3909;&#3962;&#3942;&#3851;&#3924;&#3936;&#3954;&#3851;&#3906;&#4018;&#3964;&#3851;&#3926;&#3934;&#3954;&#3923;&#3851;&#3935;&#4019;&#3851;&#3926;&#3936;&#3954;&#3851;&#3937;&#3938;&#3851;&#3930;&#3962;&#3942;&#3851;&#3926;&#3938;&#3986;&#4017;&#3921;&#3851;&#3939;&#3853;
            &#3930;&#3962;&#3851;&#3938;&#3926;&#3942;&#3851;&#3920;&#3928;&#3942;&#3851;&#3909;&#3921;&#3851;&#3921;&#3956;&#3851;&#3906;&#3956;&#3851;&#3938;&#3956;&#3851;&#3924;&#3921;&#4008;&#3936;&#3954;&#3851;&#3926;&#4018;&#3923;&#3851;&#3921;&#3956;&#3851;&#3942;&#4008;&#3964;&#3923;&#3851;&#3939;&#3928;&#3851;&#3936;&#3921;&#3962;&#3926;&#3942;&#3851;&#3924;&#3851;&#3928;&#3954;&#3851;&#3925;&#3928;&#3851;&#3938;&#4003;&#3928;&#3851;&#3924;&#3938;&#3851;&#3938;&#3986;&#4017;&#3939;&#3851;&#3926;&#3936;&#3954;&#3851;&#3937;&#3954;&#3921;&#3851;&#3904;&#4017;&#3954;&#3851;&#3928;&#3930;&#3964;&#3851;&#3939;&#3942;&#3851;&#3926;&#4017;&#3956;&#3908;&#3851;&#3926;&#3851;&#3921;&#3906;&#3962;&#3936;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>
            On the eighth day of the waxing moon in the seventh month of
            &lsquo;Taming All Beings&rsquo;,1 this arose from the oceanic mind
            of Mipham Nampar Gyalwa, one who prays and aspires to serve Guru
            Padmasambhava in all his lives. May virtue ensue!
          </span>
        </p>
        <p>
          <span>&#3928;&#3908;&#3986;&#3851;&#3939;&#3966;&#3853;&#3853;</span>
        </p>
        <p>
          <span>Ma&#7749;gala&#7747;.</span>
        </p>
        <p>
          <span>| Rigpa Translations, 2015.</span>
        </p>
        <p>
          <span>&uarr; i.e., the Fire Pig year, 1887</span>
        </p>
      </Container>
    </>
  );
}

export default ShowerOfBlessings;
