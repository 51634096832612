import React from "react";
import Container from "react-bootstrap/Container";
import * as S from "./styles";

function PrivacyPolicy() {
  return (
    <>
      <Container>
        <S.PageHeader>Privacy Policy</S.PageHeader>
        <S.PrivacyContainer>
          <div>
            <div>
              <div>
                <strong>
                  <span style={{ fontSize: 26 }}>
                    <span>
                      <u />
                      <u />
                      PRIVACY NOTICE
                      <u />
                      <u />
                    </span>
                  </span>
                </strong>
              </div>
              <div>
                <br />
              </div>
              <div>
                <span style={{ color: "rgb(127,127,127)" }}>
                  <strong>
                    <span style={{ fontSize: 15 }}>
                      <span>Last updated March 10, 2024</span>
                    </span>
                  </strong>
                </span>
              </div>
              <div>
                <br />
              </div>
              <div>
                <br />
              </div>
              <div>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127,127,127)" }}>
                  <span style={{ color: "rgb(89,89,89)", fontSize: 15 }}>
                    <span>
                      This privacy notice for Southern California Region Yeshe
                      Nyingpo (
                      <u />
                      <u />"<strong>we</strong>," "<strong>us</strong>," or "
                      <strong>our</strong>"<u />
                      <u />
                    </span>
                    <span>
                      ), describes how and why we might collect, store, use,
                      and/or share (<u />
                      <u />"<strong>process</strong>"<u />
                      <u />) your information when you use our services (<u />
                      <u />"<strong>Services</strong>"<u />
                      <u />
                      ), such as when you:
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(127,127,127)" }}>
                    <span>
                      <span style={{ color: "rgb(89,89,89)" }}>
                        <span>
                          <u />
                          <u />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <div>
                  <u />
                  <span style={{ fontSize: 15 }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(127,127,127)" }}>
                        <span>
                          <span style={{ color: "rgb(89,89,89)" }}>
                            <span>
                              <u />
                              <u />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <u />
                </div>
                <ul>
                  <li style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                        <span>
                          Download and use
                          <u />
                          <u /> our mobile application
                          <u />
                          <u /> (<u />
                          Seven Line Prayer )
                          <span
                            style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                            >
                              <span>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89,89,89)" }}>
                                    <span>
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89,89,89)" }}
                                        >
                                          <u />,<u />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <u />
                        </span>
                        <span>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89,89,89)" }}>
                              <span>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89,89,89)" }}>
                                    <u />
                                    <u /> or any other application of ours that
                                    links to this privacy notice
                                    <u />
                                    <u />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(127,127,127)" }}>
                      <span>
                        <span style={{ color: "rgb(89,89,89)" }}>
                          <span>
                            <u />
                            <u />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <ul>
                  <li style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                        <span>
                          Engage with us in other related ways, including any
                          sales, marketing, or events
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89,89,89)" }}>
                              <span>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89,89,89)" }}>
                                    <u />
                                    <u />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(127,127,127)" }}>
                      <span>
                        <strong>Questions or concerns? </strong>Reading this
                        privacy notice will help you understand your privacy
                        rights and choices. If you do not agree with our
                        policies and practices, please do not use our Services.
                        <u />
                        <u />
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <strong>
                    <span style={{ fontSize: 15 }}>
                      <span>SUMMARY OF KEY POINTS</span>
                    </span>
                  </strong>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <strong>
                        <em>
                          This summary provides key points from our privacy
                          notice, but you can find out more details about any of
                          these topics by clicking the link following each key
                          point or by using our{" "}
                        </em>
                      </strong>
                    </span>
                  </span>
                  <div rel="noreferrer">
                    <span style={{ color: "rgb(0,58,250)", fontSize: 15 }}>
                      <span>
                        <strong>
                          <em>table of contents</em>
                        </strong>
                      </span>
                    </span>
                  </div>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <strong>
                        <em> below to find the section you are looking for.</em>
                      </strong>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <strong>What personal information do we process?</strong>{" "}
                      When you visit, use, or navigate our Services, we may
                      process personal information depending on how you interact
                      with us and the Services, the choices you make, and the
                      products and features you use. Learn more about{" "}
                    </span>
                  </span>
                  <div rel="noreferrer">
                    <span style={{ color: "rgb(0,58,250)", fontSize: 15 }}>
                      <span>personal information you disclose to us</span>
                    </span>
                  </div>
                  <span>.</span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <strong>
                        Do we process any sensitive personal information?
                      </strong>{" "}
                      <u />
                      <u />
                      We do not process sensitive personal information.
                      <u />
                      <u />
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <strong>
                        Do we receive any information from third parties?
                      </strong>{" "}
                      <u />
                      <u />
                      We do not receive any information from third parties.
                      <u />
                      <u />
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <strong>How do we process your information?</strong> We
                      process your information to provide, improve, and
                      administer our Services, communicate with you, for
                      security and fraud prevention, and to comply with law. We
                      may also process your information for other purposes with
                      your consent. We process your information only when we
                      have a valid legal reason to do so. Learn more about{" "}
                    </span>
                  </span>
                  <div rel="noreferrer">
                    <span style={{ color: "rgb(0,58,250)", fontSize: 15 }}>
                      <span>how we process your information</span>
                    </span>
                  </div>
                  <span>.</span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <strong>
                        In what situations and with which <u />
                        <u />
                        parties do we share personal information?
                      </strong>{" "}
                      We may share information in specific situations and with
                      specific <u />
                      <u />
                      third parties. Learn more about{" "}
                    </span>
                  </span>
                  <div rel="noreferrer">
                    <span style={{ color: "rgb(0,58,250)", fontSize: 15 }}>
                      <span>
                        when and with whom we share your personal information
                      </span>
                    </span>
                  </div>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      .<u />
                      <u />
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <strong>How do we keep your information safe?</strong> We
                      have <u />
                      <u />
                      organizational
                      <u />
                      <u /> and technical processes and procedures in place to
                      protect your personal information. However, no electronic
                      transmission over the internet or information storage
                      technology can be guaranteed to be 100% secure, so we
                      cannot promise or guarantee that hackers, cybercriminals,
                      or other <u />
                      <u />
                      unauthorized
                      <u />
                      <u /> third parties will not be able to defeat our
                      security and improperly collect, access, steal, or modify
                      your information. Learn more about{" "}
                    </span>
                  </span>
                  <div rel="noreferrer">
                    <span style={{ color: "rgb(0,58,250)", fontSize: 15 }}>
                      <span>how we keep your information safe</span>
                    </span>
                  </div>
                  <span>.</span>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <u />
                      <u />
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <strong>What are your rights?</strong> Depending on where
                      you are located geographically, the applicable privacy law
                      may mean you have certain rights regarding your personal
                      information. Learn more about{" "}
                    </span>
                  </span>
                  <div rel="noreferrer">
                    <span style={{ color: "rgb(0,58,250)", fontSize: 15 }}>
                      <span>your privacy rights</span>
                    </span>
                  </div>
                  <span>.</span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <strong>How do you exercise your rights?</strong> The
                      easiest way to exercise your rights is by <u />
                      submitting a <u />
                    </span>
                  </span>
                  <a
                    href="https://www.google.com/url?q=https://app.termly.io/notify/f5fd18fe-85d8-4526-bd0f-3c11af680b56&source=gmail-html&ust=1710201824111000&usg=AOvVaw36gCnk1u4XgErAjaizOBll"
                    rel="noopener noreferrer noreferrer"
                    target="_blank"
                  >
                    <span style={{ color: "rgb(0,58,250)", fontSize: 15 }}>
                      <span>data subject access request</span>
                    </span>
                  </a>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <u />
                      <u />, or by contacting us. We will consider and act upon
                      any request in accordance with applicable data protection
                      laws.
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      Want to learn more about what we do with any information
                      we collect?{" "}
                    </span>
                  </span>
                  <div rel="noreferrer">
                    <span style={{ color: "rgb(0,58,250)", fontSize: 15 }}>
                      <span>Review the privacy notice in full</span>
                    </span>
                  </div>
                  <span style={{ fontSize: 15 }}>
                    <span>.</span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div id="m_toc" style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(127,127,127)" }}>
                      <span style={{ color: "rgb(0,0,0)" }}>
                        <strong>
                          <span>TABLE OF CONTENTS</span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <div rel="noreferrer">
                      <span style={{ color: "rgb(0,58,250)" }}>
                        1. WHAT INFORMATION DO WE COLLECT?
                      </span>
                    </div>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <div rel="noreferrer">
                      <span style={{ color: "rgb(0,58,250)" }}>
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                        <u />
                        <u />
                      </span>
                    </div>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <div rel="noreferrer">
                      <span style={{ color: "rgb(0,58,250)" }}>
                        3.{" "}
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(0,58,250)" }}>
                            WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                            PERSONAL INFORMATION?
                          </span>
                        </span>
                        <u />
                        <u />
                      </span>
                    </div>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(0,58,250)" }}>
                      <div rel="noreferrer">
                        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                        INFORMATION?
                      </div>
                    </span>
                    <span>
                      <u />
                      <u />
                      <span style={{ color: "rgb(127,127,127)" }}>
                        <span style={{ color: "rgb(89,89,89)" }}>
                          <span>
                            <span style={{ color: "rgb(89,89,89)" }}>
                              <u />
                              <u />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <div rel="noreferrer">
                      <span style={{ color: "rgb(0,58,250)" }}>
                        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                      </span>
                    </div>
                    <span style={{ color: "rgb(127,127,127)" }}>
                      <span style={{ color: "rgb(89,89,89)" }}>
                        <span>
                          <span style={{ color: "rgb(89,89,89)" }}>
                            <u />
                            <u />
                          </span>
                        </span>
                        <span>
                          <span style={{ color: "rgb(89,89,89)" }}>
                            <span style={{ color: "rgb(89,89,89)" }}>
                              <span style={{ color: "rgb(89,89,89)" }}>
                                <u />
                                <u />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <div rel="noreferrer">
                      <span style={{ color: "rgb(0,58,250)" }}>
                        <span style={{ color: "rgb(0,58,250)" }}>
                          <span style={{ color: "rgb(0,58,250)" }}>
                            6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                          </span>
                        </span>
                      </span>
                    </div>
                    <span style={{ color: "rgb(127,127,127)" }}>
                      <span style={{ color: "rgb(89,89,89)" }}>
                        <span>
                          <span style={{ color: "rgb(89,89,89)" }}>
                            <span style={{ color: "rgb(89,89,89)" }}>
                              <span style={{ color: "rgb(89,89,89)" }}>
                                <u />
                                <u />
                              </span>
                            </span>
                            <u />
                            <u />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <div rel="noreferrer">
                      <span style={{ color: "rgb(0,58,250)" }}>
                        7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                      </span>
                    </div>
                    <span style={{ color: "rgb(127,127,127)" }}>
                      <span style={{ color: "rgb(89,89,89)" }}>
                        <span>
                          <span style={{ color: "rgb(89,89,89)" }}>
                            <u />
                            <u />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <div rel="noreferrer">
                      <span style={{ color: "rgb(0,58,250)" }}>
                        8. HOW LONG DO WE KEEP YOUR INFORMATION?
                      </span>
                    </div>
                    <span style={{ color: "rgb(127,127,127)" }}>
                      <span style={{ color: "rgb(89,89,89)" }}>
                        <span>
                          <span style={{ color: "rgb(89,89,89)" }}>
                            <span style={{ color: "rgb(89,89,89)" }}>
                              <u />
                              <u />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <div rel="noreferrer">
                      <span style={{ color: "rgb(0,58,250)" }}>
                        9. HOW DO WE KEEP YOUR INFORMATION SAFE?
                      </span>
                    </div>
                    <span style={{ color: "rgb(127,127,127)" }}>
                      <span style={{ color: "rgb(89,89,89)" }}>
                        <span>
                          <span style={{ color: "rgb(89,89,89)" }}>
                            <u />
                            <u />
                            <u />
                            <u />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(0,58,250)" }}>
                      <div rel="noreferrer">
                        10. WHAT ARE YOUR PRIVACY RIGHTS?
                      </div>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <div rel="noreferrer">
                      <span style={{ color: "rgb(0,58,250)" }}>
                        11. CONTROLS FOR DO-NOT-TRACK FEATURES
                        <u />
                        <u />
                      </span>
                    </div>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <div rel="noreferrer">
                      <span style={{ color: "rgb(0,58,250)" }}>
                        12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY
                        RIGHTS?
                      </span>
                    </div>
                  </span>
                  <u />
                  <span style={{ fontSize: 15 }}>
                    <span />
                  </span>
                  <u />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <u />
                  <span style={{ fontSize: 15 }} />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                  <u />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <div rel="noreferrer">
                      <span style={{ color: "rgb(0,58,250)" }}>
                        13. DO WE MAKE UPDATES TO THIS NOTICE?
                      </span>
                    </div>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <div rel="noreferrer">
                    <span style={{ color: "rgb(0,58,250)", fontSize: 15 }}>
                      14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </span>
                  </div>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <div rel="noreferrer">
                    <span style={{ color: "rgb(0,58,250)" }}>
                      15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                      COLLECT FROM YOU?
                    </span>
                  </div>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div id="m_infocollect" style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(0,0,0)" }}>
                    <span style={{ color: "rgb(0,0,0)", fontSize: 15 }}>
                      <span style={{ fontSize: 15, color: "rgb(0,0,0)" }}>
                        <span style={{ fontSize: 15, color: "rgb(0,0,0)" }}>
                          <span id="m_control" style={{ color: "rgb(0,0,0)" }}>
                            <strong>
                              <span>1. WHAT INFORMATION DO WE COLLECT?</span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div id="m_personalinfo" style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(0,0,0)" }}>
                    <span style={{ fontSize: 15 }}>
                      <strong>Personal information you disclose to us</strong>
                    </span>
                  </span>
                </div>
                <div>
                  <div>
                    <br />
                  </div>
                  <div style={{ lineHeight: "1.5" }}>
                    <span style={{ color: "rgb(127,127,127)" }}>
                      <span style={{ color: "rgb(89,89,89)", fontSize: 15 }}>
                        <span>
                          <span
                            style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                            >
                              <span>
                                <strong>
                                  <em>In Short:</em>
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                        <span>
                          <span
                            style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                            >
                              <span>
                                <strong>
                                  <em> </em>
                                </strong>
                                <em>
                                  We collect personal information that you
                                  provide to us.
                                </em>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                      <span>
                        We collect personal information that you voluntarily
                        provide to us when you{" "}
                        <span style={{ fontSize: 15 }}>
                          <u />
                          <u />
                        </span>
                      </span>
                      <span>
                        express an interest in obtaining information about us or
                        our products and Services, when you participate in
                        activities on the Services, or otherwise when you
                        contact us.
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                      <span>
                        <span style={{ fontSize: 15 }}>
                          <u />
                          <u />
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div id="m_sensitiveinfo" style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <strong>Sensitive Information.</strong> <u />
                      <u />
                      We do not process sensitive information.
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span>
                      <u />
                      <u />
                    </span>
                  </span>
                  <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                      <span>
                        <span style={{ fontSize: 15 }}>
                          <span>
                            <u />
                            <u />
                            <u />
                            <u />
                          </span>
                        </span>
                      </span>
                    </span>
                    <u />
                    <u />
                    <u />
                    <u />
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span>
                    <span style={{ fontSize: 15 }}>
                      <strong>Application Data.</strong> If you use our
                      application(s), we also may collect the following
                      information if you choose to provide us with access or
                      permission:
                      <u />
                      <u />
                    </span>
                  </span>
                  <div style={{ lineHeight: "1.5" }}>
                    <u />
                    <span style={{ fontSize: 15 }}>
                      <span />
                    </span>
                    <u />
                    <div style={{ lineHeight: "1.5" }}>
                      <u />
                      <span style={{ fontSize: 15 }}>
                        <span />
                      </span>
                      <u />
                    </div>
                    <ul>
                      <li style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                          <span>
                            <em>Mobile Device Data.</em> We automatically
                            collect device information (such as your mobile
                            device ID, model, and manufacturer), operating
                            system, version information and system configuration
                            information, device and application identification
                            numbers, browser type and version, hardware model
                            Internet service provider and/or mobile carrier, and
                            Internet Protocol (IP) address (or proxy server). If
                            you are using our application(s), we may also
                            collect information about the phone network
                            associated with your mobile device, your mobile
                            device’s operating system or platform, the type of
                            mobile device you use, your mobile device’s unique
                            device ID, and information about the features of our
                            application(s) you accessed.
                            <u />
                            <u />
                          </span>
                        </span>
                      </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                      <u />
                      <span style={{ fontSize: 15 }}>
                        <span />
                      </span>
                      <u />
                      <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                          <span>
                            This information is primarily needed to maintain the
                            security and operation of our application(s), for
                            troubleshooting, and for our internal analytics and
                            reporting purposes.
                          </span>
                        </span>
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <u />
                        <span style={{ fontSize: 15 }}>
                          <span />
                        </span>
                        <u />
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                          <span
                            style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                          >
                            <span>
                              All personal information that you provide to us
                              must be true, complete, and accurate, and you must
                              notify us of any changes to such personal
                              information.
                            </span>
                          </span>
                        </span>
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                          <span
                            style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                          >
                            <span>
                              <u />
                              <u />
                            </span>
                            <span>
                              <span
                                style={{ color: "rgb(89,89,89)", fontSize: 15 }}
                              >
                                <span>
                                  <span
                                    style={{
                                      color: "rgb(89,89,89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span>
                                      <u />
                                      <u />
                                      <u />
                                      <u />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                        <span style={{ fontSize: 15 }}>
                          <span>
                            <u />
                            <u />
                          </span>
                        </span>
                      </div>
                      <div id="m_infouse" style={{ lineHeight: "1.5" }}>
                        <span style={{ color: "rgb(127,127,127)" }}>
                          <span
                            style={{ color: "rgb(89,89,89)", fontSize: 15 }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                            >
                              <span
                                style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                              >
                                <span
                                  id="m_control"
                                  style={{ color: "rgb(0,0,0)" }}
                                >
                                  <strong>
                                    <span>
                                      2. HOW DO WE PROCESS YOUR INFORMATION?
                                    </span>
                                  </strong>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span style={{ color: "rgb(127,127,127)" }}>
                            <span
                              style={{ color: "rgb(89,89,89)", fontSize: 15 }}
                            >
                              <span>
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89,89,89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89,89,89)",
                                    }}
                                  >
                                    <span>
                                      <strong>
                                        <em>In Short: </em>
                                      </strong>
                                      <em>
                                        We process your information to provide,
                                        improve, and administer our Services,
                                        communicate with you, for security and
                                        fraud prevention, and to comply with
                                        law. We may also process your
                                        information for other purposes with your
                                        consent.
                                      </em>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89,89,89)" }}>
                          <span
                            style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                          >
                            <span>
                              <strong>
                                We process your personal information for a
                                variety of reasons, depending on how you
                                interact with our Services, including:
                              </strong>
                              <u />
                              <u />
                            </span>
                          </span>
                        </span>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                            >
                              <span>
                                <u />
                                <u />
                              </span>
                            </span>
                          </span>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                            >
                              <span
                                style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                              >
                                <span>
                                  <u />
                                  <u />
                                </span>
                              </span>
                            </span>
                            <div style={{ lineHeight: "1.5" }}>
                              <span
                                style={{ fontSize: 15, color: "rgb(89,89,89)" }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89,89,89)",
                                  }}
                                >
                                  <span>
                                    <u />
                                    <u />
                                  </span>
                                </span>
                              </span>
                              <div style={{ lineHeight: "1.5" }}>
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89,89,89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89,89,89)",
                                    }}
                                  >
                                    <span>
                                      <u />
                                      <u />
                                    </span>
                                  </span>
                                </span>
                                <div style={{ lineHeight: "1.5" }}>
                                  <u />
                                  <span style={{ fontSize: 15 }} />
                                  <u />
                                  <div style={{ lineHeight: "1.5" }}>
                                    <u />
                                    <span style={{ fontSize: 15 }} />
                                    <u />
                                    <div style={{ lineHeight: "1.5" }}>
                                      <u />
                                      <span style={{ fontSize: 15 }} />
                                      <u />
                                      <div style={{ lineHeight: "1.5" }}>
                                        <u />
                                        <span style={{ fontSize: 15 }}>
                                          <span />
                                        </span>
                                        <u />
                                        <p
                                          style={{
                                            fontSize: 15,
                                            lineHeight: "1.5",
                                          }}
                                        >
                                          <u />
                                          <span style={{ fontSize: 15 }} />
                                          <u />
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 15,
                                            lineHeight: "1.5",
                                          }}
                                        >
                                          <u />
                                          <span style={{ fontSize: 15 }} />
                                          <u />
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 15,
                                            lineHeight: "1.5",
                                          }}
                                        >
                                          <u />
                                          <u />
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 15,
                                            lineHeight: "1.5",
                                          }}
                                        >
                                          <u />
                                          <u />
                                        </p>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <u />
                                          <span style={{ fontSize: 15 }}>
                                            <span />
                                          </span>
                                          <u />
                                          <div style={{ lineHeight: "1.5" }}>
                                            <u />
                                            <span style={{ fontSize: 15 }} />
                                            <u />
                                            <div style={{ lineHeight: "1.5" }}>
                                              <u />
                                              <span style={{ fontSize: 15 }} />
                                              <u />
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <u />
                                                  <span />
                                                  <u />
                                                </span>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <u />
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span />
                                                  </span>
                                                  <u />
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <u />
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span />
                                                    </span>
                                                    <u />
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <u />
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span />
                                                      </span>
                                                      <u />
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span>
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <strong>
                                                              To protect our
                                                              Services.
                                                            </strong>{" "}
                                                            We may process your
                                                            information as part
                                                            of our efforts to
                                                            keep our Services
                                                            safe and secure,
                                                            including fraud
                                                            monitoring and
                                                            prevention.
                                                          </span>
                                                        </span>
                                                        <u />
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span />
                                                        </span>
                                                        <u />
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <u />
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span />
                                                      </span>
                                                      <u />
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <u />
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span />
                                                        </span>
                                                        <u />
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <u />
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span />
                                                          </span>
                                                          <u />
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <u />
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span />
                                                            </span>
                                                            <u />
                                                            <div
                                                              style={{
                                                                lineHeight:
                                                                  "1.5",
                                                              }}
                                                            >
                                                              <u />
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span />
                                                              </span>
                                                              <u />
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5",
                                                                }}
                                                              >
                                                                <u />
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span />
                                                                </span>
                                                                <u />
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <u />
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span />
                                                                  </span>
                                                                  <u />
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <u />
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span />
                                                                    </span>
                                                                    <u />
                                                                  </div>
                                                                  <ul>
                                                                    <li
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5",
                                                                      }}
                                                                    >
                                                                      <span>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            To
                                                                            save
                                                                            or
                                                                            protect
                                                                            an
                                                                            individual's
                                                                            vital
                                                                            interest.
                                                                          </strong>{" "}
                                                                          We may
                                                                          process
                                                                          your
                                                                          information
                                                                          when
                                                                          necessary
                                                                          to
                                                                          save
                                                                          or
                                                                          protect
                                                                          an
                                                                          individual’s
                                                                          vital
                                                                          interest,
                                                                          such
                                                                          as to
                                                                          prevent
                                                                          harm.
                                                                        </span>
                                                                      </span>
                                                                      <u />
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span />
                                                                      </span>
                                                                      <u />
                                                                    </li>
                                                                  </ul>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <u />
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span />
                                                                    </span>
                                                                    <u />
                                                                    <u />
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span />
                                                                    </span>
                                                                    <u />
                                                                    <u />
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span />
                                                                    </span>
                                                                    <u />
                                                                    <u />
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span />
                                                                    </span>
                                                                    <u />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="m_legalbases"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span>
                                                                          3.
                                                                          WHAT
                                                                          LEGAL
                                                                          BASES
                                                                          DO WE
                                                                          RELY
                                                                          ON TO
                                                                          PROCESS
                                                                          YOUR
                                                                          INFORMATION?
                                                                        </span>
                                                                      </span>
                                                                    </strong>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <em>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span>
                                                                          <strong>
                                                                            In
                                                                            Short:{" "}
                                                                          </strong>
                                                                          We
                                                                          only
                                                                          process
                                                                          your
                                                                          personal
                                                                          information
                                                                          when
                                                                          we
                                                                          believe
                                                                          it is
                                                                          necessary
                                                                          and we
                                                                          have a
                                                                          valid
                                                                          legal
                                                                          reason
                                                                          (i.e.
                                                                          <u />
                                                                          <u />,
                                                                          <u />
                                                                          <u />{" "}
                                                                          legal
                                                                          basis)
                                                                          to do
                                                                          so
                                                                          under
                                                                          applicable
                                                                          law,
                                                                          like
                                                                          with
                                                                          your
                                                                          consent,
                                                                          to
                                                                          comply
                                                                          with
                                                                          laws,
                                                                          to
                                                                          provide
                                                                          you
                                                                          with
                                                                          services
                                                                          to
                                                                          enter
                                                                          into
                                                                          or{" "}
                                                                          <u />
                                                                          <u />
                                                                          fulfill
                                                                          <u />
                                                                          <u />{" "}
                                                                          our
                                                                          contractual
                                                                          obligations,
                                                                          to
                                                                          protect
                                                                          your
                                                                          rights,
                                                                          or to{" "}
                                                                          <u />
                                                                          <u />
                                                                          fulfill
                                                                          <u />
                                                                          <u />{" "}
                                                                          our
                                                                          legitimate
                                                                          business
                                                                          interests.
                                                                        </span>
                                                                      </span>
                                                                    </em>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span>
                                                                        <u />
                                                                        <u />
                                                                      </span>
                                                                      <span>
                                                                        <u />
                                                                        <u />
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <em>
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span>
                                                                          <strong>
                                                                            <u>
                                                                              If
                                                                              you
                                                                              are
                                                                              located
                                                                              in
                                                                              the
                                                                              EU
                                                                              or
                                                                              UK,
                                                                              this
                                                                              section
                                                                              applies
                                                                              to
                                                                              you.
                                                                            </u>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </em>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span>
                                                                        <u />
                                                                        <u />
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span>
                                                                        The
                                                                        General
                                                                        Data
                                                                        Protection
                                                                        Regulation
                                                                        (GDPR)
                                                                        and UK
                                                                        GDPR
                                                                        require
                                                                        us to
                                                                        explain
                                                                        the
                                                                        valid
                                                                        legal
                                                                        bases we
                                                                        rely on
                                                                        in order
                                                                        to
                                                                        process
                                                                        your
                                                                        personal
                                                                        information.
                                                                        As such,
                                                                        we may
                                                                        rely on
                                                                        the
                                                                        following
                                                                        legal
                                                                        bases to
                                                                        process
                                                                        your
                                                                        personal
                                                                        information:
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <ul>
                                                                    <li
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span>
                                                                          <strong>
                                                                            Consent.{" "}
                                                                          </strong>
                                                                          We may
                                                                          process
                                                                          your
                                                                          information
                                                                          if you
                                                                          have
                                                                          given
                                                                          us
                                                                          permission
                                                                          (i.e.
                                                                          <u />
                                                                          <u />,
                                                                          <u />
                                                                          <u />{" "}
                                                                          consent)
                                                                          to use
                                                                          your
                                                                          personal
                                                                          information
                                                                          for a
                                                                          specific
                                                                          purpose.
                                                                          You
                                                                          can
                                                                          withdraw
                                                                          your
                                                                          consent
                                                                          at any
                                                                          time.
                                                                          Learn
                                                                          more
                                                                          about{" "}
                                                                        </span>
                                                                      </span>
                                                                      <div rel="noreferrer">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(0,58,250)",
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span>
                                                                            withdrawing
                                                                            your
                                                                            consent
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <span>
                                                                        .
                                                                      </span>
                                                                    </li>
                                                                  </ul>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <u />
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span />
                                                                    </span>
                                                                    <u />
                                                                    <div
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5",
                                                                      }}
                                                                    >
                                                                      <u />
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span />
                                                                      </span>
                                                                      <u />
                                                                    </div>
                                                                    <ul>
                                                                      <li
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5",
                                                                        }}
                                                                      >
                                                                        <span>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              Legitimate
                                                                              Interests.
                                                                            </strong>{" "}
                                                                            We
                                                                            may
                                                                            process
                                                                            your
                                                                            information
                                                                            when
                                                                            we
                                                                            believe
                                                                            it
                                                                            is
                                                                            reasonably
                                                                            necessary
                                                                            to
                                                                            achieve
                                                                            our
                                                                            legitimate
                                                                            business
                                                                            interests
                                                                            and
                                                                            those
                                                                            interests
                                                                            do
                                                                            not
                                                                            outweigh
                                                                            your
                                                                            interests
                                                                            and
                                                                            fundamental
                                                                            rights
                                                                            and
                                                                            freedoms.
                                                                            For
                                                                            example,
                                                                            we
                                                                            may
                                                                            process
                                                                            your
                                                                            personal
                                                                            information
                                                                            for
                                                                            some
                                                                            of
                                                                            the
                                                                            purposes
                                                                            described
                                                                            in
                                                                            order
                                                                            to:
                                                                          </span>
                                                                        </span>
                                                                      </li>
                                                                    </ul>
                                                                    <div
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5",
                                                                      }}
                                                                    >
                                                                      <span>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <u />
                                                                          <u />
                                                                        </span>
                                                                      </span>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5",
                                                                        }}
                                                                      >
                                                                        <span>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <u />
                                                                            <u />
                                                                          </span>
                                                                        </span>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <span>
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <u />
                                                                              <u />
                                                                            </span>
                                                                          </span>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5",
                                                                            }}
                                                                          >
                                                                            <span>
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <u />
                                                                                <u />
                                                                              </span>
                                                                            </span>
                                                                            <div
                                                                              style={{
                                                                                lineHeight:
                                                                                  "1.5",
                                                                              }}
                                                                            >
                                                                              <span>
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <u />
                                                                                  <u />
                                                                                </span>
                                                                              </span>
                                                                            </div>
                                                                            <ul
                                                                              style={{
                                                                                marginLeft: 40,
                                                                              }}
                                                                            >
                                                                              <li
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    Diagnose
                                                                                    problems
                                                                                    and/or
                                                                                    prevent
                                                                                    fraudulent
                                                                                    activities
                                                                                    <u />
                                                                                    <u />
                                                                                  </span>
                                                                                </span>
                                                                              </li>
                                                                            </ul>
                                                                            <div
                                                                              style={{
                                                                                lineHeight:
                                                                                  "1.5",
                                                                              }}
                                                                            >
                                                                              <span>
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <u />
                                                                                  <u />
                                                                                </span>
                                                                              </span>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <u />
                                                                                    <u />
                                                                                  </span>
                                                                                </span>
                                                                                <u />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span />
                                                                                </span>
                                                                                <u />
                                                                                <u />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span />
                                                                                </span>
                                                                                <u />
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        Legal
                                                                                        Obligations.
                                                                                      </strong>{" "}
                                                                                      We
                                                                                      may
                                                                                      process
                                                                                      your
                                                                                      information
                                                                                      where
                                                                                      we
                                                                                      believe
                                                                                      it
                                                                                      is
                                                                                      necessary
                                                                                      for
                                                                                      compliance
                                                                                      with
                                                                                      our
                                                                                      legal
                                                                                      obligations,
                                                                                      such
                                                                                      as
                                                                                      to
                                                                                      cooperate
                                                                                      with
                                                                                      a
                                                                                      law
                                                                                      enforcement
                                                                                      body
                                                                                      or
                                                                                      regulatory
                                                                                      agency,
                                                                                      exercise
                                                                                      or
                                                                                      defend
                                                                                      our
                                                                                      legal
                                                                                      rights,
                                                                                      or
                                                                                      disclose
                                                                                      your
                                                                                      information
                                                                                      as
                                                                                      evidence
                                                                                      in
                                                                                      litigation
                                                                                      in
                                                                                      which
                                                                                      we
                                                                                      are
                                                                                      involved.
                                                                                      <u />
                                                                                      <u />
                                                                                      <br />
                                                                                    </span>
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <u />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span />
                                                                                </span>
                                                                                <u />
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        Vital
                                                                                        Interests.
                                                                                      </strong>{" "}
                                                                                      We
                                                                                      may
                                                                                      process
                                                                                      your
                                                                                      information
                                                                                      where
                                                                                      we
                                                                                      believe
                                                                                      it
                                                                                      is
                                                                                      necessary
                                                                                      to
                                                                                      protect
                                                                                      your
                                                                                      vital
                                                                                      interests
                                                                                      or
                                                                                      the
                                                                                      vital
                                                                                      interests
                                                                                      of
                                                                                      a
                                                                                      third
                                                                                      party,
                                                                                      such
                                                                                      as
                                                                                      situations
                                                                                      involving
                                                                                      potential
                                                                                      threats
                                                                                      to
                                                                                      the
                                                                                      safety
                                                                                      of
                                                                                      any
                                                                                      person.
                                                                                    </span>
                                                                                  </span>
                                                                                  <u />
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span />
                                                                                  </span>
                                                                                  <u />
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <u />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span />
                                                                                </span>
                                                                                <u />
                                                                                <span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <u />
                                                                                    <u />
                                                                                    <u />
                                                                                    <u />
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <br />
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <strong>
                                                                                      <u>
                                                                                        <em>
                                                                                          If
                                                                                          you
                                                                                          are
                                                                                          located
                                                                                          in
                                                                                          Canada,
                                                                                          this
                                                                                          section
                                                                                          applies
                                                                                          to
                                                                                          you.
                                                                                        </em>
                                                                                      </u>
                                                                                    </strong>
                                                                                    <u />
                                                                                    <u />
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <br />
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    We
                                                                                    may
                                                                                    process
                                                                                    your
                                                                                    information
                                                                                    if
                                                                                    you
                                                                                    have
                                                                                    given
                                                                                    us
                                                                                    specific
                                                                                    permission
                                                                                    (i.e.
                                                                                    <u />
                                                                                    <u />
                                                                                    ,
                                                                                    <u />
                                                                                    <u />{" "}
                                                                                    express
                                                                                    consent)
                                                                                    to
                                                                                    use
                                                                                    your
                                                                                    personal
                                                                                    information
                                                                                    for
                                                                                    a
                                                                                    specific
                                                                                    purpose,
                                                                                    or
                                                                                    in
                                                                                    situations
                                                                                    where
                                                                                    your
                                                                                    permission
                                                                                    can
                                                                                    be
                                                                                    inferred
                                                                                    (i.e.
                                                                                    <u />
                                                                                    <u />
                                                                                    ,
                                                                                    <u />
                                                                                    <u />{" "}
                                                                                    implied
                                                                                    consent).
                                                                                    You
                                                                                    can{" "}
                                                                                  </span>
                                                                                </span>
                                                                                <div rel="noreferrer">
                                                                                  <span>
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(0,58,250)",
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      withdraw
                                                                                      your
                                                                                      consent
                                                                                    </span>
                                                                                  </span>
                                                                                </div>
                                                                                <span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    {" "}
                                                                                    at
                                                                                    any
                                                                                    time.
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <br />
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    In
                                                                                    some
                                                                                    exceptional
                                                                                    cases,
                                                                                    we
                                                                                    may
                                                                                    be
                                                                                    legally
                                                                                    permitted
                                                                                    under
                                                                                    applicable
                                                                                    law
                                                                                    to
                                                                                    process
                                                                                    your
                                                                                    information
                                                                                    without
                                                                                    your
                                                                                    consent,
                                                                                    including,
                                                                                    for
                                                                                    example:
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      If
                                                                                      collection
                                                                                      is
                                                                                      clearly
                                                                                      in
                                                                                      the
                                                                                      interests
                                                                                      of
                                                                                      an
                                                                                      individual
                                                                                      and
                                                                                      consent
                                                                                      cannot
                                                                                      be
                                                                                      obtained
                                                                                      in
                                                                                      a
                                                                                      timely
                                                                                      way
                                                                                    </span>
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <u />
                                                                                    <u />
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      For
                                                                                      investigations
                                                                                      and
                                                                                      fraud
                                                                                      detection
                                                                                      and
                                                                                      prevention
                                                                                      <u />
                                                                                      <u />
                                                                                    </span>
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <u />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span />
                                                                                </span>
                                                                                <u />
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      For
                                                                                      business
                                                                                      transactions
                                                                                      provided
                                                                                      certain
                                                                                      conditions
                                                                                      are
                                                                                      met
                                                                                    </span>
                                                                                  </span>
                                                                                  <u />
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span />
                                                                                  </span>
                                                                                  <u />
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <u />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span />
                                                                                </span>
                                                                                <u />
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      If
                                                                                      it
                                                                                      is
                                                                                      contained
                                                                                      in
                                                                                      a
                                                                                      witness
                                                                                      statement
                                                                                      and
                                                                                      the
                                                                                      collection
                                                                                      is
                                                                                      necessary
                                                                                      to
                                                                                      assess,
                                                                                      process,
                                                                                      or
                                                                                      settle
                                                                                      an
                                                                                      insurance
                                                                                      claim
                                                                                    </span>
                                                                                  </span>
                                                                                  <u />
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span />
                                                                                  </span>
                                                                                  <u />
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <u />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span />
                                                                                </span>
                                                                                <u />
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      For
                                                                                      identifying
                                                                                      injured,
                                                                                      ill,
                                                                                      or
                                                                                      deceased
                                                                                      persons
                                                                                      and
                                                                                      communicating
                                                                                      with
                                                                                      next
                                                                                      of
                                                                                      kin
                                                                                    </span>
                                                                                  </span>
                                                                                  <u />
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span />
                                                                                  </span>
                                                                                  <u />
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <u />
                                                                                    <u />
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      If
                                                                                      we
                                                                                      have
                                                                                      reasonable
                                                                                      grounds
                                                                                      to
                                                                                      believe
                                                                                      an
                                                                                      individual
                                                                                      has
                                                                                      been,
                                                                                      is,
                                                                                      or
                                                                                      may
                                                                                      be
                                                                                      victim
                                                                                      of
                                                                                      financial
                                                                                      abuse
                                                                                      <u />
                                                                                      <u />
                                                                                    </span>
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <u />
                                                                                    <u />
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      If
                                                                                      it
                                                                                      is
                                                                                      reasonable
                                                                                      to
                                                                                      expect
                                                                                      collection
                                                                                      and
                                                                                      use
                                                                                      with
                                                                                      consent
                                                                                      would
                                                                                      compromise
                                                                                      the
                                                                                      availability
                                                                                      or
                                                                                      the
                                                                                      accuracy
                                                                                      of
                                                                                      the
                                                                                      information
                                                                                      and
                                                                                      the
                                                                                      collection
                                                                                      is
                                                                                      reasonable
                                                                                      for
                                                                                      purposes
                                                                                      related
                                                                                      to
                                                                                      investigating
                                                                                      a
                                                                                      breach
                                                                                      of
                                                                                      an
                                                                                      agreement
                                                                                      or
                                                                                      a
                                                                                      contravention
                                                                                      of
                                                                                      the
                                                                                      laws
                                                                                      of
                                                                                      Canada
                                                                                      or
                                                                                      a
                                                                                      province
                                                                                      <u />
                                                                                      <u />
                                                                                    </span>
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span>
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <u />
                                                                                    <u />
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      If
                                                                                      disclosure
                                                                                      is
                                                                                      required
                                                                                      to
                                                                                      comply
                                                                                      with
                                                                                      a
                                                                                      subpoena,
                                                                                      warrant,
                                                                                      court
                                                                                      order,
                                                                                      or
                                                                                      rules
                                                                                      of
                                                                                      the
                                                                                      court
                                                                                      relating
                                                                                      to
                                                                                      the
                                                                                      production
                                                                                      of
                                                                                      records
                                                                                      <u />
                                                                                      <u />
                                                                                    </span>
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <u />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span />
                                                                                </span>
                                                                                <u />
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span>
                                                                                      If
                                                                                      it
                                                                                      was
                                                                                      produced
                                                                                      by
                                                                                      an
                                                                                      individual
                                                                                      in
                                                                                      the
                                                                                      course
                                                                                      of
                                                                                      their
                                                                                      employment,
                                                                                      business,
                                                                                      or
                                                                                      profession
                                                                                      and
                                                                                      the
                                                                                      collection
                                                                                      is
                                                                                      consistent
                                                                                      with
                                                                                      the
                                                                                      purposes
                                                                                      for
                                                                                      which
                                                                                      the
                                                                                      information
                                                                                      was
                                                                                      produced
                                                                                      <u />
                                                                                      <u />
                                                                                    </span>
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <u />
                                                                                    <u />
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span>
                                                                                      If
                                                                                      the
                                                                                      collection
                                                                                      is
                                                                                      solely
                                                                                      for
                                                                                      journalistic,
                                                                                      artistic,
                                                                                      or
                                                                                      literary
                                                                                      purposes
                                                                                      <u />
                                                                                      <u />
                                                                                    </span>
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    <u />
                                                                                    <u />
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span>
                                                                                      If
                                                                                      the
                                                                                      information
                                                                                      is
                                                                                      publicly
                                                                                      available
                                                                                      and
                                                                                      is
                                                                                      specified
                                                                                      by
                                                                                      the
                                                                                      regulations
                                                                                    </span>
                                                                                    <u />
                                                                                    <span />
                                                                                    <u />
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <u />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span />
                                                                                </span>
                                                                                <u />
                                                                                <u />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span />
                                                                                </span>
                                                                                <u />
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <br />
                                                                              </div>
                                                                              <div
                                                                                id="m_whoshare"
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(127,127,127)",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89,89,89)",
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color:
                                                                                          "rgb(89,89,89)",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89,89,89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          id="m_control"
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(0,0,0)",
                                                                                          }}
                                                                                        >
                                                                                          <strong>
                                                                                            <span>
                                                                                              4.
                                                                                              WHEN
                                                                                              AND
                                                                                              WITH
                                                                                              WHOM
                                                                                              DO
                                                                                              WE
                                                                                              SHARE
                                                                                              YOUR
                                                                                              PERSONAL
                                                                                              INFORMATION?
                                                                                            </span>
                                                                                          </strong>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <br />
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89,89,89)",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        "rgb(89,89,89)",
                                                                                    }}
                                                                                  >
                                                                                    <span>
                                                                                      <strong>
                                                                                        <em>
                                                                                          In
                                                                                          Short:
                                                                                        </em>
                                                                                      </strong>
                                                                                      <em>
                                                                                        {" "}
                                                                                        We
                                                                                        may
                                                                                        share
                                                                                        information
                                                                                        in
                                                                                        specific
                                                                                        situations
                                                                                        described
                                                                                        in
                                                                                        this
                                                                                        section
                                                                                        and/or
                                                                                        with
                                                                                        the
                                                                                        following{" "}
                                                                                        <u />
                                                                                        <u />
                                                                                        third
                                                                                        parties.
                                                                                      </em>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89,89,89)",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        "rgb(89,89,89)",
                                                                                    }}
                                                                                  >
                                                                                    <span>
                                                                                      <u />
                                                                                      <u />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <br />
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span>
                                                                                    We{" "}
                                                                                    <u />
                                                                                    <u />
                                                                                    may
                                                                                    need
                                                                                    to
                                                                                    share
                                                                                    your
                                                                                    personal
                                                                                    information
                                                                                    in
                                                                                    the
                                                                                    following
                                                                                    situations:
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span>
                                                                                      <strong>
                                                                                        Business
                                                                                        Transfers.
                                                                                      </strong>{" "}
                                                                                      We
                                                                                      may
                                                                                      share
                                                                                      or
                                                                                      transfer
                                                                                      your
                                                                                      information
                                                                                      in
                                                                                      connection
                                                                                      with,
                                                                                      or
                                                                                      during
                                                                                      negotiations
                                                                                      of,
                                                                                      any
                                                                                      merger,
                                                                                      sale
                                                                                      of
                                                                                      company
                                                                                      assets,
                                                                                      financing,
                                                                                      or
                                                                                      acquisition
                                                                                      of
                                                                                      all
                                                                                      or
                                                                                      a
                                                                                      portion
                                                                                      of
                                                                                      our
                                                                                      business
                                                                                      to
                                                                                      another
                                                                                      company.
                                                                                    </span>
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5",
                                                                                }}
                                                                              >
                                                                                <u />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                />
                                                                                <u />
                                                                                <div
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span>
                                                                                      <u />
                                                                                      <u />
                                                                                    </span>
                                                                                  </span>
                                                                                  <div
                                                                                    style={{
                                                                                      lineHeight:
                                                                                        "1.5",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <u />
                                                                                      <span />
                                                                                      <u />
                                                                                    </span>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <u />
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span />
                                                                                      </span>
                                                                                      <u />
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <u />
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span />
                                                                                        </span>
                                                                                        <u />
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight:
                                                                                              "1.5",
                                                                                          }}
                                                                                        >
                                                                                          <u />
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}
                                                                                          >
                                                                                            <span />
                                                                                          </span>
                                                                                          <u />
                                                                                          <span>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <u />
                                                                                              <u />
                                                                                            </span>
                                                                                          </span>
                                                                                          <u />
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}
                                                                                          >
                                                                                            <span />
                                                                                          </span>
                                                                                          <u />
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <u />
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span />
                                                                                            </span>
                                                                                            <u />
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <u />
                                                                                                      <span />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            id="m_cookies"
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(127,127,127)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      id="m_control"
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0,0,0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <strong>
                                                                                                        <span>
                                                                                                          5.
                                                                                                          DO
                                                                                                          WE
                                                                                                          USE
                                                                                                          COOKIES
                                                                                                          AND
                                                                                                          OTHER
                                                                                                          TRACKING
                                                                                                          TECHNOLOGIES?
                                                                                                        </span>
                                                                                                      </strong>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <strong>
                                                                                                    <em>
                                                                                                      In
                                                                                                      Short:
                                                                                                    </em>
                                                                                                  </strong>
                                                                                                  <em>
                                                                                                    {" "}
                                                                                                    We
                                                                                                    may
                                                                                                    use
                                                                                                    cookies
                                                                                                    and
                                                                                                    other
                                                                                                    tracking
                                                                                                    technologies
                                                                                                    to
                                                                                                    collect
                                                                                                    and
                                                                                                    store
                                                                                                    your
                                                                                                    information.
                                                                                                  </em>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  We
                                                                                                  may
                                                                                                  use
                                                                                                  cookies
                                                                                                  and
                                                                                                  similar
                                                                                                  tracking
                                                                                                  technologies
                                                                                                  (like
                                                                                                  web
                                                                                                  beacons
                                                                                                  and
                                                                                                  pixels)
                                                                                                  to
                                                                                                  access
                                                                                                  or
                                                                                                  store
                                                                                                  information.
                                                                                                  Specific
                                                                                                  information
                                                                                                  about
                                                                                                  how
                                                                                                  we
                                                                                                  use
                                                                                                  such
                                                                                                  technologies
                                                                                                  and
                                                                                                  how
                                                                                                  you
                                                                                                  can
                                                                                                  refuse
                                                                                                  certain
                                                                                                  cookies
                                                                                                  is
                                                                                                  set
                                                                                                  out
                                                                                                  in
                                                                                                  our
                                                                                                  Cookie
                                                                                                  Notice
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />

                                                                                                      .
                                                                                                    </span>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89,89,89)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89,89,89)",
                                                                                                              }}
                                                                                                            >
                                                                                                              <span>
                                                                                                                <u />
                                                                                                                <u />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                                <span>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89,89,89)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89,89,89)",
                                                                                                                }}
                                                                                                              >
                                                                                                                <span>
                                                                                                                  <u />
                                                                                                                  <u />
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            id="m_sociallogins"
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(127,127,127)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      id="m_control"
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0,0,0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <strong>
                                                                                                        <span>
                                                                                                          6.
                                                                                                          HOW
                                                                                                          DO
                                                                                                          WE
                                                                                                          HANDLE
                                                                                                          YOUR
                                                                                                          SOCIAL
                                                                                                          LOGINS?
                                                                                                        </span>
                                                                                                      </strong>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <strong>
                                                                                                    <em>
                                                                                                      In
                                                                                                      Short:{" "}
                                                                                                    </em>
                                                                                                  </strong>
                                                                                                  <em>
                                                                                                    If
                                                                                                    you
                                                                                                    choose
                                                                                                    to
                                                                                                    register
                                                                                                    or
                                                                                                    log
                                                                                                    in
                                                                                                    to
                                                                                                    our
                                                                                                    Services
                                                                                                    using
                                                                                                    a
                                                                                                    social
                                                                                                    media
                                                                                                    account,
                                                                                                    we
                                                                                                    may
                                                                                                    have
                                                                                                    access
                                                                                                    to
                                                                                                    certain
                                                                                                    information
                                                                                                    about
                                                                                                    you.
                                                                                                  </em>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  Our
                                                                                                  Services
                                                                                                  offer
                                                                                                  you
                                                                                                  the
                                                                                                  ability
                                                                                                  to
                                                                                                  register
                                                                                                  and
                                                                                                  log
                                                                                                  in
                                                                                                  using
                                                                                                  your
                                                                                                  third-party
                                                                                                  social
                                                                                                  media
                                                                                                  account
                                                                                                  details
                                                                                                  (like
                                                                                                  your
                                                                                                  Facebook
                                                                                                  or
                                                                                                  Twitter
                                                                                                  logins).
                                                                                                  Where
                                                                                                  you
                                                                                                  choose
                                                                                                  to
                                                                                                  do
                                                                                                  this,
                                                                                                  we
                                                                                                  will
                                                                                                  receive
                                                                                                  certain
                                                                                                  profile
                                                                                                  information
                                                                                                  about
                                                                                                  you
                                                                                                  from
                                                                                                  your
                                                                                                  social
                                                                                                  media
                                                                                                  provider.
                                                                                                  The
                                                                                                  profile
                                                                                                  information
                                                                                                  we
                                                                                                  receive
                                                                                                  may
                                                                                                  vary
                                                                                                  depending
                                                                                                  on
                                                                                                  the
                                                                                                  social
                                                                                                  media
                                                                                                  provider
                                                                                                  concerned,
                                                                                                  but
                                                                                                  will
                                                                                                  often
                                                                                                  include
                                                                                                  your
                                                                                                  name,
                                                                                                  email
                                                                                                  address,
                                                                                                  friends
                                                                                                  list,
                                                                                                  and
                                                                                                  profile
                                                                                                  picture,
                                                                                                  as
                                                                                                  well
                                                                                                  as
                                                                                                  other
                                                                                                  information
                                                                                                  you
                                                                                                  choose
                                                                                                  to
                                                                                                  make
                                                                                                  public
                                                                                                  on
                                                                                                  such
                                                                                                  a
                                                                                                  social
                                                                                                  media
                                                                                                  platform.
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <u />
                                                                                                        <u />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  We
                                                                                                  will
                                                                                                  use
                                                                                                  the
                                                                                                  information
                                                                                                  we
                                                                                                  receive
                                                                                                  only
                                                                                                  for
                                                                                                  the
                                                                                                  purposes
                                                                                                  that
                                                                                                  are
                                                                                                  described
                                                                                                  in
                                                                                                  this
                                                                                                  privacy
                                                                                                  notice
                                                                                                  or
                                                                                                  that
                                                                                                  are
                                                                                                  otherwise
                                                                                                  made
                                                                                                  clear
                                                                                                  to
                                                                                                  you
                                                                                                  on
                                                                                                  the
                                                                                                  relevant
                                                                                                  Services.
                                                                                                  Please
                                                                                                  note
                                                                                                  that
                                                                                                  we
                                                                                                  do
                                                                                                  not
                                                                                                  control,
                                                                                                  and
                                                                                                  are
                                                                                                  not
                                                                                                  responsible
                                                                                                  for,
                                                                                                  other
                                                                                                  uses
                                                                                                  of
                                                                                                  your
                                                                                                  personal
                                                                                                  information
                                                                                                  by
                                                                                                  your
                                                                                                  third-party
                                                                                                  social
                                                                                                  media
                                                                                                  provider.
                                                                                                  We
                                                                                                  recommend
                                                                                                  that
                                                                                                  you
                                                                                                  review
                                                                                                  their
                                                                                                  privacy
                                                                                                  notice
                                                                                                  to
                                                                                                  understand
                                                                                                  how
                                                                                                  they
                                                                                                  collect,
                                                                                                  use,
                                                                                                  and
                                                                                                  share
                                                                                                  your
                                                                                                  personal
                                                                                                  information,
                                                                                                  and
                                                                                                  how
                                                                                                  you
                                                                                                  can
                                                                                                  set
                                                                                                  your
                                                                                                  privacy
                                                                                                  preferences
                                                                                                  on
                                                                                                  their
                                                                                                  sites
                                                                                                  and
                                                                                                  apps.
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89,89,89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89,89,89)",
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89,89,89)",
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span>
                                                                                                                    <u />
                                                                                                                    <u />
                                                                                                                  </span>
                                                                                                                  <u />
                                                                                                                  <span>
                                                                                                                    <u />
                                                                                                                    <u />
                                                                                                                  </span>
                                                                                                                  <u />
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            id="m_intltransfers"
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(127,127,127)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      id="m_control"
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0,0,0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <strong>
                                                                                                        <span>
                                                                                                          7.
                                                                                                          IS
                                                                                                          YOUR
                                                                                                          INFORMATION
                                                                                                          TRANSFERRED
                                                                                                          INTERNATIONALLY?
                                                                                                        </span>
                                                                                                      </strong>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <strong>
                                                                                                    <em>
                                                                                                      In
                                                                                                      Short:{" "}
                                                                                                    </em>
                                                                                                  </strong>
                                                                                                  <em>
                                                                                                    We
                                                                                                    may
                                                                                                    transfer,
                                                                                                    store,
                                                                                                    and
                                                                                                    process
                                                                                                    your
                                                                                                    information
                                                                                                    in
                                                                                                    countries
                                                                                                    other
                                                                                                    than
                                                                                                    your
                                                                                                    own.
                                                                                                  </em>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  Our
                                                                                                  servers
                                                                                                  are
                                                                                                  located
                                                                                                  in
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <u />
                                                                                                        <u />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        .
                                                                                                        If
                                                                                                        you
                                                                                                        are
                                                                                                        accessing
                                                                                                        our
                                                                                                        Services
                                                                                                        from
                                                                                                        outside
                                                                                                      </span>
                                                                                                      <span>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89,89,89)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span>
                                                                                                              <u />
                                                                                                              <u />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                      <span>
                                                                                                        ,
                                                                                                        please
                                                                                                        be
                                                                                                        aware
                                                                                                        that
                                                                                                        your
                                                                                                        information
                                                                                                        may
                                                                                                        be
                                                                                                        transferred
                                                                                                        to,
                                                                                                        stored,
                                                                                                        and
                                                                                                        processed
                                                                                                        by
                                                                                                        us
                                                                                                        in
                                                                                                        our
                                                                                                        facilities
                                                                                                        and
                                                                                                        by
                                                                                                        those
                                                                                                        third
                                                                                                        parties
                                                                                                        with
                                                                                                        whom
                                                                                                        we
                                                                                                        may
                                                                                                        share
                                                                                                        your
                                                                                                        personal
                                                                                                        information
                                                                                                        (see{" "}
                                                                                                        <u />
                                                                                                        <u />
                                                                                                        "
                                                                                                        <u />
                                                                                                        <u />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                  <div rel="noreferrer">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0,58,250)",
                                                                                                        }}
                                                                                                      >
                                                                                                        WHEN
                                                                                                        AND
                                                                                                        WITH
                                                                                                        WHOM
                                                                                                        DO
                                                                                                        WE
                                                                                                        SHARE
                                                                                                        YOUR
                                                                                                        PERSONAL
                                                                                                        INFORMATION?
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <u />
                                                                                                        <u />
                                                                                                        "
                                                                                                        <u />
                                                                                                        <u />{" "}
                                                                                                        above),
                                                                                                        in
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <u />
                                                                                                        <u />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        {" "}
                                                                                                        and
                                                                                                        other
                                                                                                        countries.
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  If
                                                                                                  you
                                                                                                  are
                                                                                                  a
                                                                                                  resident
                                                                                                  in
                                                                                                  the
                                                                                                  European
                                                                                                  Economic
                                                                                                  Area
                                                                                                  (EEA),
                                                                                                  United
                                                                                                  Kingdom
                                                                                                  (UK),
                                                                                                  or
                                                                                                  Switzerland,
                                                                                                  then
                                                                                                  these
                                                                                                  countries
                                                                                                  may
                                                                                                  not
                                                                                                  necessarily
                                                                                                  have
                                                                                                  data
                                                                                                  protection
                                                                                                  laws
                                                                                                  or
                                                                                                  other
                                                                                                  similar
                                                                                                  laws
                                                                                                  as
                                                                                                  comprehensive
                                                                                                  as
                                                                                                  those
                                                                                                  in
                                                                                                  your
                                                                                                  country.
                                                                                                  However,
                                                                                                  we
                                                                                                  will
                                                                                                  take
                                                                                                  all
                                                                                                  necessary
                                                                                                  measures
                                                                                                  to
                                                                                                  protect
                                                                                                  your
                                                                                                  personal
                                                                                                  information
                                                                                                  in
                                                                                                  accordance
                                                                                                  with
                                                                                                  this
                                                                                                  privacy
                                                                                                  notice
                                                                                                  and
                                                                                                  applicable
                                                                                                  law.
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <u />
                                                                                                        <u />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <u />
                                                                                                  <u />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <span>
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <u />
                                                                                                    <u />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89,89,89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89,89,89)",
                                                                                                              fontSize: 15,
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89,89,89)",
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89,89,89)",
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span>
                                                                                                                      <u />
                                                                                                                      <u />
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            id="m_inforetain"
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(127,127,127)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      id="m_control"
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0,0,0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <strong>
                                                                                                        <span>
                                                                                                          8.
                                                                                                          HOW
                                                                                                          LONG
                                                                                                          DO
                                                                                                          WE
                                                                                                          KEEP
                                                                                                          YOUR
                                                                                                          INFORMATION?
                                                                                                        </span>
                                                                                                      </strong>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <strong>
                                                                                                    <em>
                                                                                                      In
                                                                                                      Short:{" "}
                                                                                                    </em>
                                                                                                  </strong>
                                                                                                  <em>
                                                                                                    We
                                                                                                    keep
                                                                                                    your
                                                                                                    information
                                                                                                    for
                                                                                                    as
                                                                                                    long
                                                                                                    as
                                                                                                    necessary
                                                                                                    to{" "}
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    fulfill
                                                                                                    <u />
                                                                                                    <u />{" "}
                                                                                                    the
                                                                                                    purposes
                                                                                                    outlined
                                                                                                    in
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice
                                                                                                    unless
                                                                                                    otherwise
                                                                                                    required
                                                                                                    by
                                                                                                    law.
                                                                                                  </em>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  We
                                                                                                  will
                                                                                                  only
                                                                                                  keep
                                                                                                  your
                                                                                                  personal
                                                                                                  information
                                                                                                  for
                                                                                                  as
                                                                                                  long
                                                                                                  as
                                                                                                  it
                                                                                                  is
                                                                                                  necessary
                                                                                                  for
                                                                                                  the
                                                                                                  purposes
                                                                                                  set
                                                                                                  out
                                                                                                  in
                                                                                                  this
                                                                                                  privacy
                                                                                                  notice,
                                                                                                  unless
                                                                                                  a
                                                                                                  longer
                                                                                                  retention
                                                                                                  period
                                                                                                  is
                                                                                                  required
                                                                                                  or
                                                                                                  permitted
                                                                                                  by
                                                                                                  law
                                                                                                  (such
                                                                                                  as
                                                                                                  tax,
                                                                                                  accounting,
                                                                                                  or
                                                                                                  other
                                                                                                  legal
                                                                                                  requirements).
                                                                                                  <u />
                                                                                                  <u />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  When
                                                                                                  we
                                                                                                  have
                                                                                                  no
                                                                                                  ongoing
                                                                                                  legitimate
                                                                                                  business
                                                                                                  need
                                                                                                  to
                                                                                                  process
                                                                                                  your
                                                                                                  personal
                                                                                                  information,
                                                                                                  we
                                                                                                  will
                                                                                                  either
                                                                                                  delete
                                                                                                  or{" "}
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  anonymize
                                                                                                  <u />
                                                                                                  <u />{" "}
                                                                                                  such
                                                                                                  information,
                                                                                                  or,
                                                                                                  if
                                                                                                  this
                                                                                                  is
                                                                                                  not
                                                                                                  possible
                                                                                                  (for
                                                                                                  example,
                                                                                                  because
                                                                                                  your
                                                                                                  personal
                                                                                                  information
                                                                                                  has
                                                                                                  been
                                                                                                  stored
                                                                                                  in
                                                                                                  backup
                                                                                                  archives),
                                                                                                  then
                                                                                                  we
                                                                                                  will
                                                                                                  securely
                                                                                                  store
                                                                                                  your
                                                                                                  personal
                                                                                                  information
                                                                                                  and
                                                                                                  isolate
                                                                                                  it
                                                                                                  from
                                                                                                  any
                                                                                                  further
                                                                                                  processing
                                                                                                  until
                                                                                                  deletion
                                                                                                  is
                                                                                                  possible.
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <u />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            id="m_infosafe"
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(127,127,127)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      id="m_control"
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0,0,0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <strong>
                                                                                                        <span>
                                                                                                          9.
                                                                                                          HOW
                                                                                                          DO
                                                                                                          WE
                                                                                                          KEEP
                                                                                                          YOUR
                                                                                                          INFORMATION
                                                                                                          SAFE?
                                                                                                        </span>
                                                                                                      </strong>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <strong>
                                                                                                    <em>
                                                                                                      In
                                                                                                      Short:{" "}
                                                                                                    </em>
                                                                                                  </strong>
                                                                                                  <em>
                                                                                                    We
                                                                                                    aim
                                                                                                    to
                                                                                                    protect
                                                                                                    your
                                                                                                    personal
                                                                                                    information
                                                                                                    through
                                                                                                    a
                                                                                                    system
                                                                                                    of{" "}
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    organizational
                                                                                                    <u />
                                                                                                    <u />{" "}
                                                                                                    and
                                                                                                    technical
                                                                                                    security
                                                                                                    measures.
                                                                                                  </em>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  We
                                                                                                  have
                                                                                                  implemented
                                                                                                  appropriate
                                                                                                  and
                                                                                                  reasonable
                                                                                                  technical
                                                                                                  and{" "}
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  organizational
                                                                                                  <u />
                                                                                                  <u />{" "}
                                                                                                  security
                                                                                                  measures
                                                                                                  designed
                                                                                                  to
                                                                                                  protect
                                                                                                  the
                                                                                                  security
                                                                                                  of
                                                                                                  any
                                                                                                  personal
                                                                                                  information
                                                                                                  we
                                                                                                  process.
                                                                                                  However,
                                                                                                  despite
                                                                                                  our
                                                                                                  safeguards
                                                                                                  and
                                                                                                  efforts
                                                                                                  to
                                                                                                  secure
                                                                                                  your
                                                                                                  information,
                                                                                                  no
                                                                                                  electronic
                                                                                                  transmission
                                                                                                  over
                                                                                                  the
                                                                                                  Internet
                                                                                                  or
                                                                                                  information
                                                                                                  storage
                                                                                                  technology
                                                                                                  can
                                                                                                  be
                                                                                                  guaranteed
                                                                                                  to
                                                                                                  be
                                                                                                  100%
                                                                                                  secure,
                                                                                                  so
                                                                                                  we
                                                                                                  cannot
                                                                                                  promise
                                                                                                  or
                                                                                                  guarantee
                                                                                                  that
                                                                                                  hackers,
                                                                                                  cybercriminals,
                                                                                                  or
                                                                                                  other{" "}
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  unauthorized
                                                                                                  <u />
                                                                                                  <u />{" "}
                                                                                                  third
                                                                                                  parties
                                                                                                  will
                                                                                                  not
                                                                                                  be
                                                                                                  able
                                                                                                  to
                                                                                                  defeat
                                                                                                  our
                                                                                                  security
                                                                                                  and
                                                                                                  improperly
                                                                                                  collect,
                                                                                                  access,
                                                                                                  steal,
                                                                                                  or
                                                                                                  modify
                                                                                                  your
                                                                                                  information.
                                                                                                  Although
                                                                                                  we
                                                                                                  will
                                                                                                  do
                                                                                                  our
                                                                                                  best
                                                                                                  to
                                                                                                  protect
                                                                                                  your
                                                                                                  personal
                                                                                                  information,
                                                                                                  transmission
                                                                                                  of
                                                                                                  personal
                                                                                                  information
                                                                                                  to
                                                                                                  and
                                                                                                  from
                                                                                                  our
                                                                                                  Services
                                                                                                  is
                                                                                                  at
                                                                                                  your
                                                                                                  own
                                                                                                  risk.
                                                                                                  You
                                                                                                  should
                                                                                                  only
                                                                                                  access
                                                                                                  the
                                                                                                  Services
                                                                                                  within
                                                                                                  a
                                                                                                  secure
                                                                                                  environment.
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <u />
                                                                                                  </span>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            id="m_privacyrights"
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(127,127,127)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      id="m_control"
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0,0,0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <strong>
                                                                                                        <span>
                                                                                                          10.
                                                                                                          WHAT
                                                                                                          ARE
                                                                                                          YOUR
                                                                                                          PRIVACY
                                                                                                          RIGHTS?
                                                                                                        </span>
                                                                                                      </strong>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <strong>
                                                                                                    <em>
                                                                                                      In
                                                                                                      Short:
                                                                                                    </em>
                                                                                                  </strong>
                                                                                                  <em>
                                                                                                    {" "}
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <em>
                                                                                                            <u />
                                                                                                            <u />
                                                                                                          </em>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                    In
                                                                                                    some
                                                                                                    regions,
                                                                                                    such
                                                                                                    as{" "}
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    the
                                                                                                    European
                                                                                                    Economic
                                                                                                    Area
                                                                                                    (EEA),
                                                                                                    United
                                                                                                    Kingdom
                                                                                                    (UK),
                                                                                                    Switzerland,
                                                                                                    and
                                                                                                    Canada
                                                                                                    <u />
                                                                                                    <u />

                                                                                                    ,
                                                                                                    you
                                                                                                    have
                                                                                                    rights
                                                                                                    that
                                                                                                    allow
                                                                                                    you
                                                                                                    greater
                                                                                                    access
                                                                                                    to
                                                                                                    and
                                                                                                    control
                                                                                                    over
                                                                                                    your
                                                                                                    personal
                                                                                                    information.
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <em>
                                                                                                            <u />
                                                                                                            <u />
                                                                                                          </em>
                                                                                                        </span>
                                                                                                      </span>{" "}
                                                                                                    </span>
                                                                                                    You
                                                                                                    may
                                                                                                    review,
                                                                                                    change,
                                                                                                    or
                                                                                                    terminate
                                                                                                    your
                                                                                                    account
                                                                                                    at
                                                                                                    any
                                                                                                    time.
                                                                                                  </em>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  In
                                                                                                  some
                                                                                                  regions
                                                                                                  (like{" "}
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  the
                                                                                                  EEA,
                                                                                                  UK,
                                                                                                  Switzerland,
                                                                                                  and
                                                                                                  Canada
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  ),
                                                                                                  you
                                                                                                  have
                                                                                                  certain
                                                                                                  rights
                                                                                                  under
                                                                                                  applicable
                                                                                                  data
                                                                                                  protection
                                                                                                  laws.
                                                                                                  These
                                                                                                  may
                                                                                                  include
                                                                                                  the
                                                                                                  right
                                                                                                  (i)
                                                                                                  to
                                                                                                  request
                                                                                                  access
                                                                                                  and
                                                                                                  obtain
                                                                                                  a
                                                                                                  copy
                                                                                                  of
                                                                                                  your
                                                                                                  personal
                                                                                                  information,
                                                                                                  (ii)
                                                                                                  to
                                                                                                  request
                                                                                                  rectification
                                                                                                  or
                                                                                                  erasure;
                                                                                                  (iii)
                                                                                                  to
                                                                                                  restrict
                                                                                                  the
                                                                                                  processing
                                                                                                  of
                                                                                                  your
                                                                                                  personal
                                                                                                  information;
                                                                                                  (iv)
                                                                                                  if
                                                                                                  applicable,
                                                                                                  to
                                                                                                  data
                                                                                                  portability;
                                                                                                  and
                                                                                                  (v)
                                                                                                  not
                                                                                                  to
                                                                                                  be
                                                                                                  subject
                                                                                                  to
                                                                                                  automated
                                                                                                  decision-making.
                                                                                                  In
                                                                                                  certain
                                                                                                  circumstances,
                                                                                                  you
                                                                                                  may
                                                                                                  also
                                                                                                  have
                                                                                                  the
                                                                                                  right
                                                                                                  to
                                                                                                  object
                                                                                                  to
                                                                                                  the
                                                                                                  processing
                                                                                                  of
                                                                                                  your
                                                                                                  personal
                                                                                                  information.
                                                                                                  You
                                                                                                  can
                                                                                                  make
                                                                                                  such
                                                                                                  a
                                                                                                  request
                                                                                                  by
                                                                                                  contacting
                                                                                                  us
                                                                                                  by
                                                                                                  using
                                                                                                  the
                                                                                                  contact
                                                                                                  details
                                                                                                  provided
                                                                                                  in
                                                                                                  the
                                                                                                  section{" "}
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  "
                                                                                                  <u />
                                                                                                  <u />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <div rel="noreferrer">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(0,58,250)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(0,58,250)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    HOW
                                                                                                    CAN
                                                                                                    YOU
                                                                                                    CONTACT
                                                                                                    US
                                                                                                    ABOUT
                                                                                                    THIS
                                                                                                    NOTICE?
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  "
                                                                                                  <u />
                                                                                                  <u />{" "}
                                                                                                  below.
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  We
                                                                                                  will
                                                                                                  consider
                                                                                                  and
                                                                                                  act
                                                                                                  upon
                                                                                                  any
                                                                                                  request
                                                                                                  in
                                                                                                  accordance
                                                                                                  with
                                                                                                  applicable
                                                                                                  data
                                                                                                  protection
                                                                                                  laws.
                                                                                                  <u />
                                                                                                  <u />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              {" "}
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  If
                                                                                                  you
                                                                                                  are
                                                                                                  located
                                                                                                  in
                                                                                                  the
                                                                                                  EEA
                                                                                                  or
                                                                                                  UK
                                                                                                  and
                                                                                                  you
                                                                                                  believe
                                                                                                  we
                                                                                                  are
                                                                                                  unlawfully
                                                                                                  processing
                                                                                                  your
                                                                                                  personal
                                                                                                  information,
                                                                                                  you
                                                                                                  also
                                                                                                  have
                                                                                                  the
                                                                                                  right
                                                                                                  to
                                                                                                  complain
                                                                                                  to
                                                                                                  your{" "}
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0,58,250)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(0,58,250)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span>
                                                                                                            <a
                                                                                                              href="https://www.google.com/url?q=https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&source=gmail-html&ust=1710201824112000&usg=AOvVaw3qMH5t6YLkCYxgEWqTAlQB"
                                                                                                              rel="noopener noreferrer noreferrer"
                                                                                                              target="_blank"
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                }}
                                                                                                              >
                                                                                                                Member
                                                                                                                State
                                                                                                                data
                                                                                                                protection
                                                                                                                authority
                                                                                                              </span>
                                                                                                            </a>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>{" "}
                                                                                                  or{" "}
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <a
                                                                                              href="https://www.google.com/url?q=https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/&source=gmail-html&ust=1710201824112000&usg=AOvVaw30D8MevXcpIau0mzJl2LYi"
                                                                                              rel="noopener noreferrer noreferrer"
                                                                                              target="_blank"
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(0,58,250)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(0,58,250)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    UK
                                                                                                    data
                                                                                                    protection
                                                                                                    authority
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </a>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  .
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  If
                                                                                                  you
                                                                                                  are
                                                                                                  located
                                                                                                  in
                                                                                                  Switzerland,
                                                                                                  you
                                                                                                  may
                                                                                                  contact
                                                                                                  the{" "}
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0,58,250)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(0,58,250)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(0,58,250)",
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <a
                                                                                                                href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home.html&source=gmail-html&ust=1710201824112000&usg=AOvVaw2c0GXIlZ6OVs29sNSfqpU2"
                                                                                                                rel="noopener noreferrer noreferrer"
                                                                                                                target="_blank"
                                                                                                              >
                                                                                                                Federal
                                                                                                                Data
                                                                                                                Protection
                                                                                                                and
                                                                                                                Information
                                                                                                                Commissioner
                                                                                                              </a>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>

                                                                                                  .
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            id="m_withdrawconsent"
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <strong>
                                                                                                    <u>
                                                                                                      Withdrawing
                                                                                                      your
                                                                                                      consent:
                                                                                                    </u>
                                                                                                  </strong>{" "}
                                                                                                  If
                                                                                                  we
                                                                                                  are
                                                                                                  relying
                                                                                                  on
                                                                                                  your
                                                                                                  consent
                                                                                                  to
                                                                                                  process
                                                                                                  your
                                                                                                  personal
                                                                                                  information,
                                                                                                  <u />
                                                                                                  <u />{" "}
                                                                                                  which
                                                                                                  may
                                                                                                  be
                                                                                                  express
                                                                                                  and/or
                                                                                                  implied
                                                                                                  consent
                                                                                                  depending
                                                                                                  on
                                                                                                  the
                                                                                                  applicable
                                                                                                  law,
                                                                                                  <u />
                                                                                                  <u />{" "}
                                                                                                  you
                                                                                                  have
                                                                                                  the
                                                                                                  right
                                                                                                  to
                                                                                                  withdraw
                                                                                                  your
                                                                                                  consent
                                                                                                  at
                                                                                                  any
                                                                                                  time.
                                                                                                  You
                                                                                                  can
                                                                                                  withdraw
                                                                                                  your
                                                                                                  consent
                                                                                                  at
                                                                                                  any
                                                                                                  time
                                                                                                  by
                                                                                                  contacting
                                                                                                  us
                                                                                                  by
                                                                                                  using
                                                                                                  the
                                                                                                  contact
                                                                                                  details
                                                                                                  provided
                                                                                                  in
                                                                                                  the
                                                                                                  section{" "}
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  "
                                                                                                  <u />
                                                                                                  <u />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <div rel="noreferrer">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(0,58,250)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(0,58,250)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    HOW
                                                                                                    CAN
                                                                                                    YOU
                                                                                                    CONTACT
                                                                                                    US
                                                                                                    ABOUT
                                                                                                    THIS
                                                                                                    NOTICE?
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  "
                                                                                                  <u />
                                                                                                  <u />{" "}
                                                                                                  below
                                                                                                  <u />
                                                                                                  <u />

                                                                                                  .
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span>
                                                                                                However,
                                                                                                please
                                                                                                note
                                                                                                that
                                                                                                this
                                                                                                will
                                                                                                not
                                                                                                affect
                                                                                                the
                                                                                                lawfulness
                                                                                                of
                                                                                                the
                                                                                                processing
                                                                                                before
                                                                                                its
                                                                                                withdrawal
                                                                                                nor,
                                                                                                <u />
                                                                                                <u />{" "}
                                                                                                when
                                                                                                applicable
                                                                                                law
                                                                                                allows,
                                                                                                <u />
                                                                                                <u />{" "}
                                                                                                will
                                                                                                it
                                                                                                affect
                                                                                                the
                                                                                                processing
                                                                                                of
                                                                                                your
                                                                                                personal
                                                                                                information
                                                                                                conducted
                                                                                                in
                                                                                                reliance
                                                                                                on
                                                                                                lawful
                                                                                                processing
                                                                                                grounds
                                                                                                other
                                                                                                than
                                                                                                consent.
                                                                                                <u />
                                                                                                <u />
                                                                                              </span>
                                                                                            </span>
                                                                                            <u />
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span />
                                                                                            </span>
                                                                                            <u />
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89,89,89)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89,89,89)",
                                                                                                                }}
                                                                                                              >
                                                                                                                <u />
                                                                                                                <u />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <u />
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span />
                                                                                            </span>
                                                                                            <u />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            id="m_DNT"
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(127,127,127)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      id="m_control"
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0,0,0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <strong>
                                                                                                        <span>
                                                                                                          11.
                                                                                                          CONTROLS
                                                                                                          FOR
                                                                                                          DO-NOT-TRACK
                                                                                                          FEATURES
                                                                                                        </span>
                                                                                                      </strong>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  Most
                                                                                                  web
                                                                                                  browsers
                                                                                                  and
                                                                                                  some
                                                                                                  mobile
                                                                                                  operating
                                                                                                  systems
                                                                                                  and
                                                                                                  mobile
                                                                                                  applications
                                                                                                  include
                                                                                                  a
                                                                                                  Do-Not-Track
                                                                                                  (
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  "DNT"
                                                                                                  <u />
                                                                                                  <u />

                                                                                                  )
                                                                                                  feature
                                                                                                  or
                                                                                                  setting
                                                                                                  you
                                                                                                  can
                                                                                                  activate
                                                                                                  to
                                                                                                  signal
                                                                                                  your
                                                                                                  privacy
                                                                                                  preference
                                                                                                  not
                                                                                                  to
                                                                                                  have
                                                                                                  data
                                                                                                  about
                                                                                                  your
                                                                                                  online
                                                                                                  browsing
                                                                                                  activities
                                                                                                  monitored
                                                                                                  and
                                                                                                  collected.
                                                                                                  At
                                                                                                  this
                                                                                                  stage
                                                                                                  no
                                                                                                  uniform
                                                                                                  technology
                                                                                                  standard
                                                                                                  for{" "}
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  recognizing
                                                                                                  <u />
                                                                                                  <u />{" "}
                                                                                                  and
                                                                                                  implementing
                                                                                                  DNT
                                                                                                  signals
                                                                                                  has
                                                                                                  been{" "}
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  finalized
                                                                                                  <u />
                                                                                                  <u />
                                                                                                  .
                                                                                                  As
                                                                                                  such,
                                                                                                  we
                                                                                                  do
                                                                                                  not
                                                                                                  currently
                                                                                                  respond
                                                                                                  to
                                                                                                  DNT
                                                                                                  browser
                                                                                                  signals
                                                                                                  or
                                                                                                  any
                                                                                                  other
                                                                                                  mechanism
                                                                                                  that
                                                                                                  automatically
                                                                                                  communicates
                                                                                                  your
                                                                                                  choice
                                                                                                  not
                                                                                                  to
                                                                                                  be
                                                                                                  tracked
                                                                                                  online.
                                                                                                  If
                                                                                                  a
                                                                                                  standard
                                                                                                  for
                                                                                                  online
                                                                                                  tracking
                                                                                                  is
                                                                                                  adopted
                                                                                                  that
                                                                                                  we
                                                                                                  must
                                                                                                  follow
                                                                                                  in
                                                                                                  the
                                                                                                  future,
                                                                                                  we
                                                                                                  will
                                                                                                  inform
                                                                                                  you
                                                                                                  about
                                                                                                  that
                                                                                                  practice
                                                                                                  in
                                                                                                  a
                                                                                                  revised
                                                                                                  version
                                                                                                  of
                                                                                                  this
                                                                                                  privacy
                                                                                                  notice.
                                                                                                  <u />
                                                                                                  <u />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            id="m_uslaws"
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(127,127,127)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      id="m_control"
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0,0,0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <strong>
                                                                                                        <span>
                                                                                                          12.
                                                                                                          DO
                                                                                                          UNITED
                                                                                                          STATES
                                                                                                          RESIDENTS
                                                                                                          HAVE
                                                                                                          SPECIFIC
                                                                                                          PRIVACY
                                                                                                          RIGHTS?
                                                                                                        </span>
                                                                                                      </strong>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <strong>
                                                                                                    <em>
                                                                                                      In
                                                                                                      Short:{" "}
                                                                                                    </em>
                                                                                                  </strong>
                                                                                                  <em>
                                                                                                    If
                                                                                                    you
                                                                                                    are
                                                                                                    a
                                                                                                    resident
                                                                                                    of{" "}
                                                                                                    <u />
                                                                                                    <u />

                                                                                                    ,
                                                                                                    you
                                                                                                    are
                                                                                                    granted
                                                                                                    specific
                                                                                                    rights
                                                                                                    regarding
                                                                                                    access
                                                                                                    to
                                                                                                    your
                                                                                                    personal
                                                                                                    information.
                                                                                                  </em>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  <strong>
                                                                                                    What
                                                                                                    categories
                                                                                                    of
                                                                                                    personal
                                                                                                    information
                                                                                                    do
                                                                                                    we
                                                                                                    collect?
                                                                                                  </strong>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89,89,89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  We
                                                                                                  have
                                                                                                  collected
                                                                                                  the
                                                                                                  following
                                                                                                  categories
                                                                                                  of
                                                                                                  personal
                                                                                                  information
                                                                                                  in
                                                                                                  the
                                                                                                  past
                                                                                                  twelve
                                                                                                  (12)
                                                                                                  months:
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <table
                                                                                            style={{
                                                                                              width:
                                                                                                "100%",
                                                                                            }}
                                                                                          >
                                                                                            <tbody>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "33.8274%",
                                                                                                    borderLeft:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <strong>
                                                                                                          Category
                                                                                                        </strong>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "51.4385%",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <strong>
                                                                                                          Examples
                                                                                                        </strong>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "14.9084%",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <strong>
                                                                                                          Collected
                                                                                                        </strong>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </td>
                                                                                              </tr>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "33.8274%",
                                                                                                    borderLeft:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          A.
                                                                                                          Identifiers
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "51.4385%",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          Contact
                                                                                                          details,
                                                                                                          such
                                                                                                          as
                                                                                                          real
                                                                                                          name,
                                                                                                          alias,
                                                                                                          postal
                                                                                                          address,
                                                                                                          telephone
                                                                                                          or
                                                                                                          mobile
                                                                                                          contact
                                                                                                          number,
                                                                                                          unique
                                                                                                          personal
                                                                                                          identifier,
                                                                                                          online
                                                                                                          identifier,
                                                                                                          Internet
                                                                                                          Protocol
                                                                                                          address,
                                                                                                          email
                                                                                                          address,
                                                                                                          and
                                                                                                          account
                                                                                                          name
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "14.9084%",
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                    verticalAlign:
                                                                                                      "middle",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          NO
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          <u />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                </td>
                                                                                              </tr>
                                                                                            </tbody>
                                                                                          </table>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <u />
                                                                                            <u />
                                                                                          </div>
                                                                                          <table
                                                                                            style={{
                                                                                              width:
                                                                                                "100%",
                                                                                            }}
                                                                                          >
                                                                                            <tbody>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "33.8274%",
                                                                                                    borderLeft:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          B
                                                                                                          <u />
                                                                                                          <u />

                                                                                                          .
                                                                                                          Protected
                                                                                                          classification
                                                                                                          characteristics
                                                                                                          under
                                                                                                          state
                                                                                                          or
                                                                                                          federal
                                                                                                          law
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "51.4385%",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          Gender
                                                                                                          and
                                                                                                          date
                                                                                                          of
                                                                                                          birth
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "14.9084%",
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      NO
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                    <u />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                </td>
                                                                                              </tr>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "33.8274%",
                                                                                                    borderLeft:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          C
                                                                                                          <u />
                                                                                                          <u />

                                                                                                          .
                                                                                                          Commercial
                                                                                                          information
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "51.4385%",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          Transaction
                                                                                                          information,
                                                                                                          purchase
                                                                                                          history,
                                                                                                          financial
                                                                                                          details,
                                                                                                          and
                                                                                                          payment
                                                                                                          information
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "14.9084%",
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      NO
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                    <u />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                </td>
                                                                                              </tr>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "33.8274%",
                                                                                                    borderLeft:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          D
                                                                                                          <u />
                                                                                                          <u />

                                                                                                          .
                                                                                                          Biometric
                                                                                                          information
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "51.4385%",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          Fingerprints
                                                                                                          and
                                                                                                          voiceprints
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "14.9084%",
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      NO
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                    <u />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                </td>
                                                                                              </tr>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "33.8274%",
                                                                                                    borderLeft:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          E
                                                                                                          <u />
                                                                                                          <u />

                                                                                                          .
                                                                                                          Internet
                                                                                                          or
                                                                                                          other
                                                                                                          similar
                                                                                                          network
                                                                                                          activity
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "51.4385%",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          Browsing
                                                                                                          history,
                                                                                                          search
                                                                                                          history,
                                                                                                          online{" "}
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          behavior
                                                                                                          <u />
                                                                                                          <u />

                                                                                                          ,
                                                                                                          interest
                                                                                                          data,
                                                                                                          and
                                                                                                          interactions
                                                                                                          with
                                                                                                          our
                                                                                                          and
                                                                                                          other
                                                                                                          websites,
                                                                                                          applications,
                                                                                                          systems,
                                                                                                          and
                                                                                                          advertisements
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "14.9084%",
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      NO
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                    <u />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                </td>
                                                                                              </tr>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "33.8274%",
                                                                                                    borderLeft:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          F
                                                                                                          <u />
                                                                                                          <u />

                                                                                                          .
                                                                                                          Geolocation
                                                                                                          data
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "51.4385%",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          Device
                                                                                                          location
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "14.9084%",
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      NO
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                    <u />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                </td>
                                                                                              </tr>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "33.8274%",
                                                                                                    borderLeft:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          G
                                                                                                          <u />
                                                                                                          <u />

                                                                                                          .
                                                                                                          Audio,
                                                                                                          electronic,
                                                                                                          visual,
                                                                                                          thermal,
                                                                                                          olfactory,
                                                                                                          or
                                                                                                          similar
                                                                                                          information
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "51.4385%",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          Images
                                                                                                          and
                                                                                                          audio,
                                                                                                          video
                                                                                                          or
                                                                                                          call
                                                                                                          recordings
                                                                                                          created
                                                                                                          in
                                                                                                          connection
                                                                                                          with
                                                                                                          our
                                                                                                          business
                                                                                                          activities
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "14.9084%",
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      NO
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                    <u />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                </td>
                                                                                              </tr>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "33.8274%",
                                                                                                    borderLeft:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          H
                                                                                                          <u />
                                                                                                          <u />

                                                                                                          .
                                                                                                          Professional
                                                                                                          or
                                                                                                          employment-related
                                                                                                          information
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "51.4385%",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          Business
                                                                                                          contact
                                                                                                          details
                                                                                                          in
                                                                                                          order
                                                                                                          to
                                                                                                          provide
                                                                                                          you
                                                                                                          our
                                                                                                          Services
                                                                                                          at
                                                                                                          a
                                                                                                          business
                                                                                                          level
                                                                                                          or
                                                                                                          job
                                                                                                          title,
                                                                                                          work
                                                                                                          history,
                                                                                                          and
                                                                                                          professional
                                                                                                          qualifications
                                                                                                          if
                                                                                                          you
                                                                                                          apply
                                                                                                          for
                                                                                                          a
                                                                                                          job
                                                                                                          with
                                                                                                          us
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    width:
                                                                                                      "14.9084%",
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      NO
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                    <u />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                </td>
                                                                                              </tr>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    borderLeft:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                    width:
                                                                                                      "33.8274%",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          I
                                                                                                          <u />
                                                                                                          <u />

                                                                                                          .
                                                                                                          Education
                                                                                                          Information
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                    width:
                                                                                                      "51.4385%",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          Student
                                                                                                          records
                                                                                                          and
                                                                                                          directory
                                                                                                          information
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                    width:
                                                                                                      "14.9084%",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      NO
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                    <u />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                </td>
                                                                                              </tr>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    borderWidth: 1,
                                                                                                    borderColor:
                                                                                                      "black",
                                                                                                    borderStyle:
                                                                                                      "solid",
                                                                                                    width:
                                                                                                      "33.8274%",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          J
                                                                                                          <u />
                                                                                                          <u />

                                                                                                          .
                                                                                                          Inferences
                                                                                                          drawn
                                                                                                          from
                                                                                                          collected
                                                                                                          personal
                                                                                                          information
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    borderBottom:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    width:
                                                                                                      "51.4385%",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          Inferences
                                                                                                          drawn
                                                                                                          from
                                                                                                          any
                                                                                                          of
                                                                                                          the
                                                                                                          collected
                                                                                                          personal
                                                                                                          information
                                                                                                          listed
                                                                                                          above
                                                                                                          to
                                                                                                          create
                                                                                                          a
                                                                                                          profile
                                                                                                          or
                                                                                                          summary
                                                                                                          about,
                                                                                                          for
                                                                                                          example,
                                                                                                          an
                                                                                                          individual’s
                                                                                                          preferences
                                                                                                          and
                                                                                                          characteristics
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    textAlign:
                                                                                                      "center",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderBottom:
                                                                                                      "1px solid black",
                                                                                                    borderTop:
                                                                                                      "1px solid black",
                                                                                                    width:
                                                                                                      "14.9084%",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />
                                                                                                      NO
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89,89,89)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89,89,89)",
                                                                                                              }}
                                                                                                            >
                                                                                                              <span>
                                                                                                                <u />
                                                                                                                <u />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                </td>
                                                                                              </tr>
                                                                                              <tr>
                                                                                                <td
                                                                                                  style={{
                                                                                                    borderLeft:
                                                                                                      "1px solid black",
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderBottom:
                                                                                                      "1px solid black",
                                                                                                    lineHeight:
                                                                                                      "1.5",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    K
                                                                                                    <u />
                                                                                                    <u />

                                                                                                    .
                                                                                                    Sensitive
                                                                                                    personal
                                                                                                    Information
                                                                                                  </span>
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderBottom:
                                                                                                      "1px solid black",
                                                                                                    lineHeight:
                                                                                                      "1.5",
                                                                                                  }}
                                                                                                >
                                                                                                  <u />
                                                                                                  <span />
                                                                                                  <u />
                                                                                                </td>
                                                                                                <td
                                                                                                  style={{
                                                                                                    borderRight:
                                                                                                      "1px solid black",
                                                                                                    borderBottom:
                                                                                                      "1px solid black",
                                                                                                  }}
                                                                                                >
                                                                                                  <div
                                                                                                    style={{
                                                                                                      textAlign:
                                                                                                        "center",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      textAlign:
                                                                                                        "center",
                                                                                                      lineHeight:
                                                                                                        "1.5",
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <span />
                                                                                                    <u />
                                                                                                    NO
                                                                                                    <u />
                                                                                                    <span />
                                                                                                    <u />
                                                                                                  </div>
                                                                                                  <div
                                                                                                    style={{
                                                                                                      textAlign:
                                                                                                        "center",
                                                                                                    }}
                                                                                                  >
                                                                                                    <br />
                                                                                                  </div>
                                                                                                </td>
                                                                                              </tr>
                                                                                            </tbody>
                                                                                          </table>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5",
                                                                                            }}
                                                                                          >
                                                                                            <u />
                                                                                            <span />
                                                                                            <u />
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <u />
                                                                                                    <u />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    We
                                                                                                    may
                                                                                                    also
                                                                                                    collect
                                                                                                    other
                                                                                                    personal
                                                                                                    information
                                                                                                    outside
                                                                                                    of
                                                                                                    these
                                                                                                    categories
                                                                                                    through
                                                                                                    instances
                                                                                                    where
                                                                                                    you
                                                                                                    interact
                                                                                                    with
                                                                                                    us
                                                                                                    in
                                                                                                    person,
                                                                                                    online,
                                                                                                    or
                                                                                                    by
                                                                                                    phone
                                                                                                    or
                                                                                                    mail
                                                                                                    in
                                                                                                    the
                                                                                                    context
                                                                                                    of:
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89,89,89)",
                                                                                                              fontSize: 15,
                                                                                                            }}
                                                                                                          >
                                                                                                            <span>
                                                                                                              <u />
                                                                                                              <u />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <ul>
                                                                                              <li
                                                                                                style={{
                                                                                                  lineHeight:
                                                                                                    "1.5",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span>
                                                                                                      Receiving
                                                                                                      help
                                                                                                      through
                                                                                                      our
                                                                                                      customer
                                                                                                      support
                                                                                                      channels;
                                                                                                    </span>
                                                                                                    <span>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89,89,89)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89,89,89)",
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89,89,89)",
                                                                                                                    fontSize: 15,
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span>
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89,89,89)",
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span>
                                                                                                                          <u />
                                                                                                                          <u />
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </li>
                                                                                            </ul>
                                                                                            <div>
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89,89,89)",
                                                                                                              fontSize: 15,
                                                                                                            }}
                                                                                                          >
                                                                                                            <span>
                                                                                                              <u />
                                                                                                              <u />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <ul>
                                                                                              <li
                                                                                                style={{
                                                                                                  lineHeight:
                                                                                                    "1.5",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span>
                                                                                                      Participation
                                                                                                      in
                                                                                                      customer
                                                                                                      surveys
                                                                                                      or
                                                                                                      contests;
                                                                                                      and
                                                                                                    </span>
                                                                                                    <span>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89,89,89)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89,89,89)",
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89,89,89)",
                                                                                                                    fontSize: 15,
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span>
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89,89,89)",
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span>
                                                                                                                          <u />
                                                                                                                          <u />
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </li>
                                                                                            </ul>
                                                                                            <div>
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89,89,89)",
                                                                                                              fontSize: 15,
                                                                                                            }}
                                                                                                          >
                                                                                                            <span>
                                                                                                              <u />
                                                                                                              <u />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <ul>
                                                                                              <li
                                                                                                style={{
                                                                                                  lineHeight:
                                                                                                    "1.5",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span>
                                                                                                      Facilitation
                                                                                                      in
                                                                                                      the
                                                                                                      delivery
                                                                                                      of
                                                                                                      our
                                                                                                      Services
                                                                                                      and
                                                                                                      to
                                                                                                      respond
                                                                                                      to
                                                                                                      your
                                                                                                      inquiries.
                                                                                                    </span>
                                                                                                    <span>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89,89,89)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89,89,89)",
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89,89,89)",
                                                                                                                    fontSize: 15,
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span>
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89,89,89)",
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span>
                                                                                                                          <u />
                                                                                                                          <u />
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </li>
                                                                                            </ul>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <strong>
                                                                                                      How
                                                                                                      do
                                                                                                      we
                                                                                                      use
                                                                                                      and
                                                                                                      share
                                                                                                      your
                                                                                                      personal
                                                                                                      information?
                                                                                                    </strong>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                Learn
                                                                                                about
                                                                                                how
                                                                                                we
                                                                                                use
                                                                                                your
                                                                                                personal
                                                                                                information
                                                                                                in
                                                                                                the
                                                                                                section,{" "}
                                                                                                <u />
                                                                                                <u />
                                                                                                "
                                                                                                <u />
                                                                                                <u />
                                                                                              </span>
                                                                                              <div rel="noreferrer">
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0,58,250)",
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  HOW
                                                                                                  DO
                                                                                                  WE
                                                                                                  PROCESS
                                                                                                  YOUR
                                                                                                  INFORMATION?
                                                                                                </span>
                                                                                              </div>
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <u />
                                                                                                <u />

                                                                                                "
                                                                                              </span>
                                                                                              <u />
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              />
                                                                                              <u />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <u />
                                                                                                        <u />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <strong>
                                                                                                      Will
                                                                                                      your
                                                                                                      information
                                                                                                      be
                                                                                                      shared
                                                                                                      with
                                                                                                      anyone
                                                                                                      else?
                                                                                                    </strong>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    We
                                                                                                    may
                                                                                                    disclose
                                                                                                    your
                                                                                                    personal
                                                                                                    information
                                                                                                    with
                                                                                                    our
                                                                                                    service
                                                                                                    providers
                                                                                                    pursuant
                                                                                                    to
                                                                                                    a
                                                                                                    written
                                                                                                    contract
                                                                                                    between
                                                                                                    us
                                                                                                    and
                                                                                                    each
                                                                                                    service
                                                                                                    provider.
                                                                                                    Learn
                                                                                                    more
                                                                                                    about
                                                                                                    how
                                                                                                    we
                                                                                                    disclose
                                                                                                    personal
                                                                                                    information
                                                                                                    to
                                                                                                    in
                                                                                                    the
                                                                                                    section,{" "}
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    "
                                                                                                    <u />
                                                                                                    <u />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                              <div rel="noreferrer">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(0,58,250)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(0,58,250)",
                                                                                                    }}
                                                                                                  >
                                                                                                    WHEN
                                                                                                    AND
                                                                                                    WITH
                                                                                                    WHOM
                                                                                                    DO
                                                                                                    WE
                                                                                                    SHARE
                                                                                                    YOUR
                                                                                                    PERSONAL
                                                                                                    INFORMATION?
                                                                                                  </span>
                                                                                                </span>
                                                                                              </div>
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    "
                                                                                                    <u />
                                                                                                    <u />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    We
                                                                                                    may
                                                                                                    use
                                                                                                    your
                                                                                                    personal
                                                                                                    information
                                                                                                    for
                                                                                                    our
                                                                                                    own
                                                                                                    business
                                                                                                    purposes,
                                                                                                    such
                                                                                                    as
                                                                                                    for
                                                                                                    undertaking
                                                                                                    internal
                                                                                                    research
                                                                                                    for
                                                                                                    technological
                                                                                                    development
                                                                                                    and
                                                                                                    demonstration.
                                                                                                    This
                                                                                                    is
                                                                                                    not
                                                                                                    considered
                                                                                                    to
                                                                                                    be{" "}
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    "selling"
                                                                                                    <u />
                                                                                                    <u />{" "}
                                                                                                    of
                                                                                                    your
                                                                                                    personal
                                                                                                    information.
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <u />
                                                                                                        <u />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    We
                                                                                                    have
                                                                                                    not
                                                                                                    disclosed,
                                                                                                    sold,
                                                                                                    or
                                                                                                    shared
                                                                                                    any
                                                                                                    personal
                                                                                                    information
                                                                                                    to
                                                                                                    third
                                                                                                    parties
                                                                                                    for
                                                                                                    a
                                                                                                    business
                                                                                                    or
                                                                                                    commercial
                                                                                                    purpose
                                                                                                    in
                                                                                                    the
                                                                                                    preceding
                                                                                                    twelve
                                                                                                    (12)
                                                                                                    months.
                                                                                                    We
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      {" "}
                                                                                                    </span>
                                                                                                    will
                                                                                                    not
                                                                                                    sell
                                                                                                    or
                                                                                                    share
                                                                                                    personal
                                                                                                    information
                                                                                                    in
                                                                                                    the
                                                                                                    future
                                                                                                    belonging
                                                                                                    to
                                                                                                    website
                                                                                                    visitors,
                                                                                                    users,
                                                                                                    and
                                                                                                    other
                                                                                                    consumers.
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89,89,89)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89,89,89)",
                                                                                                              }}
                                                                                                            >
                                                                                                              <span>
                                                                                                                <u />
                                                                                                                <u />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                        <u />
                                                                                                        <u />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                              <span>
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(0,0,0)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <u />
                                                                                                    <u />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                              <u />
                                                                                              <u />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <u />
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              />
                                                                                              <u />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <u />
                                                                                              <u />
                                                                                              <u />
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              />
                                                                                              <u />
                                                                                              <u />
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              />
                                                                                              <u />
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89,89,89)",
                                                                                                              fontSize: 15,
                                                                                                            }}
                                                                                                          >
                                                                                                            <span>
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89,89,89)",
                                                                                                                  fontSize: 15,
                                                                                                                }}
                                                                                                              >
                                                                                                                <span>
                                                                                                                  <u />
                                                                                                                  <span>
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89,89,89)",
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89,89,89)",
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            color:
                                                                                                                              "rgb(89,89,89)",
                                                                                                                            fontSize: 15,
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span>
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(89,89,89)",
                                                                                                                                fontSize: 15,
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span>
                                                                                                                                <span
                                                                                                                                  style={{
                                                                                                                                    color:
                                                                                                                                      "rgb(89,89,89)",
                                                                                                                                    fontSize: 15,
                                                                                                                                  }}
                                                                                                                                >
                                                                                                                                  <span>
                                                                                                                                    <span
                                                                                                                                      style={{
                                                                                                                                        color:
                                                                                                                                          "rgb(89,89,89)",
                                                                                                                                        fontSize: 15,
                                                                                                                                      }}
                                                                                                                                    >
                                                                                                                                      <span>
                                                                                                                                        <u />
                                                                                                                                        <u />
                                                                                                                                        <u />
                                                                                                                                        <u />
                                                                                                                                      </span>
                                                                                                                                    </span>
                                                                                                                                  </span>
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                  <u />
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                              <u />
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              />
                                                                                              <u />
                                                                                              <u />
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              />
                                                                                              <u />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              id="m_policyupdates"
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(127,127,127)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        id="m_control"
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0,0,0)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <strong>
                                                                                                          <span>
                                                                                                            13.
                                                                                                            DO
                                                                                                            WE
                                                                                                            MAKE
                                                                                                            UPDATES
                                                                                                            TO
                                                                                                            THIS
                                                                                                            NOTICE?
                                                                                                          </span>
                                                                                                        </strong>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <em>
                                                                                                      <strong>
                                                                                                        In
                                                                                                        Short:{" "}
                                                                                                      </strong>
                                                                                                      Yes,
                                                                                                      we
                                                                                                      will
                                                                                                      update
                                                                                                      this
                                                                                                      notice
                                                                                                      as
                                                                                                      necessary
                                                                                                      to
                                                                                                      stay
                                                                                                      compliant
                                                                                                      with
                                                                                                      relevant
                                                                                                      laws.
                                                                                                    </em>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    We
                                                                                                    may
                                                                                                    update
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice
                                                                                                    from
                                                                                                    time
                                                                                                    to
                                                                                                    time.
                                                                                                    The
                                                                                                    updated
                                                                                                    version
                                                                                                    will
                                                                                                    be
                                                                                                    indicated
                                                                                                    by
                                                                                                    an
                                                                                                    updated{" "}
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    "Revised"
                                                                                                    <u />
                                                                                                    <u />{" "}
                                                                                                    date
                                                                                                    and
                                                                                                    the
                                                                                                    updated
                                                                                                    version
                                                                                                    will
                                                                                                    be
                                                                                                    effective
                                                                                                    as
                                                                                                    soon
                                                                                                    as
                                                                                                    it
                                                                                                    is
                                                                                                    accessible.
                                                                                                    If
                                                                                                    we
                                                                                                    make
                                                                                                    material
                                                                                                    changes
                                                                                                    to
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice,
                                                                                                    we
                                                                                                    may
                                                                                                    notify
                                                                                                    you
                                                                                                    either
                                                                                                    by
                                                                                                    prominently
                                                                                                    posting
                                                                                                    a
                                                                                                    notice
                                                                                                    of
                                                                                                    such
                                                                                                    changes
                                                                                                    or
                                                                                                    by
                                                                                                    directly
                                                                                                    sending
                                                                                                    you
                                                                                                    a
                                                                                                    notification.
                                                                                                    We
                                                                                                    encourage
                                                                                                    you
                                                                                                    to
                                                                                                    review
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice
                                                                                                    frequently
                                                                                                    to
                                                                                                    be
                                                                                                    informed
                                                                                                    of
                                                                                                    how
                                                                                                    we
                                                                                                    are
                                                                                                    protecting
                                                                                                    your
                                                                                                    information.
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              id="m_contact"
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(127,127,127)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        id="m_control"
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0,0,0)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <strong>
                                                                                                          <span>
                                                                                                            14.
                                                                                                            HOW
                                                                                                            CAN
                                                                                                            YOU
                                                                                                            CONTACT
                                                                                                            US
                                                                                                            ABOUT
                                                                                                            THIS
                                                                                                            NOTICE?
                                                                                                          </span>
                                                                                                        </strong>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    If
                                                                                                    you
                                                                                                    have
                                                                                                    questions
                                                                                                    or
                                                                                                    comments
                                                                                                    about
                                                                                                    this
                                                                                                    notice,
                                                                                                    you
                                                                                                    may{" "}
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <u />
                                                                                                        <u />
                                                                                                        <u />
                                                                                                        <u />
                                                                                                        <u />
                                                                                                        <u />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          contact
                                                                                                          us
                                                                                                          by
                                                                                                          post
                                                                                                          at:
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89,89,89)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span>
                                                                                                            1001
                                                                                                            Harvard
                                                                                                            Street
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                  <span>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span>
                                                                                                        <u />
                                                                                                        <u />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span>
                                                                                                  Santa
                                                                                                  Monica,
                                                                                                  CA
                                                                                                  90403
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              />
                                                                                              <span>
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <u />
                                                                                                    <u />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                              <span>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <u />
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89,89,89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span>
                                                                                                            <u />
                                                                                                            <u />
                                                                                                            <u />
                                                                                                            <u />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                      <u />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                                <u />
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                />
                                                                                                <u />
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span>
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          <u />
                                                                                                          <u />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              id="m_request"
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(127,127,127)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89,89,89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89,89,89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        id="m_control"
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0,0,0)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <strong>
                                                                                                          <span>
                                                                                                            15.
                                                                                                            HOW
                                                                                                            CAN
                                                                                                            YOU
                                                                                                            REVIEW,
                                                                                                            UPDATE,
                                                                                                            OR
                                                                                                            DELETE
                                                                                                            THE
                                                                                                            DATA
                                                                                                            WE
                                                                                                            COLLECT
                                                                                                            FROM
                                                                                                            YOU?
                                                                                                          </span>
                                                                                                        </strong>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <br />
                                                                                            </div>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89,89,89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89,89,89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span>
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    Based
                                                                                                    on
                                                                                                    the
                                                                                                    applicable
                                                                                                    laws
                                                                                                    of
                                                                                                    your
                                                                                                    country,
                                                                                                    you
                                                                                                    may
                                                                                                    have
                                                                                                    the
                                                                                                    right
                                                                                                    to
                                                                                                    request
                                                                                                    access
                                                                                                    to
                                                                                                    the
                                                                                                    personal
                                                                                                    information
                                                                                                    we
                                                                                                    collect
                                                                                                    from
                                                                                                    you,
                                                                                                    change
                                                                                                    that
                                                                                                    information,
                                                                                                    or
                                                                                                    delete
                                                                                                    it.{" "}
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    To
                                                                                                    request
                                                                                                    to
                                                                                                    review,
                                                                                                    update,
                                                                                                    or
                                                                                                    delete
                                                                                                    your
                                                                                                    personal
                                                                                                    information,
                                                                                                    please{" "}
                                                                                                    <u />
                                                                                                    <u />
                                                                                                    fill
                                                                                                    out
                                                                                                    and
                                                                                                    submit
                                                                                                    a{" "}
                                                                                                    <u />
                                                                                                  </span>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(0,58,250)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span>
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(0,58,250)",
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <a
                                                                                                          href="https://www.google.com/url?q=https://app.termly.io/notify/f5fd18fe-85d8-4526-bd0f-3c11af680b56&source=gmail-html&ust=1710201824112000&usg=AOvVaw0Wzx9j9tUspX8xb53dySGZ"
                                                                                                          rel="noopener noreferrer noreferrer"
                                                                                                          target="_blank"
                                                                                                        >
                                                                                                          data
                                                                                                          subject
                                                                                                          access
                                                                                                          request
                                                                                                        </a>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span>
                                                                                                      <u />
                                                                                                      <u />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                  <span>
                                                                                                    .
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </div>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              color:
                                                                                                "#595959",
                                                                                              fontSize: 14,
                                                                                              fontFamily:
                                                                                                "Arial",
                                                                                              paddingTop: 16,
                                                                                            }}
                                                                                          >
                                                                                            This
                                                                                            privacy
                                                                                            policy
                                                                                            was
                                                                                            created
                                                                                            using
                                                                                            Termly's{" "}
                                                                                            <a
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(48,48,241)!important",
                                                                                              }}
                                                                                              href="https://www.google.com/url?q=https://termly.io/products/privacy-policy-generator/&source=gmail-html&ust=1710201824112000&usg=AOvVaw0WTWRHmy4ce84W-MbmKQr8"
                                                                                              target="_blank"
                                                                                              rel="noopener noreferrer"
                                                                                            >
                                                                                              Privacy
                                                                                              Policy
                                                                                              Generator
                                                                                            </a>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </S.PrivacyContainer>
      </Container>
    </>
  );
}

export default PrivacyPolicy;
