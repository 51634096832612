import React from "react";
import Container from "react-bootstrap/Container";

import * as S from "./styles";

function ShowerOfBlessingsAddendum() {
  return (
    <>
      <Container>
        <S.PageHeader>
          <span>Shower of Blessings Addendum</span>
        </S.PageHeader>

        <p>
          <span>
            [Includes a Tsok feast offering. If adding the Tsok feast section
            found at the end of the text, insert it after the
            recitation-accumulation, just prior to the conclusion]
          </span>
        </p>
        <p>
          <span>
            &#3844;&#3845;&#3853;
            &#3853;&#3906;&#3956;&#3851;&#3938;&#3956;&#3936;&#3954;&#3851;&#3930;&#3954;&#3906;&#3851;&#3926;&#3921;&#3956;&#3923;&#3851;&#3906;&#3942;&#3964;&#3939;&#3851;&#3936;&#3921;&#3962;&#3926;&#3942;&#3851;&#3921;&#3908;&#3851;&#3936;&#3926;&#4018;&#3962;&#3939;&#3851;&#3926;&#3936;&#3954;&#3851;&#3926;&#4019;&#3851;&#3942;&#3986;&#4018;&#3956;&#3926;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3926;&#3938;&#4019;&#3926;&#3942;&#3851;&#3910;&#3938;&#3851;&#3936;&#3926;&#3962;&#3926;&#3942;&#3851;&#3926;&#3934;&#3956;&#3906;&#3942;&#3851;&#3942;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>
            A Shower of Blessings: A Guru Yoga Practice to Accompany the Seven
            Line Prayer
          </span>
        </p>
        <p>
          <span>
            Composed by Mipham Rinpoche and arranged by &#346;&#257;kya
            &#346;r&#299;
          </span>
        </p>
        <p>
          <span>Refuge and Bodhicitta</span>
        </p>
        <p>
          <span>
            &#3930;&#3954;&#3906;&#3851;&#3926;&#3921;&#3956;&#3923;&#3851;&#3926;&#4019;&#3851;&#3942;&#3986;&#4018;&#3956;&#3926;&#3851;&#3904;&#4017;&#3954;&#3851;&#3920;&#3964;&#3906;&#3851;&#3928;&#3938;&#3851;&#3942;&#3984;&#4017;&#3926;&#3942;&#3851;&#3936;&#3906;&#4018;&#3964;&#3851;&#3942;&#3962;&#3928;&#3942;&#3851;&#3926;&#3942;&#3984;&#4017;&#3962;&#3921;&#3851;&#3923;&#3954;&#3853;
          </span>
        </p>
        <p>
          <span>
            Begin the Seven Line Prayer Guru Yoga by taking refuge and
            generating bodhicitta:
          </span>
        </p>
        <p>
          <span>
            &#3943;&#3953;&#3956;&#3971;&#3853;
            &#3938;&#3954;&#3906;&#3851;&#3942;&#3999;&#3964;&#3908;&#3851;&#3908;&#3964;&#3851;&#3926;&#3964;&#3851;&#3928;&#3930;&#3964;&#3851;&#3942;&#3984;&#4017;&#3962;&#3942;&#3851;&#3924;&#3921;&#4008;&#3851;&#3936;&#3926;&#4017;&#3956;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>hung, riktong ngowo tsoky&eacute; pema jung</span>
        </p>
        <p>
          <span>
            H&#363;&#7747;! Lake-born Padmasambhava, empty awareness in essence,
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3984;&#4017;&#3926;&#3942;&#3851;&#3906;&#3923;&#3942;&#3851;&#3904;&#3956;&#3923;&#3851;&#3936;&#3921;&#3956;&#3942;&#3851;&#3908;&#3964;&#3851;&#3926;&#3964;&#3938;&#3851;&#3942;&#3984;&#4017;&#3926;&#3942;&#3851;&#3942;&#3956;&#3851;&#3928;&#3910;&#3954;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>kyabn&eacute; k&uuml;nd&uuml; ngowor kyab su chi</span>
        </p>
        <p>
          <span>
            You are the essential embodiment of all sources of refuge; I take
            refuge you.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#4018;&#3954;&#3921;&#3851;&#3936;&#3921;&#3954;&#3938;&#3851;&#3906;&#4019;&#3964;&#3851;&#3926;&#3956;&#3938;&#3851;&#3936;&#3905;&#4018;&#3956;&#3939;&#3851;&#3924;&#3936;&#3954;&#3851;&#3936;&#3906;&#4018;&#3964;&#3851;&#3926;&#3851;&#3904;&#3956;&#3923;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>si dir lobur trulp&eacute; drowa k&uuml;n</span>
        </p>
        <p>
          <span>
            May all beings bound to this life&rsquo;s fleeting delusions
          </span>
        </p>
        <p>
          <span>
            &#3936;&#3905;&#4018;&#3956;&#3939;&#3851;&#3924;&#3851;&#3938;&#3908;&#3851;&#3942;&#3908;&#3942;&#3851;&#3904;&#3851;&#3921;&#3906;&#3851;&#3904;&#4019;&#3964;&#3908;&#3851;&#3906;&#4018;&#3964;&#3939;&#3851;&#3940;&#3964;&#3906;
            &#3853;
          </span>
        </p>
        <p>
          <span>trulpa rangsang kadak long drol shok</span>
        </p>
        <p>
          <span>
            Naturally dispel their delusion and gain liberation in the
            primordially pure expanse.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3984;&#4017;&#3926;&#3942;&#3851;&#3942;&#3962;&#3928;&#3942;&#3851;&#3939;&#3923;&#3851;&#3906;&#3942;&#3956;&#3928;&#3853;
          </span>
        </p>
        <p>
          <span>Recite this verse three times.</span>
        </p>
        <p>
          <span>
            &#3926;&#3906;&#3962;&#3906;&#3942;&#3851;&#3906;&#3919;&#3964;&#3938;&#3851;&#3942;&#4018;&#3956;&#3908;&#3851;&#3936;&#3905;&#3964;&#3938;&#3851;&#3923;&#3954;&#3853;
          </span>
        </p>
        <p>
          <span>
            Offering a Torma to the Obstructing Forces and Visualizing the
            Protective Tent
          </span>
        </p>
        <p>
          <span>
            &#3943;&#3953;&#3956;&#3971;&#3853;
            &#3928;&#3851;&#3938;&#3954;&#3906;&#3851;&#3906;&#3935;&#3956;&#3908;&#3851;&#3936;&#3931;&#3954;&#3923;&#3851;&#3936;&#3905;&#4018;&#3956;&#3939;&#3851;&#3924;&#3936;&#3954;&#3851;&#3936;&#3926;&#4017;&#3956;&#3908;&#3851;&#3924;&#3964;&#3851;&#3938;&#4003;&#3928;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>hung, marik zungdzin trulp&eacute; jungpo nam</span>
        </p>
        <p>
          <span>
            H&#363;&#7747;! Evil forces of ignorance, dualistic perception, and
            delusion,
          </span>
        </p>
        <p>
          <span>
            &#3906;&#3919;&#3964;&#3938;&#3851;&#3928;&#3851;&#3936;&#3921;&#3954;&#3851;&#3905;&#4017;&#3962;&#3938;&#3851;&#3928;&#3920;&#3936;&#3851;&#3926;&#4018;&#3939;&#3851;&#3908;&#3908;&#3851;&#3921;&#3956;&#3851;&#3921;&#3962;&#3908;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>torma di khyer tadral ngang du deng</span>
        </p>
        <p>
          <span>
            Take this torma, and vanish into the realm beyond all extremes.
          </span>
        </p>
        <p>
          <span>
            &#3938;&#3908;&#3851;&#3938;&#3954;&#3906;&#3851;&#3937;&#3962;&#3851;&#3940;&#3962;&#3942;&#3851;&#3936;&#3925;&#3964;&#3851;&#3936;&#3906;&#4017;&#3956;&#3938;&#3851;&#3928;&#3962;&#3921;&#3851;&#3924;&#3936;&#3954;&#3851;&#3908;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>rangrig yeshe pogyur mep&eacute; ngang</span>
        </p>
        <p>
          <span>Within the unchanging realm of naturally aware wisdom,</span>
        </p>
        <p>
          <span>
            &#3936;&#3905;&#3964;&#3938;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3937;&#3962;&#3851;&#3904;&#4019;&#3964;&#3908;&#3851;&#3908;&#3908;&#3851;&#3921;&#3956;&#3851;&#3928;&#3930;&#3928;&#3942;&#3851;&#3926;&#3909;&#3921;&#3851;&#3921;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>khor sum y&eacute; long ngang du tsam ch&eacute; do</span>
        </p>
        <p>
          <span>
            The primordial expanse beyond the three spheres, the boundary is
            set!
          </span>
        </p>
        <p>
          <span>
            &#3921;&#4018;&#3906;&#3851;&#3942;&#3988;&#3906;&#3942;&#3851;&#3926;&#3938;&#3991;&#3964;&#3921;&#3851;&#3909;&#3954;&#3908;&#3851;&#3920;&#3956;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3926;&#4018;&#3926;&#3853;
          </span>
        </p>
        <p>
          <span>
            Recite the wrathful mantra, and expel the obstructing forces with
            wrathful substances.
          </span>
        </p>
        <p>
          <span>
            &#3926;&#3931;&#4018;&#3851;&#3931;&#3993;&#3953;&#3851;&#3923;&#3851;&#3938;&#3851;&#3904;&#4021;&#3851;&#3938;&#3904;&#4021;&#3967;
          </span>
        </p>
        <p>
          <span>benza jnana raksha raksha</span>
        </p>
        <p>
          <span>vajra-j&ntilde;&#257;na rak&#7779;a rak&#7779;a</span>
        </p>
        <p>
          <span>
            &#3926;&#4017;&#3954;&#3923;&#3851;&#3936;&#3926;&#3962;&#3926;&#3942;&#3851;&#3923;&#3954;&#3853;
          </span>
        </p>
        <p>
          <span>The Descent of Blessings</span>
        </p>
        <p>
          <span>
            &#3920;&#3964;&#3921;&#3851;&#3938;&#3988;&#3851;&#3926;&#3938;&#4001;&#3956;&#3908;&#3851;&#3934;&#3954;&#3908;&#3851;&#3906;&#3921;&#3956;&#3908;&#3851;&#3940;&#3956;&#3906;&#3942;&#3851;&#3921;&#4018;&#3906;&#3851;&#3924;&#3964;&#3936;&#3954;&#3851;&#3926;&#3938;&#4001;&#3942;&#3851;&#3926;&#3942;&#3984;&#3956;&#3939;&#3851;&#3919;&#3962;&#3851;&#3936;&#3921;&#3954;&#3851;&#3942;&#3984;&#3921;&#3851;&#3921;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>
            Play the &#7693;&#257;maru, and utter this invocation with signs of
            intense longing and devotion:
          </span>
        </p>
        <p>
          <span>
            &#3943;&#3953;&#3956;&#3971;&#3853;
            &#3926;&#3921;&#3962;&#3851;&#3910;&#3962;&#3923;&#3851;&#3924;&#3921;&#4008;&#3851;&#3936;&#3905;&#4017;&#3954;&#3939;&#3851;&#3926;&#3936;&#3954;&#3851;&#3934;&#3954;&#3908;&#3851;&#3905;&#3928;&#3942;&#3851;&#3923;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>hung, dechen pema khyilw&eacute; shyingkham n&eacute;</span>
        </p>
        <p>
          <span>
            H&#363;&#7747;! From the pure land of blissfully swirling lotuses,
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3984;&#3956;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3939;&#4023;&#3956;&#3923;&#3851;&#3906;&#4018;&#3956;&#3926;&#3851;&#3928;&#3930;&#3964;&#3851;&#3942;&#3984;&#4017;&#3962;&#3942;&#3851;&#3938;&#4001;&#3964;&#3851;&#3938;&#3991;&#3962;&#3851;&#3936;&#3910;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>ku sum lh&uuml;ndrub tsoky&eacute; dorj&eacute; chang</span>
        </p>
        <p>
          <span>
            Let the blessings of the Lake-born Vajradhara, three
            k&#257;yas&rsquo; spontaneous presence,
          </span>
        </p>
        <p>
          <span>
            &#3906;&#3923;&#3942;&#3851;&#3936;&#3921;&#3954;&#3938;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3925;&#3964;&#3926;&#3851;&#3928;&#3910;&#3964;&#3906;&#3851;&#3920;&#3956;&#3923;&#3851;&#3921;&#3908;&#3964;&#3942;&#3851;&#3906;&#4018;&#3956;&#3926;&#3851;&#3942;&#4009;&#3964;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>n&eacute; dir jin pob chokt&uuml;n ng&ouml;drub tsol</span>
        </p>
        <p>
          <span>
            Now descend; bestow us with the ordinary and supreme siddhis!
          </span>
        </p>
        <p>
          <span>
            &#3928;&#3910;&#3964;&#3921;&#3851;&#3938;&#4011;&#3942;&#3851;&#3904;&#3956;&#3923;&#3851;&#3926;&#3935;&#3908;&#3851;&#3938;&#3964;&#3939;&#3851;&#3924;&#3936;&#3954;&#3851;&#3938;&#3986;&#4017;&#3923;&#3851;&#3921;&#3956;&#3851;&#3906;&#4017;&#3956;&#3938;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>ch&ouml;dz&eacute; kunzang rolp&eacute; gyen du gyur</span>
        </p>
        <p>
          <span>
            These offerings become ornaments of Samantabhadra&rsquo;s display!
          </span>
        </p>
        <p>
          <span>
            &#3944;&#3964;&#3971;&#3851;&#3944;&#3953;&#3967;&#3943;&#3953;&#3956;&#3971;&#3926;&#3931;&#4018;&#3851;&#3906;&#3956;&#3851;&#3938;&#3956;&#3851;&#3924;&#3921;&#4008;&#3851;&#3920;&#3964;&#3921;&#3851;&#3925;&#4018;&#3962;&#3908;&#3851;&#3938;&#4009;&#3939;&#3851;&#3926;&#3931;&#4018;&#3851;&#3942;&#3851;&#3928;&#3851;&#3937;&#3851;&#3931;&#3967;
            &#3944;&#3953;&#3851;&#3933;&#3851;&#3940;&#3851;&#3937;&#3851;&#3944;&#3953;&#3851;&#3933;&#3851;&#3940;&#3851;&#3937;&#3851;&#3944;&#3851;&#3944;&#3851;&#3944;&#3953;&#3967;
            &#3944;&#3964;&#3971;&#3851;&#3926;&#3931;&#4018;&#3851;&#3924;&#3953;&#3956;&#3851;&#3911;&#3851;&#3928;&#3962;&#3906;&#4023;&#3851;&#3942;&#3851;&#3928;&#3956;&#3851;&#3921;&#4018;&#3851;&#3943;&#3953;&#3956;&#3971;&#3851;&#3943;&#4018;&#3953;&#3954;&#3851;&#3915;&#3953;&#3853;
          </span>
        </p>
        <p>
          <span>
            om ah hum benza guru pema t&ouml;treng tsal benza samaya dza |
            awashaya awashaya a a ah | om benza pu ja megha samudra hung hri tha
            |
          </span>
        </p>
        <p>
          <span>
            o&#7747; &#257;&#7717; h&#363;&#7747; vajra-guru-padma t&ouml;treng
            tsal vajra-samaya ja&#7717; | ava&#347;aya ava&#347;aya a a
            &#257;&#7717; | o&#7747; vajra-p&#363;ja-megha-samudra
            h&#363;&#7747; hr&#299;&#7717; th&#257; |
          </span>
        </p>
        <p>
          <span>
            &#3921;&#3908;&#3964;&#3942;&#3851;&#3906;&#3934;&#3954;&#3851;&#3939;&#4023;&#3851;&#3926;&#3942;&#3984;&#4017;&#3962;&#3942;&#3851;&#3923;&#3954;&#3853;
          </span>
        </p>
        <p>
          <span>The Main Part: Generating the Deity</span>
        </p>
        <p>
          <span>
            &#3944;&#3953;&#3967;
            &#3938;&#3908;&#3851;&#3939;&#3956;&#3942;&#3851;&#3920;&#3851;&#3928;&#3939;&#3851;&#3906;&#3923;&#3942;&#3851;&#3924;&#3936;&#3954;&#3851;&#3928;&#3921;&#3956;&#3923;&#3851;&#3928;&#3905;&#3936;&#3851;&#3938;&#3956;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>ah, rang l&uuml; tamal nep&eacute; d&uuml;n kha ru</span>
        </p>
        <p>
          <span>Ah! I am in my ordinary form; before me, in the sky</span>
        </p>
        <p>
          <span>
            &#3944;&#3964;&#3851;&#3938;&#3986;&#4017;&#3923;&#3851;&#3921;&#4018;&#3954;&#3851;&#3928;&#3962;&#3921;&#3851;&#3921;&#4023;&#3851;&#3923;&#3851;&#3904;&#3964;&#3851;&#3940;&#3936;&#3954;&#3851;&#3928;&#3930;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>orgyen drim&eacute; dhanakosh&eacute; tso</span>
        </p>
        <p>
          <span>
            Appears O&#7693;&#7693;iy&#257;na, and the immaculate Dhanako&#347;a
            lake,
          </span>
        </p>
        <p>
          <span>
            &#3906;&#3919;&#3954;&#3908;&#3851;&#3935;&#3926;&#3851;&#3937;&#3923;&#3851;&#3939;&#3906;&#3851;&#3926;&#3938;&#3986;&#4017;&#3921;&#3851;&#3939;&#4001;&#3923;&#3851;&#3910;&#3956;&#3942;&#3851;&#3906;&#3908;&#3851;&#3926;&#3936;&#3954;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>ting zap yenlak gy&eacute; den ch&uuml; gangw&eacute;</span>
        </p>
        <p>
          <span>Deep and full of water with eight kinds of purity.</span>
        </p>
        <p>
          <span>
            &#3921;&#3926;&#3956;&#3942;&#3851;&#3942;&#3956;&#3851;&#3938;&#3954;&#3923;&#3851;&#3910;&#3962;&#3923;&#3851;&#3924;&#3921;&#3851;&#3942;&#4001;&#3964;&#3908;&#3851;&#3936;&#3921;&#3926;&#3851;&#3938;&#3986;&#4017;&#3942;&#3851;&#3942;&#3999;&#3962;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>w&uuml; su rin chen p&eacute; dong dap gy&eacute; teng</span>
        </p>
        <p>
          <span>
            In its centre you dwell, on a jewelled lotus blossom, its petals
            open wide:
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3984;&#4017;&#3926;&#3942;&#3851;&#3906;&#3923;&#3942;&#3851;&#3904;&#3956;&#3923;&#3851;&#3936;&#3921;&#3956;&#3942;&#3851;&#3944;&#3964;&#3851;&#3938;&#3986;&#4017;&#3923;&#3851;&#3938;&#4001;&#3964;&#3851;&#3938;&#3991;&#3962;&#3851;&#3936;&#3910;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>kyapn&eacute; kund&uuml; orgyen dorj&eacute; chang</span>
        </p>
        <p>
          <span>All sources of refuge combined, Orgyen Dorje Chang,</span>
        </p>
        <p>
          <span>
            &#3928;&#3930;&#3923;&#3851;&#3921;&#3924;&#3962;&#3936;&#3954;&#3851;&#3921;&#3924;&#3939;&#3851;&#3936;&#3926;&#3938;&#3851;&#3928;&#3930;&#3964;&#3851;&#3938;&#3986;&#4017;&#3939;&#3851;&#3937;&#3956;&#3928;&#3851;&#3921;&#3908;&#3851;&#3936;&#3905;&#4018;&#3954;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>tsenp&eacute; palbar tsogyal yum dang tril</span>
        </p>
        <p>
          <span>
            Ablaze in splendour with the major and minor marks of perfection,
            and embracing the consort Yeshe Tsogyal.
          </span>
        </p>
        <p>
          <span>
            &#3925;&#4017;&#3906;&#3851;&#3906;&#3937;&#3942;&#3851;&#3938;&#4001;&#3964;&#3851;&#3938;&#3991;&#3962;&#3851;&#3906;&#3937;&#3964;&#3923;&#3851;&#3924;&#3942;&#3851;&#3920;&#3964;&#3921;&#3851;&#3926;&#3956;&#3928;&#3851;&#3926;&#3942;&#4003;&#3928;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>
            chak y&eacute; dorj&eacute; y&ouml;np&eacute; t&ouml; bum nam
          </span>
        </p>
        <p>
          <span>
            You hold a vajra in your right hand, a skull cup containing a vase
            in your left,
          </span>
        </p>
        <p>
          <span>
            &#3921;&#3938;&#3851;&#3921;&#3908;&#3851;&#3938;&#3954;&#3923;&#3851;&#3910;&#3962;&#3923;&#3851;&#3938;&#3956;&#3942;&#3851;&#3924;&#3936;&#3954;&#3851;&#3938;&#3986;&#4017;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3928;&#3931;&#3962;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>dar dang rinchen r&uuml;p&eacute; gyen gyi dz&eacute;</span>
        </p>
        <p>
          <span>Beautiful in all your silk, jewel and bone ornaments.</span>
        </p>
        <p>
          <span>
            &#3936;&#3964;&#3921;&#3851;&#3939;&#3988;&#3936;&#3954;&#3851;&#3904;&#4019;&#3964;&#3908;&#3851;&#3923;&#3942;&#3851;&#3926;&#3921;&#3962;&#3851;&#3910;&#3962;&#3923;&#3851;&#3906;&#3935;&#3954;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3936;&#3926;&#3938;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>&ouml;ng&eacute; longn&eacute; dechen zi jin bar</span>
        </p>
        <p>
          <span>
            In an expanse of blue, white, yellow, red and green light, you
            preside resplendent, radiating great bliss.
          </span>
        </p>
        <p>
          <span>
            &#3936;&#3905;&#3964;&#3938;&#3851;&#3921;&#3956;&#3851;&#3938;&#4009;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3938;&#3986;&#4017;&#3851;&#3928;&#3930;&#3964;&#3851;&#3942;&#4004;&#4018;&#3954;&#3923;&#3851;&#3939;&#3999;&#3938;&#3851;&#3906;&#3919;&#3954;&#3926;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>khor du tsa sum gyatso trin tar tip</span>
        </p>
        <p>
          <span>
            All around you, gurus, devas and &#7693;&#257;kin&#299;s gather like
            clouds.
          </span>
        </p>
        <p>
          <span>
            &#3926;&#4017;&#3954;&#3923;&#3851;&#3938;&#4019;&#3926;&#3942;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3938;&#3991;&#3962;&#3936;&#3954;&#3851;&#3910;&#3938;&#3851;&#3936;&#3926;&#3962;&#3926;&#3942;&#3851;&#3926;&#3921;&#3906;&#3851;&#3939;&#3851;&#3906;&#3935;&#3954;&#3906;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>jinlap tukj&eacute; char bep dak la zik</span>
        </p>
        <p>
          <span>
            Pour down your blessings in a rain of compassion: watch over and
            care for me!
          </span>
        </p>
        <p>
          <span>
            &#3938;&#3986;&#4017;&#3939;&#3851;&#3904;&#3956;&#3923;&#3851;&#3908;&#3964;&#3851;&#3926;&#3964;&#3851;&#3936;&#3910;&#3954;&#3851;&#3928;&#3962;&#3921;&#3851;&#3937;&#3962;&#3851;&#3940;&#3962;&#3942;&#3851;&#3942;&#3984;&#3956;&#3938;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>gyal k&uuml;n ngowo chim&eacute; yesh&eacute; kur</span>
        </p>
        <p>
          <span>Essence of all buddhas, in your immortal wisdom body,</span>
        </p>
        <p>
          <span>
            &#3906;&#3921;&#3956;&#3908;&#3851;&#3940;&#3956;&#3906;&#3942;&#3851;&#3921;&#4018;&#3906;&#3851;&#3924;&#3964;&#3942;&#3851;&#3921;&#3921;&#3851;&#3925;&#4017;&#3906;&#3851;&#3938;&#3999;&#3906;&#3851;&#3919;&#3956;&#3851;&#3936;&#3930;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>dung shuk drakp&ouml; d&eacute; chak tak tu tsal</span>
        </p>
        <p>
          <span>
            Now and always, I bow to you with passionate longing and devotion.
          </span>
        </p>
        <p>
          <span>
            &#3939;&#3956;&#3942;&#3851;&#3921;&#3908;&#3851;&#3939;&#3964;&#3908;&#3942;&#3851;&#3942;&#4004;&#4017;&#3964;&#3921;&#3851;&#3921;&#3956;&#3942;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3921;&#3906;&#3962;&#3851;&#3926;&#3936;&#3954;&#3851;&#3930;&#3964;&#3906;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>l&uuml; dang long ch&ouml; d&uuml; sum gew&eacute; tsok</span>
        </p>
        <p>
          <span>
            My bodies, possessions and all my merit, past, present and future
          </span>
        </p>
        <p>
          <span>
            &#3904;&#3956;&#3923;&#3851;&#3926;&#3935;&#3908;&#3851;&#3928;&#3910;&#3964;&#3921;&#3851;&#3924;&#3936;&#3954;&#3851;&#3942;&#4004;&#4018;&#3954;&#3923;&#3851;&#3921;&#3956;&#3851;&#3921;&#3928;&#3954;&#3906;&#3942;&#3851;&#3923;&#3942;&#3851;&#3936;&#3926;&#3956;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>kunzang ch&ouml;p&eacute; trin du mikn&eacute; bul</span>
        </p>
        <p>
          <span>
            I visualize as Samantabhadra&rsquo;s offering clouds, and present
            them to you.
          </span>
        </p>
        <p>
          <span>
            &#3920;&#3964;&#3906;&#3851;&#3928;&#3962;&#3921;&#3851;&#3923;&#3942;&#3851;&#3926;&#3942;&#3906;&#3942;&#3851;&#3942;&#4001;&#3954;&#3906;&#3851;&#3939;&#3999;&#3956;&#3908;&#3851;&#3928;&#3851;&#3939;&#3956;&#3942;&#3851;&#3926;&#3940;&#3906;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>tokm&eacute; n&eacute; sak dik tung ma l&uuml; shak</span>
        </p>
        <p>
          <span>
            My harmful actions and downfalls, committed through beginningless
            time, I confess without exception.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#4018;&#3942;&#3851;&#3926;&#3909;&#3942;&#3851;&#3938;&#3986;&#4017;&#3939;&#3851;&#3926;&#3851;&#3904;&#3956;&#3923;&#3851;&#3906;&#4017;&#3954;&#3851;&#3937;&#3964;&#3923;&#3851;&#3919;&#3923;&#3851;&#3906;&#4017;&#3954;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>s&eacute; ch&eacute; gyalwa k&uuml;n gyi y&ouml;nten gyi</span>
        </p>
        <p>
          <span>
            You alone have completely mastered the precious qualities of all the
            buddhas and bodhisattvas,
          </span>
        </p>
        <p>
          <span>
            &#3905;&#4017;&#3926;&#3851;&#3926;&#3921;&#3906;&#3851;&#3906;&#3909;&#3954;&#3906;&#3851;&#3924;&#3956;&#3851;&#3928;&#3906;&#3964;&#3923;&#3851;&#3924;&#3964;&#3936;&#3954;&#3851;&#3938;&#4003;&#3928;&#3851;&#3920;&#3938;&#3851;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>khyap dak chik pu g&ouml;np&ouml; nam tar la</span>
        </p>
        <p>
          <span>
            And with all my heart I rejoice at your liberating life story.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3993;&#3954;&#3908;&#3851;&#3923;&#3942;&#3851;&#3937;&#3954;&#3851;&#3938;&#3908;&#3851;&#3921;&#3921;&#3851;&#3924;&#3942;&#3851;&#3906;&#3942;&#3964;&#3939;&#3851;&#3936;&#3921;&#3962;&#3926;&#3942;&#3851;&#3940;&#3954;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>nying n&eacute; yi rang dep&eacute; sol dep shing</span>
        </p>
        <p>
          <span>With total trust I pray to you,</span>
        </p>
        <p>
          <span>
            &#3935;&#3926;&#3851;&#3938;&#3986;&#4017;&#3942;&#3851;&#3910;&#3964;&#3942;&#3851;&#3904;&#4017;&#3954;&#3851;&#3910;&#3938;&#3851;&#3910;&#3962;&#3923;&#3851;&#3936;&#3926;&#3962;&#3926;&#3942;&#3851;&#3924;&#3938;&#3851;&#3926;&#3942;&#3984;&#3956;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>zap gy&eacute; ch&ouml; kyi char chen beppar kul</span>
        </p>
        <p>
          <span>
            And implore you: shower down a great rain of Dharma, profound and
            vast.
          </span>
        </p>
        <p>
          <span>
            &#3938;&#3908;&#3851;&#3906;&#3934;&#3923;&#3851;&#3921;&#3906;&#3962;&#3851;&#3926;&#3936;&#3954;&#3851;&#3921;&#3908;&#3964;&#3942;&#3851;&#3924;&#3964;&#3851;&#3904;&#3956;&#3923;&#3851;&#3926;&#3942;&#4001;&#3964;&#3928;&#3942;&#3851;&#3923;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>rang shyen gew&eacute; ng&ouml;po kun dom n&eacute;</span>
        </p>
        <p>
          <span>Gathering all the actual merit of myself and others,</span>
        </p>
        <p>
          <span>
            &#3936;&#3906;&#4018;&#3964;&#3851;&#3905;&#3928;&#3942;&#3851;&#3938;&#3986;&#4017;&#3851;&#3928;&#3930;&#3964;&#3851;&#3911;&#3954;&#3851;&#3942;&#4018;&#3954;&#3921;&#3851;&#3906;&#3923;&#3942;&#3851;&#3904;&#4017;&#3954;&#3851;&#3926;&#3938;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>dro kham gyatso ji si n&eacute; kyi bar</span>
        </p>
        <p>
          <span>
            For as long as these infinite realms of living beings remain
          </span>
        </p>
        <p>
          <span>
            &#3928;&#3906;&#3964;&#3923;&#3851;&#3924;&#3964;&#3851;&#3905;&#4017;&#3964;&#3921;&#3851;&#3904;&#4017;&#3954;&#3851;&#3938;&#4003;&#3928;&#3851;&#3920;&#3938;&#3851;&#3938;&#3991;&#3962;&#3942;&#3851;&#3926;&#3942;&#3993;&#3962;&#3906;&#3942;&#3851;&#3919;&#3962;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>g&ouml;npo khy&ouml; kyi namtar j&eacute; nyek t&eacute;</span>
        </p>
        <p>
          <span>I shall follow your example, Lord,</span>
        </p>
        <p>
          <span>
            &#3928;&#3905;&#3936;&#3851;&#3905;&#4017;&#3926;&#3851;&#3936;&#3906;&#4018;&#3964;&#3851;&#3926;&#3851;&#3936;&#3921;&#4018;&#3962;&#3923;&#3851;&#3924;&#3936;&#3954;&#3851;&#3921;&#3964;&#3923;&#3851;&#3921;&#3956;&#3851;&#3926;&#3942;&#3988;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>kha khyap drowa drenp&eacute; d&ouml;n du ngo</span>
        </p>
        <p>
          <span>
            And dedicate it all to guiding beings as limitless as space.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3984;&#4017;&#3926;&#3942;&#3851;&#3906;&#3923;&#3942;&#3851;&#3904;&#3956;&#3923;&#3851;&#3936;&#3921;&#3956;&#3942;&#3851;&#3928;&#3905;&#4017;&#3962;&#3923;&#3851;&#3926;&#3938;&#4009;&#3962;&#3936;&#3954;&#3851;&#3906;&#3919;&#3962;&#3938;&#3851;&#3910;&#3962;&#3923;&#3851;&#3924;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>kyapn&eacute; kund&uuml; khyents&eacute; terchen po</span>
        </p>
        <p>
          <span>
            You are all my sources of refuge in one, a great treasure of wisdom
            and love,
          </span>
        </p>
        <p>
          <span>
            &#3921;&#3956;&#3942;&#3851;&#3908;&#3923;&#3851;&#3942;&#3993;&#3954;&#3906;&#3942;&#3851;&#3928;&#3936;&#3954;&#3851;&#3942;&#3984;&#4017;&#3926;&#3942;&#3851;&#3928;&#3910;&#3964;&#3906;&#3851;&#3938;&#3954;&#3923;&#3851;&#3924;&#3964;&#3851;&#3910;&#3962;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>d&uuml; ngen nyik m&eacute; kyap chok rinpoch&eacute;</span>
        </p>
        <p>
          <span>
            Our precious, our greatest, protection in this dark age, the dregs
            of time.
          </span>
        </p>
        <p>
          <span>
            &#3939;&#3988;&#3851;&#3926;&#3921;&#3964;&#3936;&#3954;&#3851;&#3938;&#3986;&#3956;&#3921;&#3851;&#3924;&#3942;&#3851;&#3928;&#3923;&#3938;&#3851;&#3940;&#3954;&#3908;&#3851;&#3906;&#3921;&#3956;&#3908;&#3942;&#3851;&#3940;&#3956;&#3906;&#3942;&#3851;&#3904;&#4017;&#3954;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>nga d&ouml; g&uuml;p&eacute; nar shing dung shuk kyi</span>
        </p>
        <p>
          <span>
            Tormented and drained by the five kinds of degeneration, when I call
            on you
          </span>
        </p>
        <p>
          <span>
            &#3906;&#3942;&#3964;&#3939;&#3851;&#3936;&#3921;&#3962;&#3926;&#3942;&#3851;&#3926;&#3956;&#3851;&#3939;&#3851;&#3926;&#3938;&#4009;&#3962;&#3851;&#3926;&#3942;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3904;&#4017;&#3954;&#3942;&#3851;&#3906;&#3935;&#3954;&#3906;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>sol dep bu la tsew&eacute; tuk kyi zik</span>
        </p>
        <p>
          <span>
            In desperate yearning, look upon your child with all the love of
            your wisdom mind.
          </span>
        </p>
        <p>
          <span>
            &#3921;&#3906;&#3964;&#3908;&#3942;&#3851;&#3924;&#3936;&#3954;&#3851;&#3904;&#4019;&#3964;&#3908;&#3851;&#3923;&#3942;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3938;&#3991;&#3962;&#3936;&#3954;&#3851;&#3938;&#4009;&#3939;&#3851;&#3925;&#4017;&#3956;&#3908;&#3942;&#3851;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>gong p&eacute; long n&eacute; tukj&eacute; tsal chung la</span>
        </p>
        <p>
          <span>
            Let the power of your compassion burst out from your vast expanse of
            realization,
          </span>
        </p>
        <p>
          <span>
            &#3928;&#3964;&#3942;&#3851;&#3939;&#4001;&#3923;&#3851;&#3926;&#3921;&#3906;&#3851;&#3906;&#3954;&#3851;&#3942;&#3993;&#3954;&#3908;&#3851;&#3939;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3938;&#4019;&#3964;&#3926;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>m&ouml; den dak gi nying la jin gyi lop</span>
        </p>
        <p>
          <span>And flood my devoted heart with your blessing.</span>
        </p>
        <p>
          <span>
            &#3938;&#3999;&#3906;&#3942;&#3851;&#3921;&#3908;&#3851;&#3928;&#3930;&#3923;&#3851;&#3928;&#3851;&#3928;&#4017;&#3956;&#3938;&#3851;&#3921;&#3956;&#3851;&#3942;&#3999;&#3964;&#3923;&#3851;&#3924;&#3851;&#3921;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>tak dang tsenma nyur du t&ouml;npa dang</span>
        </p>
        <p>
          <span>Quickly show me the signs and marks of attainment,</span>
        </p>
        <p>
          <span>
            &#3928;&#3910;&#3964;&#3906;&#3851;&#3921;&#3908;&#3851;&#3920;&#3956;&#3923;&#3851;&#3928;&#3964;&#3908;&#3851;&#3921;&#3908;&#3964;&#3942;&#3851;&#3906;&#4018;&#3956;&#3926;&#3851;&#3942;&#4009;&#3939;&#3851;&#3921;&#3956;&#3851;&#3906;&#3942;&#3964;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>chok dang t&uuml;nmong ng&ouml;drup tsal du sol</span>
        </p>
        <p>
          <span>And grant me siddhis, ordinary and supreme.</span>
        </p>
        <p>
          <span>
            &#3943;&#3953;&#3956;&#3971;&#3860;
            &#3944;&#3964;&#3851;&#3938;&#3986;&#4017;&#3923;&#3851;&#3937;&#3956;&#3939;&#3851;&#3906;&#4017;&#3954;&#3851;&#3923;&#3956;&#3926;&#3851;&#3926;&#4017;&#3908;&#3851;&#3928;&#3930;&#3928;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>hung, orgyen yul gyi nup jang tsam</span>
        </p>
        <p>
          <span>
            H&#363;&#7747;! In the north-west of the land of
            O&#7693;&#7693;iy&#257;na,
          </span>
        </p>
        <p>
          <span>
            &#3924;&#3921;&#4008;&#3851;&#3906;&#3962;&#3851;&#3942;&#3938;&#3851;&#3942;&#4001;&#3964;&#3908;&#3851;&#3924;&#3964;&#3851;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>pema g&eacute;sar dong po la</span>
        </p>
        <p>
          <span>In the heart of a lotus flower,</span>
        </p>
        <p>
          <span>
            &#3937;&#3851;&#3928;&#3930;&#3923;&#3851;&#3928;&#3910;&#3964;&#3906;&#3851;&#3906;&#3954;&#3851;&#3921;&#3908;&#3964;&#3942;&#3851;&#3906;&#4018;&#3956;&#3926;&#3851;&#3926;&#3938;&#3993;&#3962;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>yatsen chok gi ng&ouml; drup ny&eacute;</span>
        </p>
        <p>
          <span>Endowed with the most marvellous attainments,</span>
        </p>
        <p>
          <span>
            &#3924;&#3921;&#4008;&#3851;&#3936;&#3926;&#4017;&#3956;&#3908;&#3851;&#3906;&#3923;&#3942;&#3851;&#3934;&#3962;&#3942;&#3851;&#3942;&#3956;&#3851;&#3906;&#4018;&#3906;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>pema jungn&eacute; shy&eacute; su drak</span>
        </p>
        <p>
          <span>You are renowned as the Lotus-born,</span>
        </p>
        <p>
          <span>
            &#3936;&#3905;&#3964;&#3938;&#3851;&#3921;&#3956;&#3851;&#3928;&#3905;&#3936;&#3851;&#3936;&#3906;&#4018;&#3964;&#3851;&#3928;&#3908;&#3851;&#3924;&#3964;&#3942;&#3851;&#3926;&#3942;&#3984;&#3964;&#3938;&#3860;
          </span>
        </p>
        <p>
          <span>khor du khandro mangp&ouml; kor</span>
        </p>
        <p>
          <span>Surrounded by many hosts of &#7693;&#257;kin&#299;s.</span>
        </p>
        <p>
          <span>
            &#3905;&#4017;&#3962;&#3921;&#3851;&#3904;&#4017;&#3954;&#3851;&#3938;&#3991;&#3962;&#3942;&#3851;&#3942;&#3956;&#3851;&#3926;&#3921;&#3906;&#3851;&#3926;&#3942;&#3986;&#4018;&#3956;&#3926;&#3851;&#3904;&#4017;&#3954;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>khy&eacute; kyi jesu dak drup kyi</span>
        </p>
        <p>
          <span>Following in your footsteps</span>
        </p>
        <p>
          <span>
            &#3926;&#4017;&#3954;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3938;&#4019;&#3964;&#3926;&#3942;&#3851;&#3925;&#4017;&#3954;&#3938;&#3851;&#3906;&#3940;&#3962;&#3906;&#3942;&#3851;&#3942;&#3956;&#3851;&#3906;&#3942;&#3964;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>jin gyi lop chir shek su sol</span>
        </p>
        <p>
          <span>I pray to you: come and bless me with your grace!</span>
        </p>
        <p>
          <span>
            &#3906;&#3956;&#3851;&#3938;&#3956;&#3851;&#3924;&#3921;&#4008;&#3851;&#3942;&#3954;&#3921;&#4001;&#4023;&#3954;&#3851;&#3943;&#3953;&#3956;&#3971;&#3860;
          </span>
        </p>
        <p>
          <span>guru pema siddhi hung</span>
        </p>
        <p>
          <span>
            &#3934;&#3962;&#3942;&#3851;&#3926;&#4017;&#3942;&#3851;&#3939;&#3851;&#3930;&#3954;&#3906;&#3851;&#3926;&#3921;&#3956;&#3923;&#3851;&#3906;&#3942;&#3964;&#3939;&#3851;&#3936;&#3921;&#3962;&#3926;&#3942;&#3851;&#3909;&#3954;&#3851;&#3923;&#3956;&#3942;&#3851;&#3942;&#3956;&#3851;&#3936;&#3921;&#3964;&#3923;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>Recite The Seven Line Prayer as many times as you can.</span>
        </p>
        <p>
          <span>
            &#3928;&#3964;&#3942;&#3851;&#3906;&#3956;&#3942;&#3851;&#3904;&#4017;&#3954;&#3942;&#3851;&#3906;&#3942;&#3964;&#3939;&#3851;&#3926;&#3851;&#3926;&#3919;&#3926;&#3851;&#3924;&#3942;&#3851;&#3926;&#4019;&#3851;&#3928;&#3851;&#3937;&#3926;&#3851;&#3937;&#3956;&#3928;&#3851;&#3906;&#4017;&#3954;&#3851;&#3942;&#4006;&#4017;&#3964;&#3938;&#3851;&#3928;&#3930;&#3928;&#3942;&#3851;&#3921;&#3908;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3904;&#3851;&#3923;&#3942;&#3851;&#3937;&#3962;&#3851;&#3940;&#3962;&#3942;&#3851;&#3904;&#4017;&#3954;&#3851;&#3936;&#3964;&#3921;&#3851;&#3935;&#3962;&#3938;&#3851;&#3942;&#4003;&#3851;&#3939;&#3988;&#3851;&#3926;&#3851;&#3920;&#3906;&#3851;&#3939;&#3999;&#3938;&#3851;&#3926;&#3938;&#3984;&#4017;&#3908;&#3851;&#3923;&#3942;&#3851;&#3938;&#3908;&#3851;&#3906;&#3954;&#3851;&#3942;&#3993;&#3954;&#3908;&#3851;&#3906;&#3938;&#3851;&#3920;&#3954;&#3928;&#3851;&#3924;&#3942;&#3851;&#3938;&#3986;&#4017;&#3956;&#3921;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3926;&#3938;&#4019;&#3926;&#3851;&#3924;&#3938;&#3851;&#3926;&#3942;&#3928;&#3853;
          </span>
        </p>
        <p>
          <span>
            Imagine that, because you are praying with sincere devotion, from
            the place of union and the heart of the guru and the consort fine
            shafts of wisdom light&mdash;blue, white, yellow, red and
            green&mdash;shoot out and dissolve into your heart, blessing and
            transforming your very being.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3984;&#3926;&#3942;&#3851;&#3942;&#3956;&#3851;&#3926;&#3931;&#4018;&#3851;&#3906;&#3956;&#3851;&#3938;&#3956;&#3851;&#3909;&#3954;&#3851;&#3938;&#3954;&#3906;&#3942;&#3851;&#3926;&#3935;&#4019;&#3853;
          </span>
        </p>
        <p>
          <span>Recite the Vajra Guru Mantra as much as you wish.</span>
        </p>
        <p>
          <span>
            &#3944;&#3964;&#3966;&#3851;&#3944;&#3953;&#3967;&#3943;&#3953;&#3956;&#3971;&#3851;&#3926;&#3931;&#4018;&#3851;&#3906;&#3956;&#3851;&#3938;&#3956;&#3851;&#3924;&#3921;&#4008;&#3851;&#3942;&#3954;&#3921;&#4001;&#4023;&#3954;&#3851;&#3943;&#3953;&#3956;&#3971;&#3860;
          </span>
        </p>
        <p>
          <span>om ah hung vajra guru pema siddhi hung</span>
        </p>
        <p>
          <span>
            o&#7747; &#257;&#7717; h&#363;&#7747; vajra-guru-padma siddhi
            h&#363;&#7747;
          </span>
        </p>
        <p>
          <span>&#3920;&#3956;&#3923;&#3851;&#3928;&#3920;&#3938;&#3853;</span>
        </p>
        <p>
          <span>Conclusion</span>
        </p>
        <p>
          <span>
            &#3926;&#4019;&#3851;&#3928;&#3936;&#3954;&#3851;&#3906;&#3923;&#3942;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3937;&#3954;&#3851;&#3906;&#3962;&#3851;&#3936;&#3926;&#4018;&#3956;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3939;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>lam&eacute; n&eacute; sum yi g&eacute; dru sum l&eacute;</span>
        </p>
        <p>
          <span>
            From the three syllables o&#7747; &#257;&#7717; h&#363;&#7747; at
            the three centres of the guru,
          </span>
        </p>
        <p>
          <span>
            &#3936;&#3964;&#3921;&#3851;&#3935;&#3962;&#3938;&#3851;&#3921;&#3904;&#3938;&#3851;&#3921;&#3928;&#3938;&#3851;&#3928;&#3920;&#3954;&#3908;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3926;&#4017;&#3956;&#3908;&#3851;&#3923;&#3942;&#3851;&#3942;&#3956;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>&ouml;zer kar mar ting sum jung n&eacute; su</span>
        </p>
        <p>
          <span>White, red and blue rays of light strike out</span>
        </p>
        <p>
          <span>
            &#3938;&#3908;&#3851;&#3906;&#3954;&#3851;&#3906;&#3923;&#3942;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3920;&#3954;&#3928;&#3851;&#3924;&#3942;&#3851;&#3942;&#3986;&#3964;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3906;&#4017;&#3954;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>rang gi n&eacute; sum timp&eacute; go sum gyi</span>
        </p>
        <p>
          <span>
            And dissolve into my own three centres, purifying the obscurations
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3986;&#4018;&#3954;&#3926;&#3851;&#3926;&#4017;&#3908;&#3851;&#3942;&#3984;&#3956;&#3851;&#3906;&#3942;&#3956;&#3908;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3904;&#4017;&#3954;&#3851;&#3938;&#4001;&#3964;&#3851;&#3938;&#3991;&#3962;&#3938;&#3851;&#3906;&#4017;&#3956;&#3938;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>drip jang ku sung tuk kyi dorj&eacute;r gyur</span>
        </p>
        <p>
          <span>
            Of my body, speech and mind, and transforming them into vajra body,
            speech and mind.
          </span>
        </p>
        <p>
          <span>
            &#3928;&#3920;&#3938;&#3851;&#3923;&#3954;&#3851;&#3926;&#4019;&#3851;&#3928;&#3851;&#3936;&#3905;&#3964;&#3938;&#3851;&#3926;&#3909;&#3942;&#3851;&#3936;&#3964;&#3921;&#3851;&#3921;&#3956;&#3851;&#3934;&#3956;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>tar ni lama khor ch&eacute; &ouml; du shyu</span>
        </p>
        <p>
          <span>Finally, the guru and his retinue melt into light</span>
        </p>
        <p>
          <span>
            &#3921;&#3904;&#3938;&#3851;&#3921;&#3928;&#3938;&#3851;&#3920;&#3954;&#3906;&#3851;&#3939;&#3962;&#3851;&#3943;&#3953;&#3956;&#3971;&#3851;&#3906;&#3954;&#3942;&#3851;&#3928;&#3930;&#3923;&#3851;&#3924;&#3851;&#3938;&#3956;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>kar mar tigl&eacute; hum gi tsen pa ru</span>
        </p>
        <p>
          <span>
            And into a red and white bindu marked with h&#363;&#7747;,
          </span>
        </p>
        <p>
          <span>
            &#3938;&#3908;&#3851;&#3906;&#3954;&#3851;&#3942;&#3993;&#3954;&#3908;&#3851;&#3906;&#3938;&#3851;&#3920;&#3954;&#3928;&#3851;&#3924;&#3942;&#3851;&#3926;&#4019;&#3851;&#3928;&#3936;&#3954;&#3851;&#3920;&#3956;&#3906;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>rang gi nying gar timp&eacute; lam&eacute; tuk</span>
        </p>
        <p>
          <span>
            That dissolves into my heart, through which the guru&rsquo;s wisdom
            mind
          </span>
        </p>
        <p>
          <span>
            &#3938;&#3908;&#3851;&#3942;&#3962;&#3928;&#3942;&#3851;&#3921;&#3926;&#4017;&#3962;&#3938;&#3851;&#3928;&#3962;&#3921;&#3851;&#3939;&#4023;&#3923;&#3851;&#3942;&#3984;&#4017;&#3962;&#3942;&#3851;&#3910;&#3964;&#3942;&#3851;&#3942;&#3984;&#3956;&#3938;&#3851;&#3906;&#3923;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>
            rang sem yerm&eacute; lhen ky&eacute; ch&ouml; kur n&eacute;
          </span>
        </p>
        <p>
          <span>
            And my own mind merge inseparably, and so I abide in the
            ever-present dharmak&#257;ya.
          </span>
        </p>
        <p>
          <span>&#3944;&#3851;&#3944;&#3953;&#3967;</span>
        </p>
        <p>
          <span>a a</span>
        </p>
        <p>
          <span>a &#257;&#7717;</span>
        </p>
        <p>
          <span>
            &#3934;&#3962;&#3942;&#3851;&#3926;&#3938;&#3991;&#3964;&#3921;&#3851;&#3939;&#3851;&#3937;&#3962;&#3851;&#3923;&#3942;&#3851;&#3926;&#3909;&#3964;&#3942;&#3851;&#3926;&#3942;&#3986;&#4017;&#3956;&#3938;&#3851;&#3942;&#4004;&#3908;&#3851;&#3926;&#4019;&#3908;&#3851;&#3939;&#3942;&#3851;&#3936;&#3921;&#3942;&#3851;&#3924;&#3851;&#3938;&#3908;&#3851;&#3906;&#3954;&#3851;&#3942;&#3962;&#3928;&#3942;&#3851;&#3913;&#3954;&#3921;&#3851;&#3906;&#3921;&#3964;&#3921;&#3851;&#3928;&#3936;&#3954;&#3851;&#3910;&#3964;&#3942;&#3851;&#3942;&#3984;&#3956;&#3936;&#3954;&#3851;&#3910;&#3962;&#3923;&#3851;&#3924;&#3964;&#3936;&#3954;&#3851;&#3938;&#3908;&#3851;&#3934;&#3939;&#3851;&#3926;&#3939;&#3999;&#3853;
          </span>
        </p>
        <p>
          <span>
            As you utter &ldquo;a, &#257;&#7717;!&rdquo; look into the actual
            face of the great primordial dharmak&#257;ya&mdash;the very nature
            of your own mind that from the very beginning is utterly beyond
            alteration or change, beyond rejecting or accepting anything at all.
            Once again, consider all illusion-like appearances as the nature of
            the guru. Dedicate the merit and make prayers for auspiciousness, so
            that peace and wellbeing prevail.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#4019;&#3938;&#3851;&#3937;&#3908;&#3851;&#3942;&#3986;&#4017;&#3956;&#3851;&#3928;&#3851;&#3939;&#3999;&#3851;&#3926;&#3956;&#3936;&#3954;&#3851;&#3942;&#4003;&#3908;&#3851;&#3926;&#3851;&#3920;&#3928;&#3942;&#3851;&#3909;&#3921;&#3851;&#3926;&#4019;&#3851;&#3928;&#3936;&#3954;&#3851;&#3938;&#3908;&#3851;&#3926;&#3934;&#3954;&#3923;&#3851;&#3921;&#3956;&#3851;&#3926;&#3939;&#3999;&#3942;&#3851;&#3926;&#4017;&#3936;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>
            End the practice well by recognizing all illusory appearance as the
            nature of the Guru.
          </span>
        </p>
        <p>
          <span>Blessing the Feast Offering</span>
        </p>
        <p>
          <span>
            &#3940;&#3851;&#3910;&#3908;&#3851;&#3942;&#3964;&#3906;&#3942;&#3851;&#3909;&#3954;&#3851;&#3936;&#3926;&#4017;&#3964;&#3938;&#3851;&#3926;&#3936;&#3954;&#3851;&#3921;&#3928;&#3851;&#3938;&#4011;&#3942;&#3851;&#3926;&#3940;&#3928;&#3942;&#3851;&#3939;&#3853;
          </span>
        </p>
        <p>
          <span>
            Arrange whatever samaya substances you have, such as meat and
            alcohol.
          </span>
        </p>
        <p>
          <span>
            &#3943;&#3953;&#3956;&#3971;&#3853;
            &#3944;&#3953;&#3967;&#3939;&#3942;&#3851;&#3910;&#3964;&#3942;&#3851;&#3921;&#3926;&#4017;&#3954;&#3908;&#3942;&#3851;&#3921;&#3908;&#3851;&#3928;&#3913;&#3928;&#3851;&#3904;&#3851;&#3924;&#3851;&#3939;&#3938;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>hung, ah l&eacute; ch&ouml;ying dang nyam kapalar</span>
        </p>
        <p>
          <span>
            H&#363;&#7747;. With &#257;&#7717; appears a skullcup, as vast as
            the space of dharmadh&#257;tu,
          </span>
        </p>
        <p>
          <span>
            &#3944;&#3964;&#3966;&#3851;&#3939;&#3942;&#3851;&#3942;&#4003;&#3908;&#3851;&#3942;&#4018;&#3954;&#3921;&#3851;&#3936;&#3921;&#3964;&#3921;&#3851;&#3937;&#3964;&#3923;&#3851;&#3930;&#3964;&#3906;&#3942;&#3851;&#3942;&#3956;&#3851;&#3926;&#3940;&#3928;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>om l&eacute; nang si d&ouml; y&ouml;n tsok su sham</span>
        </p>
        <p>
          <span>
            With o&#7747;, all appearance and existence are displayed as the
            feast offerings
          </span>
        </p>
        <p>
          <span>
            &#3943;&#3953;&#3956;&#3971;&#3851;&#3906;&#3954;&#3942;&#3851;&#3926;&#3921;&#3962;&#3851;&#3910;&#3962;&#3923;&#3851;&#3937;&#3962;&#3851;&#3940;&#3962;&#3942;&#3851;&#3938;&#3964;&#3939;&#3851;&#3924;&#3938;&#3851;&#3926;&#3942;&#3986;&#4017;&#3956;&#3938;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>hung gi dechen yesh&eacute; rolpar gyur</span>
        </p>
        <p>
          <span>
            With h&#363;&#7747;, they transform into the wisdom play of great
            bliss,
          </span>
        </p>
        <p>
          <span>
            &#3943;&#4018;&#3953;&#3954;&#3967;&#3937;&#3954;&#3942;&#3851;&#3938;&#4009;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3939;&#4023;&#3851;&#3930;&#3964;&#3906;&#3942;&#3851;&#3921;&#3906;&#4017;&#3962;&#3942;&#3851;&#3924;&#3851;&#3926;&#3942;&#3984;&#3908;&#3851;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>hrih yi tsa sum lha tsok gy&eacute; pa kang</span>
        </p>
        <p>
          <span>
            With hr&#299;&#7717;, gurus, devas and &#7693;&#257;kin&#299;s, all
            the deities gathered, are pleased and satisfied.
          </span>
        </p>
        <p>
          <span>
            &#3944;&#3964;&#3966;&#3851;&#3944;&#3953;&#3967;&#3943;&#3953;&#3956;&#3971;&#3851;&#3943;&#4018;&#3953;&#3954;&#3967;&#3942;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3926;&#3938;&#4019;&#3926;&#3942;&#3853;
          </span>
        </p>
        <p>
          <span>om ah hung hrih</span>
        </p>
        <p>
          <span>o&#7747; &#257;&#7717; h&#363;&#7747; hr&#299;&#7717;</span>
        </p>
        <p>
          <span>With this bless the offerings.</span>
        </p>
        <p>
          <span>
            &#3930;&#3964;&#3906;&#3942;&#3851;&#3934;&#3954;&#3908;&#3851;&#3942;&#4004;&#4017;&#3908;&#3851;&#3936;&#3921;&#4018;&#3962;&#3923;&#3851;&#3934;&#3954;&#3908;&#3851;&#3928;&#3910;&#3964;&#3921;&#3851;&#3924;&#3851;&#3936;&#3926;&#3956;&#3939;&#3851;&#3926;&#3851;&#3923;&#3954;&#3853;
          </span>
        </p>
        <p>
          <span>
            The Invitation of the Guests and the Offering of the Feast
          </span>
        </p>
        <p>
          <span>
            &#3943;&#3953;&#3956;&#3971;&#3860;
            &#3944;&#3964;&#3851;&#3938;&#3986;&#4017;&#3923;&#3851;&#3937;&#3956;&#3939;&#3851;&#3906;&#4017;&#3954;&#3851;&#3923;&#3956;&#3926;&#3851;&#3926;&#4017;&#3908;&#3851;&#3928;&#3930;&#3928;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>hung, orgyen yul gyi nup jang tsam</span>
        </p>
        <p>
          <span>
            H&#363;&#7747;! In the north-west of the land of
            O&#7693;&#7693;iy&#257;na,
          </span>
        </p>
        <p>
          <span>
            &#3924;&#3921;&#4008;&#3851;&#3906;&#3962;&#3851;&#3942;&#3938;&#3851;&#3942;&#4001;&#3964;&#3908;&#3851;&#3924;&#3964;&#3851;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>pema g&eacute;sar dong po la</span>
        </p>
        <p>
          <span>In the heart of a lotus flower,</span>
        </p>
        <p>
          <span>
            &#3937;&#3851;&#3928;&#3930;&#3923;&#3851;&#3928;&#3910;&#3964;&#3906;&#3851;&#3906;&#3954;&#3851;&#3921;&#3908;&#3964;&#3942;&#3851;&#3906;&#4018;&#3956;&#3926;&#3851;&#3926;&#3938;&#3993;&#3962;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>yatsen chok gi ng&ouml; drup ny&eacute;</span>
        </p>
        <p>
          <span>Endowed with the most marvellous attainments,</span>
        </p>
        <p>
          <span>
            &#3924;&#3921;&#4008;&#3851;&#3936;&#3926;&#4017;&#3956;&#3908;&#3851;&#3906;&#3923;&#3942;&#3851;&#3934;&#3962;&#3942;&#3851;&#3942;&#3956;&#3851;&#3906;&#4018;&#3906;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>pema jungn&eacute; shy&eacute; su drak</span>
        </p>
        <p>
          <span>You are renowned as the Lotus-born,</span>
        </p>
        <p>
          <span>
            &#3936;&#3905;&#3964;&#3938;&#3851;&#3921;&#3956;&#3851;&#3928;&#3905;&#3936;&#3851;&#3936;&#3906;&#4018;&#3964;&#3851;&#3928;&#3908;&#3851;&#3924;&#3964;&#3942;&#3851;&#3926;&#3942;&#3984;&#3964;&#3938;&#3860;
          </span>
        </p>
        <p>
          <span>khor du khandro mangp&ouml; kor</span>
        </p>
        <p>
          <span>Surrounded by many hosts of &#7693;&#257;kin&#299;s.</span>
        </p>
        <p>
          <span>
            &#3905;&#4017;&#3962;&#3921;&#3851;&#3904;&#4017;&#3954;&#3851;&#3938;&#3991;&#3962;&#3942;&#3851;&#3942;&#3956;&#3851;&#3926;&#3921;&#3906;&#3851;&#3926;&#3942;&#3986;&#4018;&#3956;&#3926;&#3851;&#3904;&#4017;&#3954;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>khy&eacute; kyi jesu dak drup kyi</span>
        </p>
        <p>
          <span>Following in your footsteps,</span>
        </p>
        <p>
          <span>
            &#3936;&#3921;&#3964;&#3921;&#3851;&#3937;&#3964;&#3923;&#3851;&#3930;&#3964;&#3906;&#3942;&#3851;&#3939;&#3851;&#3942;&#4004;&#4017;&#3923;&#3851;&#3936;&#3921;&#4018;&#3962;&#3923;&#3851;&#3923;&#3860;
          </span>
        </p>
        <p>
          <span>d&ouml; y&ouml;n tsok la chen dren na</span>
        </p>
        <p>
          <span>We invite you to this feast of desirable offerings,</span>
        </p>
        <p>
          <span>
            &#3926;&#4017;&#3954;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3938;&#4019;&#3964;&#3926;&#3942;&#3851;&#3925;&#4017;&#3954;&#3938;&#3851;&#3906;&#3940;&#3962;&#3906;&#3942;&#3851;&#3942;&#3956;&#3851;&#3906;&#3942;&#3964;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>jin gyi lop chir shek su sol</span>
        </p>
        <p>
          <span>So come now, bless us with your grace!</span>
        </p>
        <p>
          <span>
            &#3906;&#3923;&#3942;&#3851;&#3928;&#3910;&#3964;&#3906;&#3851;&#3936;&#3921;&#3954;&#3851;&#3938;&#3956;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3925;&#3964;&#3926;&#3851;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>n&eacute; chok di ru jin pop la</span>
        </p>
        <p>
          <span>Pour down your blessings on this sublime place,</span>
        </p>
        <p>
          <span>
            &#3930;&#3964;&#3906;&#3942;&#3851;&#3928;&#3910;&#3964;&#3921;&#3851;&#3937;&#3962;&#3851;&#3940;&#3962;&#3942;&#3851;&#3926;&#3921;&#3956;&#3921;&#3851;&#3938;&#4009;&#3954;&#3938;&#3851;&#3926;&#3942;&#3986;&#4017;&#3956;&#3938;&#3860;
          </span>
        </p>
        <p>
          <span>tsok ch&ouml; yesh&eacute; d&uuml;tsir gyur</span>
        </p>
        <p>
          <span>
            Transform the ga&#7751;acakra offerings into wisdom nectar,
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3986;&#4018;&#3956;&#3926;&#3851;&#3928;&#3910;&#3964;&#3906;&#3851;&#3926;&#3921;&#3906;&#3851;&#3939;&#3851;&#3921;&#3926;&#3908;&#3851;&#3926;&#3934;&#3954;&#3851;&#3926;&#3942;&#3984;&#3956;&#3938;&#3860;
          </span>
        </p>
        <p>
          <span>drup chok dak la wang shyi kur</span>
        </p>
        <p>
          <span>
            Grant us, the supreme practitioners, the four empowerments,
          </span>
        </p>
        <p>
          <span>
            &#3926;&#3906;&#3962;&#3906;&#3942;&#3851;&#3921;&#3908;&#3851;&#3939;&#3964;&#3906;&#3851;&#3936;&#3921;&#4018;&#3962;&#3923;&#3851;&#3926;&#3938;&#3851;&#3910;&#3921;&#3851;&#3942;&#3964;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>gek dang lok dren barch&eacute; sol</span>
        </p>
        <p>
          <span>Dispel obstructors, negative forces and obstacles</span>
        </p>
        <p>
          <span>
            &#3928;&#3910;&#3964;&#3906;&#3851;&#3921;&#3908;&#3851;&#3920;&#3956;&#3923;&#3851;&#3928;&#3964;&#3908;&#3851;&#3921;&#3908;&#3964;&#3942;&#3851;&#3906;&#4018;&#3956;&#3926;&#3851;&#3942;&#4009;&#3964;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>chok dang t&uuml;nmong ng&ouml;drup tsol</span>
        </p>
        <p>
          <span>And confer siddhis, ordinary and supreme.</span>
        </p>
        <p>
          <span>
            &#3943;&#3953;&#3956;&#3971;&#3860;
            &#3926;&#4019;&#3851;&#3928;&#3851;&#3938;&#3991;&#3962;&#3851;&#3926;&#3929;&#3956;&#3923;&#3851;&#3924;&#3921;&#4008;&#3851;&#3920;&#3964;&#3921;&#3851;&#3925;&#4018;&#3962;&#3908;&#3851;&#3938;&#4009;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>hung, lama jets&uuml;n pema t&ouml;trengtsal</span>
        </p>
        <p>
          <span>H&#363;&#7747;! Beloved master Pema T&ouml;trengtsal,</span>
        </p>
        <p>
          <span>
            &#3938;&#3954;&#3906;&#3851;&#3936;&#3931;&#3954;&#3923;&#3851;&#3928;&#3905;&#3936;&#3851;&#3936;&#3906;&#4018;&#3964;&#3936;&#3954;&#3851;&#3930;&#3964;&#3906;&#3942;&#3851;&#3921;&#3908;&#3851;&#3926;&#3909;&#3942;&#3851;&#3924;&#3851;&#3937;&#3954;&#3860;
          </span>
        </p>
        <p>
          <span>rigdzin khandr&ouml; tsok dang ch&eacute;pa yi</span>
        </p>
        <p>
          <span>
            With your throng of vidy&#257;dharas and &#7693;&#257;kin&#299;s,
          </span>
        </p>
        <p>
          <span>
            &#3938;&#4009;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3904;&#3956;&#3923;&#3851;&#3936;&#3921;&#3956;&#3942;&#3851;&#3938;&#3986;&#4017;&#3939;&#3851;&#3926;&#3936;&#3954;&#3851;&#3921;&#3904;&#4017;&#3954;&#3939;&#3851;&#3936;&#3905;&#3964;&#3938;&#3851;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>tsa sum kund&uuml; gyalw&eacute; kyilkhor la</span>
        </p>
        <p>
          <span>
            Encompassing the three roots in this ma&#7751;&#7693;ala of the
            buddhas,
          </span>
        </p>
        <p>
          <span>
            &#3928;&#3964;&#3942;&#3851;&#3906;&#3956;&#3942;&#3851;&#3906;&#3921;&#3956;&#3908;&#3851;&#3940;&#3956;&#3906;&#3942;&#3851;&#3921;&#4018;&#3906;&#3851;&#3924;&#3964;&#3942;&#3851;&#3906;&#3942;&#3964;&#3939;&#3851;&#3926;&#3851;&#3936;&#3921;&#3962;&#3926;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>m&ouml; g&uuml; dung shuk drak p&ouml; solwa dep</span>
        </p>
        <p>
          <span>In deep devotion, with fervent yearning, I pray to you.</span>
        </p>
        <p>
          <span>
            &#3926;&#3921;&#3906;&#3851;&#3906;&#3934;&#3923;&#3851;&#3942;&#3986;&#3964;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3921;&#3906;&#3962;&#3851;&#3930;&#3964;&#3906;&#3942;&#3851;&#3939;&#3964;&#3908;&#3942;&#3851;&#3942;&#4004;&#4017;&#3964;&#3921;&#3851;&#3926;&#3909;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>dak shyen go sum g&eacute; tsok long ch&ouml; ch&eacute;</span>
        </p>
        <p>
          <span>
            All the merit of my and others&rsquo; body, speech and mind, our
            wealth,
          </span>
        </p>
        <p>
          <span>
            &#3942;&#4003;&#3908;&#3851;&#3942;&#4018;&#3954;&#3921;&#3851;&#3936;&#3921;&#3964;&#3921;&#3851;&#3937;&#3964;&#3923;&#3851;&#3921;&#3906;&#4017;&#3962;&#3942;&#3851;&#3921;&#3906;&#3956;&#3851;&#3928;&#3851;&#3930;&#3908;&#3851;&#3928;&#3962;&#3921;&#3860;
          </span>
        </p>
        <p>
          <span>nang si d&ouml; y&ouml;n gy&eacute; gu ma tsang m&eacute;</span>
        </p>
        <p>
          <span>
            And everything desirable in the universe, every source of pleasure
            and enjoyment with nothing left out,
          </span>
        </p>
        <p>
          <span>
            &#3904;&#3956;&#3923;&#3851;&#3926;&#3935;&#3908;&#3851;&#3926;&#3921;&#3962;&#3851;&#3910;&#3962;&#3923;&#3851;&#3930;&#3964;&#3906;&#3942;&#3851;&#3904;&#4017;&#3954;&#3851;&#3936;&#3905;&#3964;&#3938;&#3851;&#3939;&#3964;&#3938;&#3851;&#3936;&#3926;&#3956;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>kunzang dechen tsok kyi khorlor bul</span>
        </p>
        <p>
          <span>
            I offer as Samantabhadra&rsquo;s unending ga&#7751;acakra of great
            bliss.
          </span>
        </p>
        <p>
          <span>
            &#3920;&#3956;&#3906;&#3942;&#3851;&#3926;&#3938;&#4009;&#3962;&#3936;&#3954;&#3851;&#3921;&#3906;&#4017;&#3962;&#3942;&#3851;&#3926;&#3934;&#3962;&#3942;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3921;&#3928;&#3851;&#3942;&#3984;&#3964;&#3908;&#3851;&#3906;&#4017;&#3956;&#3938;&#3851;&#3909;&#3954;&#3906;&#3860;
          </span>
        </p>
        <p>
          <span>
            tukts&eacute; gy&eacute; shy&eacute; tuk dam kong gyur chik
          </span>
        </p>
        <p>
          <span>
            With all your compassion and love, accept them with delight, so your
            heart&rsquo;s wishes are fulfilled.
          </span>
        </p>
        <p>
          <span>
            &#3906;&#3942;&#3964;&#3939;&#3851;&#3926;&#3851;&#3936;&#3921;&#3962;&#3926;&#3942;&#3851;&#3942;&#3964;&#3851;&#3906;&#3956;&#3851;&#3938;&#3956;&#3851;&#3938;&#3954;&#3923;&#3851;&#3924;&#3964;&#3851;&#3910;&#3962;&#3860;
          </span>
        </p>
        <p>
          <span>solwa dep so guru rinpoch&eacute;</span>
        </p>
        <p>
          <span>Guru Rinpoche, I pray to you;</span>
        </p>
        <p>
          <span>
            &#3926;&#4017;&#3954;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3938;&#4019;&#3964;&#3926;&#3942;&#3851;&#3940;&#3954;&#3906;&#3851;&#3938;&#3954;&#3906;&#3851;&#3936;&#3931;&#3954;&#3923;&#3851;&#3928;&#3905;&#3936;&#3851;&#3936;&#3906;&#4018;&#3964;&#3936;&#3954;&#3851;&#3930;&#3964;&#3906;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>jin gyi lop shik rigdzin khandr&ouml; tsok</span>
        </p>
        <p>
          <span>
            Vidy&#257;dharas and &#7693;&#257;kin&#299;s, inspire me with your
            blessings;
          </span>
        </p>
        <p>
          <span>
            &#3928;&#3964;&#3942;&#3851;&#3939;&#4001;&#3923;&#3851;&#3926;&#3956;&#3851;&#3939;&#3851;&#3928;&#3910;&#3964;&#3906;&#3851;&#3920;&#3956;&#3923;&#3851;&#3921;&#3908;&#3964;&#3942;&#3851;&#3906;&#4018;&#3956;&#3926;&#3851;&#3942;&#4009;&#3964;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>m&ouml; den bu la chok t&uuml;n ng&ouml;drup tsol</span>
        </p>
        <p>
          <span>
            Grant this devoted disciple attainments, ordinary and supreme;
          </span>
        </p>
        <p>
          <span>
            &#3921;&#3928;&#3851;&#3930;&#3954;&#3906;&#3851;&#3913;&#3928;&#3942;&#3851;&#3910;&#3906;&#3851;&#3920;&#3928;&#3942;&#3851;&#3909;&#3921;&#3851;&#3942;&#4006;&#4017;&#3908;&#3942;&#3851;&#3921;&#3956;&#3851;&#3906;&#3942;&#3964;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>damtsik nyam chak tamch&eacute; jang du sol</span>
        </p>
        <p>
          <span>
            Let all my impairments and breakages of samaya be healed, I pray.
          </span>
        </p>
        <p>
          <span>
            &#3925;&#4017;&#3954;&#3851;&#3923;&#3908;&#3851;&#3906;&#3942;&#3908;&#3851;&#3926;&#3936;&#3954;&#3851;&#3926;&#3938;&#3851;&#3910;&#3921;&#3851;&#3921;&#3926;&#4017;&#3954;&#3908;&#3942;&#3851;&#3942;&#3956;&#3851;&#3942;&#3986;&#4018;&#3964;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>chi nang sangw&eacute; barch&eacute; ying su drol</span>
        </p>
        <p>
          <span>
            Obstacles outer, inner and secret&mdash;liberate them all into
            dharmadh&#257;tu.
          </span>
        </p>
        <p>
          <span>
            &#3926;&#4017;&#3908;&#3851;&#3910;&#3956;&#3926;&#3851;&#3926;&#3938;&#3851;&#3921;&#3956;&#3851;&#3936;&#3926;&#4018;&#3939;&#3851;&#3928;&#3962;&#3921;&#3851;&#3938;&#3991;&#3962;&#3942;&#3851;&#3936;&#3931;&#3954;&#3923;&#3851;&#3934;&#3954;&#3908;&#3851;&#3860;
          </span>
        </p>
        <p>
          <span>changchup bardu dralm&eacute; j&eacute; dzin shying</span>
        </p>
        <p>
          <span>
            Until I realize enlightenment, hold me close, without ever parting,
          </span>
        </p>
        <p>
          <span>
            &#3930;&#3962;&#3851;&#3926;&#3942;&#3964;&#3921;&#3851;&#3913;&#3928;&#3942;&#3851;&#3938;&#3999;&#3964;&#3906;&#3942;&#3851;&#3937;&#3938;&#3851;&#3908;&#3964;&#3936;&#3954;&#3851;&#3935;&#4019;&#3851;&#3939;&#3999;&#3938;&#3851;&#3936;&#3925;&#3962;&#3939;&#3860;
          </span>
        </p>
        <p>
          <span>ts&eacute; s&ouml; nyam tok yar ng&ouml; da tar pel</span>
        </p>
        <p>
          <span>
            Like the waxing moon let my lifespan, merit, experience and
            realization grow ever greater,
          </span>
        </p>
        <p>
          <span>
            &#3926;&#3942;&#3928;&#3851;&#3924;&#3851;&#3939;&#4023;&#3956;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3936;&#3906;&#4018;&#3956;&#3926;&#3851;&#3924;&#3938;&#3851;&#3926;&#4017;&#3954;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3938;&#4019;&#3964;&#3926;&#3942;&#3860;
          </span>
        </p>
        <p>
          <span>sampa lh&uuml;n gyi druppar jin gyi lop</span>
        </p>
        <p>
          <span>
            And bless me so that all my aspirations spontaneously come true.
          </span>
        </p>
        <p>
          <span>
            &#3944;&#3964;&#3966;&#3851;&#3944;&#3953;&#3967;&#3943;&#3953;&#3956;&#3971;&#3851;&#3926;&#3931;&#4013;&#3851;&#3906;&#3956;&#3851;&#3938;&#3956;&#3851;&#3924;&#3921;&#4008;&#3851;&#3942;&#3954;&#3921;&#4001;&#4023;&#3954;&#3851;&#3943;&#3953;&#3956;&#3971;&#3860;
          </span>
        </p>
        <p>
          <span>om ah hung vajra guru pema siddhi hung</span>
        </p>
        <p>
          <span>
            o&#7747; &#257;&#7717; h&#363;&#7747; vajra-guru-padma siddhi
            h&#363;&#7747;
          </span>
        </p>
        <p>
          <span>Offering the Remainder</span>
        </p>
        <p>
          <span>
            &#3926;&#4019;&#3851;&#3928;&#3851;&#3928;&#3910;&#3962;&#3921;&#3851;&#3939;&#3989;&#3928;&#3851;&#3938;&#4003;&#3928;&#3942;&#3851;&#3930;&#3964;&#3906;&#3942;&#3851;&#3939;&#3851;&#3938;&#3964;&#3939;&#3851;&#3923;&#3942;&#3853;
            &#3939;&#4023;&#3906;&#3851;&#3928;&#3851;&#3926;&#3942;&#4001;&#3956;&#3921;&#3851;&#3939;&#3851;&#3926;&#3921;&#3956;&#3921;&#3851;&#3938;&#4009;&#3954;&#3942;&#3851;&#3935;&#3923;&#3853;
          </span>
        </p>
        <p>
          <span>
            Once the guru and the assembled vajra brothers and sisters have
            enjoyed the feast, gather the remainders and sprinkle them with
            am&#7771;ta:
          </span>
        </p>
        <p>
          <span>
            &#3939;&#4023;&#3906;&#3851;&#3942;&#4001;&#3956;&#3921;&#3851;&#3936;&#3911;&#3954;&#3906;&#3851;&#3938;&#3999;&#3962;&#3923;&#3851;&#3921;&#4018;&#3962;&#3906;&#3942;&#3851;&#3924;&#3936;&#3954;&#3851;&#3930;&#3964;&#3906;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>lhak d&uuml; jikten drekp&eacute; tsok</span>
        </p>
        <p>
          <span>
            Hosts of arrogant worldly spirits who collect the remainders,
          </span>
        </p>
        <p>
          <span>
            &#3936;&#3921;&#3964;&#3921;&#3851;&#3937;&#3964;&#3923;&#3851;&#3939;&#3988;&#3851;&#3939;&#4001;&#3923;&#3851;&#3939;&#4023;&#3906;&#3851;&#3906;&#3919;&#3964;&#3938;&#3851;&#3926;&#3934;&#3962;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>d&ouml;y&ouml;n ngaden lhaktor shy&eacute;</span>
        </p>
        <p>
          <span>
            Please accept this remainder torma of the five sensual delights,
          </span>
        </p>
        <p>
          <span>
            &#3925;&#3923;&#3851;&#3921;&#3908;&#3851;&#3926;&#3921;&#3962;&#3851;&#3926;&#3936;&#3954;&#3851;&#3925;&#4018;&#3954;&#3923;&#3851;&#3939;&#3942;&#3851;&#3928;&#3931;&#3964;&#3921;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>pen dang dew&eacute; trinl&eacute; dz&ouml;</span>
        </p>
        <p>
          <span>And carry out beneficial and favorable activities!</span>
        </p>
        <p>
          <span>
            &#3944;&#3964;&#3971;&#3851;&#3944;&#3956;&#3851;&#3929;&#4010;&#3954;&#3941;&#3994;&#3851;&#3926;&#3851;&#3939;&#3954;&#3908;&#3851;&#3919;&#3851;&#3905;&#3953;&#3851;&#3943;&#3954;&#3967;
          </span>
        </p>
        <p>
          <span>om utsita baling ta khahi</span>
        </p>
        <p>
          <span>o&#7747; ucchi&#7779;&#7789;a-bali&#7749;te kh&#257;hi</span>
        </p>
        <p>
          <span>Aspiration</span>
        </p>
        <p>
          <span>
            &#3928;&#3930;&#3964;&#3851;&#3942;&#3984;&#4017;&#3962;&#3942;&#3851;&#3926;&#4019;&#3851;&#3928;&#3936;&#3954;&#3851;&#3921;&#3904;&#4017;&#3954;&#3939;&#3851;&#3936;&#3905;&#3964;&#3938;&#3851;&#3921;&#3956;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>tsoky&eacute; lam&eacute; kyilkhor du</span>
        </p>
        <p>
          <span>H&#363;&#7747;! By making this feast offering</span>
        </p>
        <p>
          <span>
            &#3930;&#3964;&#3906;&#3942;&#3851;&#3904;&#4017;&#3954;&#3851;&#3928;&#3910;&#3964;&#3921;&#3851;&#3924;&#3851;&#3936;&#3926;&#3956;&#3939;&#3851;&#3926;&#3851;&#3937;&#3954;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>tsok kyi ch&ouml;pa bulwa yi</span>
        </p>
        <p>
          <span>To the ma&#7751;&#7693;ala of the Lake-born Guru,</span>
        </p>
        <p>
          <span>
            &#3926;&#3921;&#3906;&#3851;&#3906;&#3934;&#3923;&#3851;&#3936;&#3906;&#4018;&#3964;&#3851;&#3926;&#3851;&#3928;&#3851;&#3939;&#3956;&#3942;&#3851;&#3924;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>dakshyen drowa mal&uuml;pa</span>
        </p>
        <p>
          <span>May I and every single other sentient being</span>
        </p>
        <p>
          <span>
            &#3921;&#3904;&#4017;&#3954;&#3939;&#3851;&#3936;&#3905;&#3964;&#3938;&#3851;&#3906;&#3909;&#3954;&#3906;&#3851;&#3919;&#3956;&#3851;&#3936;&#3906;&#4018;&#3956;&#3926;&#3851;&#3924;&#3938;&#3851;&#3940;&#3964;&#3906;
            &#3853;
          </span>
        </p>
        <p>
          <span>kyilkhor chik tu drubpar shok</span>
        </p>
        <p>
          <span>Attain realization within a single ma&#7751;&#7693;ala!</span>
        </p>
        <p>
          <span>
            &#3921;&#3906;&#3962;&#3851;&#3926;&#3851;&#3926;&#3942;&#3988;&#3964;&#3851;&#3934;&#3954;&#3908;&#3851;&#3940;&#3954;&#3942;&#3851;&#3924;&#3851;&#3926;&#3938;&#3991;&#3964;&#3921;&#3851;&#3909;&#3954;&#3908;&#3851;&#3926;&#3921;&#3962;&#3851;&#3939;&#3962;&#3906;&#3942;&#3851;&#3942;&#3956;&#3851;&#3926;&#4017;&#3851;&#3926;&#3851;&#3923;&#3954;&#3853;
          </span>
        </p>
        <p>
          <span>
            Dedication of merit and Prayers of Prosperity and Auspiciousness
          </span>
        </p>
        <p>
          <span>
            &#3943;&#3953;&#3956;&#3971;&#3860;
            &#3942;&#3984;&#4017;&#3962;&#3942;&#3851;&#3928;&#3962;&#3921;&#3851;&#3910;&#3964;&#3942;&#3851;&#3942;&#3984;&#3956;&#3936;&#3954;&#3851;&#3928;&#3905;&#3936;&#3851;&#3904;&#4019;&#3964;&#3908;&#3851;&#3923;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>hung kyem&eacute; ch&ouml;k&uuml; kha long n&eacute;</span>
        </p>
        <p>
          <span>
            H&#363;&#7747;! From the space-like expanse of the unborn
            dharmak&#257;ya
          </span>
        </p>
        <p>
          <span>
            &#3936;&#3906;&#3906;&#3942;&#3851;&#3928;&#3962;&#3921;&#3851;&#3939;&#4023;&#3851;&#3942;&#3984;&#3956;&#3851;&#3942;&#4003;&#3908;&#3851;&#3926;&#3936;&#3954;&#3851;&#3910;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>gakm&eacute; lha ku nangw&eacute; cha</span>
        </p>
        <p>
          <span>The unobstructed form of the deity appears</span>
        </p>
        <p>
          <span>
            &#3938;&#3954;&#3942;&#3851;&#3928;&#3962;&#3921;&#3851;&#3942;&#3999;&#3964;&#3908;&#3851;&#3906;&#3942;&#3939;&#3851;&#3908;&#3908;&#3851;&#3921;&#3956;&#3851;&#3920;&#3954;&#3928;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>rim&eacute; tong sal ngang du tim</span>
        </p>
        <p>
          <span>And dissolves within the empty clarity beyond limits.</span>
        </p>
        <p>
          <span>&#3944;&#3851;&#3944;&#3953;&#3967;</span>
        </p>
        <p>
          <span>a ah</span>
        </p>
        <p>
          <span>a &#257;&#7717;</span>
        </p>
        <p>
          <span>
            &#3921;&#3906;&#3962;&#3851;&#3938;&#4009;&#3851;&#3926;&#4017;&#3908;&#3851;&#3910;&#3956;&#3926;&#3851;&#3910;&#3962;&#3923;&#3851;&#3924;&#3964;&#3938;&#3851;&#3926;&#3942;&#3988;&#3964;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>getsa changchub chenpor ngo</span>
        </p>
        <p>
          <span>I dedicate these roots of merit to great awakening.</span>
        </p>
        <p>
          <span>
            &#3908;&#3964;&#3851;&#3926;&#3964;&#3851;&#3938;&#3908;&#3851;&#3926;&#3934;&#3954;&#3923;&#3851;&#3920;&#3956;&#3906;&#3942;&#3851;&#3938;&#3991;&#3962;&#3936;&#3954;&#3851;&#3938;&#4009;&#3939;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>ngowo rangshyin tukj&eacute; tsal</span>
        </p>
        <p>
          <span>Essence, nature, and compassionate energy</span>
        </p>
        <p>
          <span>
            &#3944;&#3964;&#3851;&#3938;&#3986;&#4017;&#3923;&#3851;&#3926;&#4019;&#3851;&#3928;&#3851;&#3928;&#3930;&#3964;&#3851;&#3942;&#3984;&#4017;&#3962;&#3942;&#3851;&#3938;&#3991;&#3962;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>orgyen lama tsoky&eacute; j&eacute;</span>
        </p>
        <p>
          <span>
            Are the Lake-born Lord, the Guru of O&#7693;&#7693;iy&#257;na.
          </span>
        </p>
        <p>
          <span>
            &#3942;&#3984;&#3956;&#3851;&#3906;&#3942;&#3956;&#3928;&#3851;&#3939;&#4023;&#3956;&#3923;&#3851;&#3906;&#4017;&#3954;&#3942;&#3851;&#3906;&#4018;&#3956;&#3926;&#3851;&#3924;&#3851;&#3937;&#3954;&#3942;&#3853;
            &#3853;
          </span>
        </p>
        <p>
          <span>ku sum lh&uuml;n gyi drubpa yi</span>
        </p>
        <p>
          <span>May the spontaneous presence of the three k&#257;yas</span>
        </p>
        <p>
          <span>
            &#3926;&#3904;&#4018;&#3851;&#3940;&#3954;&#3942;&#3851;&#3921;&#3928;&#3851;&#3924;&#3936;&#3954;&#3851;&#3926;&#3921;&#3962;&#3851;&#3939;&#3962;&#3906;&#3942;&#3851;&#3940;&#3964;&#3906;
            &#3853;
          </span>
        </p>
        <p>
          <span>tashi damp&eacute; delek shok</span>
        </p>
        <p>
          <span>Yield the bliss and excellence of sublime auspiciousness!</span>
        </p>
        <p>
          <span>
            &#3909;&#3962;&#3942;&#3851;&#3924;&#3851;&#3936;&#3921;&#3954;&#3851;&#3937;&#3908;&#3851;&#3928;&#3954;&#3851;&#3925;&#3928;&#3851;&#3938;&#3954;&#3923;&#3851;&#3924;&#3964;&#3851;&#3910;&#3962;&#3936;&#3954;&#3851;&#3930;&#3954;&#3906;&#3851;&#3926;&#3921;&#3956;&#3923;&#3851;&#3926;&#4019;&#3851;&#3928;&#3936;&#3954;&#3851;&#3942;&#3986;&#4018;&#3956;&#3926;&#3851;&#3924;&#3936;&#3954;&#3851;&#3939;&#4023;&#3923;&#3851;&#3920;&#3926;&#3942;&#3851;&#3942;&#3956;&#3851;&#3940;&#3953;&#3851;&#3904;&#4017;&#3851;&#3940;&#4018;&#3953;&#3954;&#3851;&#3931;&#3993;&#3953;&#3851;&#3923;&#3942;&#3851;&#3920;&#3964;&#3939;&#3851;&#3926;&#4017;&#3956;&#3908;&#3851;&#3921;&#3956;&#3851;&#3942;&#4008;&#4018;&#3942;&#3851;&#3924;&#3936;&#3964;&#3853;&#3853;
            &#3853;&#3853;
          </span>
        </p>
        <p>
          <span>
            This addendum for Mipham Rinpoch&eacute;&rsquo;s Seven Line Prayer
            Guru Yoga was uttered spontaneously by &#346;&#257;kya &#346;r&#299;
            J&ntilde;&#257;na.
          </span>
        </p>
        <p>
          <span>
            | Main visualization &amp; feast offering translated by Rigpa
            Translations, 2015. Opening &amp; concluding verses by
            &#346;&#257;kya &#346;r&#299; translated by Lhasey Lotsawa (Stefan
            Mang and Peter Woods), 2020.
          </span>
        </p>
      </Container>
    </>
  );
}

export default ShowerOfBlessingsAddendum;
